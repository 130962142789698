/*** ICONS FROM SVG ***/
.ci-arrow-left-primary
  background: url('../../images/campsider/icons/commons/primary/arrow_right.svg') no-repeat
  background-size: contain
  width: toRem(60px)
  height: toRem(60px)
  display: flex
  transform: rotate(180deg)

.ci-arrow-right-primary
  background: url('../../images/campsider/icons/commons/primary/arrow_right.svg') no-repeat
  background-size: contain
  width: toRem(60px)
  height: toRem(60px)
  display: flex

/** SOCIALS **/
.ci-instagram-white
  background: url('../../images/campsider/icons/socials/white/instagram.svg') no-repeat
  background-size: contain
  width: toRem(60px)
  height: toRem(60px)
  display: flex

.ci-facebook-white
  background: url('../../images/campsider/icons/socials/white/facebook.svg') no-repeat
  background-size: contain
  width: toRem(60px)
  height: toRem(60px)
  display: flex

.ci-twitter-white
  background: url('../../images/campsider/icons/socials/white/twitter.svg') no-repeat
  background-size: contain
  width: toRem(60px)
  height: toRem(60px)
  display: flex

.ci-youtube-white
  background: url('../../images/campsider/icons/socials/white/youtube.svg') no-repeat
  background-size: contain
  width: toRem(60px)
  height: toRem(60px)
  display: flex

/** REINSURANCE **/
.ci-search-checked-white
  background: url('../../images/campsider/icons/reinsurance/white/search-checked.svg') no-repeat
  background-size: contain
  width: toRem(40px)
  height: toRem(40px)
  display: flex

.ci-shield-checked-white
  background: url('../../images/campsider/icons/reinsurance/white/shield-checked.svg') no-repeat
  background-size: contain
  width: toRem(40px)
  height: toRem(40px)
  display: flex

.ci-chat-checked-white
  background: url('../../images/campsider/icons/reinsurance/white/chat-checked.svg') no-repeat
  background-size: contain
  width: toRem(40px)
  height: toRem(40px)
  display: flex

.ci-ribbon-checked-white
  background: url('../../images/campsider/icons/reinsurance/white/ribbon-checked.svg') no-repeat
  background-size: contain
  width: toRem(40px)
  height: toRem(40px)
  display: flex

.ci-technical-green
  background: url('../../images/campsider/icons/reinsurance/green/technical.svg') no-repeat
  background-size: contain
  width: toRem(25px)
  height: toRem(25px)
  display: flex

.ci-economic-green
  background: url('../../images/campsider/icons/reinsurance/green/economic.svg') no-repeat
  background-size: contain
  width: toRem(25px)
  height: toRem(25px)
  display: flex

.ci-responsible-green
  background: url('../../images/campsider/icons/reinsurance/green/responsible.svg') no-repeat
  background-size: contain
  width: toRem(25px)
  height: toRem(25px)
  display: flex

.ci-responsible-green
  background: url('../../images/campsider/icons/reinsurance/green/responsible.svg') no-repeat
  background-size: contain
  width: toRem(25px)
  height: toRem(25px)
  display: flex

.ci-facebook
  background: url('../../images/campsider/icons/socials/buttons/facebook.svg') no-repeat
  background-size: contain
  width: toRem(25px)
  height: toRem(25px)
  display: flex

.ci-google
  background: url('../../images/campsider/icons/socials/buttons/google.svg') no-repeat
  background-size: contain
  width: toRem(25px)
  height: toRem(25px)
  display: flex
