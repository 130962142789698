.main_header_order_note
  +text
  display: flex
  justify-content: center
  align-items: center
  gap: 8px
  background-color: $reinsurance
  height: 32px
  color: white
  font-size: 11px

  +tablet
    font-size: 12px

.main_header_order
  display: flex
  align-items: center
  background-color: $black
  height: 42px

  &__content
    display: flex
    justify-content: center
    padding: 0

  &__content__logo
    max-width: 100px

  &__content--tablet
    display: none

  +tablet
    position: relative
    height: 66px
    .container
      display: flex
      justify-content: center
      padding: 0 24px

    &__content
      display: flex !important
      justify-content: space-between !important
      align-items: center !important
      width: 1142px

    &__content--tablet
      +text
      font-size: 14px
      display: flex
      align-items: center
      gap: 8px
      color: white
      a
        color: white

    &__content__logo
      max-width: 147px
      position: absolute
      left: 50%
      transform: translateX(-50%)

  +fullhd
    .container
      padding: 0
