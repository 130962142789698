.phone-input-wrapper
  display: grid

  &__label
    padding-bottom: 6px
    font-size: 14px

  &__lib
    &.has-error
      input
        border-color: $error !important
      .phone-input-wrapper_dropdown
        border-top-color: $error !important
        border-left-color: $error !important
        border-bottom-color: $error !important
    input
      +text
      width: 100% !important
      padding: 13.5px 17px 13.5px 62px !important
      border: 1px solid $grey-25 !important
      border-radius: 4px !important
      font-size: 13px !important
      background-color: $white !important
      height: 46px !important

      &::placeholder
        color: $grey-350 !important

      &:focus
        border-color: $black !important
        box-shadow: none !important
        outline: none !important
        + .phone-input-wrapper__lib-dropdown
          border-right-color: $black !important

    .selected-flag
      width: 52px !important
      padding-left: 15px !important

    .flag-dropdown
      height: 46px !important

.phone-input-wrapper__lib-dropdown
  border: 1px solid $grey-25 !important
  background-color: $white !important
