.simple-stepper
  $this: &

  display: flex
  flex-direction: column
  gap: 48px
  &:not(&--vertical)
    +desktop
      gap: initial
      flex-direction: row
      min-height: 60px

  &__step
    flex: 1
    position: relative
    display: flex
    align-items: center
    gap: 14px
    &:not(&--vertical)
      +desktop
        position: relative
        flex-direction: column
        &:first-of-type
          #{$this}__label
            left: 0
            text-align: left

  &__step
    &--vertical
      &:last-of-type
        #{$this}__line
          height: 0

  &__dot
    align-self: flex-start
    background-color: $grey-350
    width: 12px
    height: 12px
    border-radius: 50px
    &--active
      background-color: $black

  &__line
    position: absolute
    top: 0
    left: 5px
    background-color: $grey-350
    height: calc(100% + 48px)
    width: 2px
    flex: 1
    &--active
      background-color: $black
    &:not(&--vertical)
      +desktop
        position: absolute
        margin-top: 5px
        height: 2px
        width: 100%

  &__label
    color: $grey-350
    flex: 1
    margin-top: -4px
    font-size: 14px
    &--active
      color: $text
      font-weight: $weight-medium
    &:not(&--vertical)
      +desktop
        max-width: 100px
        position: absolute
        top: 22px
        left: -43px
        text-align: center

  &__description
    color: $grey-350
    font-weight: initial
    &--active
      color: $text

  &__action
    margin-top: 12px
