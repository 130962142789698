$dimensions: 16 20 24 32 38 45 48 52 64 70 74 96 128

@import "~bulma/sass/elements/image.sass"
@import "../../nuuns/mixins"

.image
  &.is-zoomable
    cursor: zoom-in

  &.is-expanded
    width: 100%
    height: 100%
    img
      height: 100%

  // TODO : Not used yet, but should be used if the card-product-responsive become global
  &.is-79by100
    padding-top: calc(100 / 79 * 100%)

  &.is-50by27
    padding-top: calc(27 / 50 * 100%)

  &.is-14by9
    padding-top: calc(9 / 14 * 100%)

  &.is-79by100,
  &.is-50by27,
  &.is-14by9
    img
      @extend %overlay
      height: 100%
      width: 100%

  .figcaption
    &.is-centered
      +overlayAbsolute
      +flexCenter
      flex-direction: column

  .is-cover
    object-fit: cover

  .is-contain
    object-fit: contain
    height: 100%
