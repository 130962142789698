.short-cards-container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between
  width: 100%
  margin-top: 20px

.short-card-container
  display: flex
  align-content: flex-end
  justify-content: flex-end
  border-radius: 4px
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16)
  position: relative

  width: 43vw
  height: 43vw

  +desktop
    width: 290px
    height: 290px

  &.large
    width: 95vw
    height: 95vw
    margin-bottom: 10px
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16)

    +desktop
      width: 605px
      height: 605px
      margin-bottom: 20px

  .image-container
    position: absolute
    height: 100%
    width: 100%
    top: 0
    display: flex
    border-radius: 4px

    .image
      border-radius: 4px

  .text-container
    padding: 0 3.75vw 3.75vw
    border-radius: 4px
    z-index: 1
    display: flex
    flex-direction: column
    justify-content: flex-end
    width: 100%

    +desktop
      padding: 0 20px 30px

    &.large
      padding: 0 7.5vw 7.5vw
      +desktop
        padding: 0 30px 40px

    .type
      color: $beige
      font-size: 11px
      text-transform: uppercase

      +touch
        font-size: 12px

      &.large
        font-size: 24px
        +touch
          font-size: 18px
    .subtitle

      margin-top: 10px
      color: $beige
      font-size: 14px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden
