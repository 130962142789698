.guide-summary
  +desktop
    &:hover
      box-shadow: inset 0px 0px 0px 1px $beige
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1825))

      .guide-summary__link
        visibility: visible
        opacity: 1

  &__tag
    color: $primary
    margin-left: toRem(16px)

    &:first-child
      margin-left: 0

  &__link
    transition: visibility 0s, opacity 0.25s linear
    
    +desktop
      visibility: hidden
      opacity: 0
