[data-dropdown] .dropdown__account
  li
    &:not(:first-child)
      border-top: 1px solid $grey-100

    &.logout a
      color: $grey-300

    &.title
      font-weight: normal
      padding: toRem(15px) toRem(40px) toRem(15px) toRem(20px)

    a
      padding: toRem(15px) toRem(40px)

      &:hover
        background-color: rgba(190, 74, 9, 0.1)
        border-left: 4px solid $primary
        color: $primary

      span
        +notification
