.images-input-button
  flex: 1
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 10px
  position: relative
  text-align: center
  padding: 0 40px

  border: 1px solid $grey-50
  border-radius: 4px

  input[type=file]
    position: absolute
    top: 0
    left: 0
    cursor: pointer
    opacity: 0
    width: 100%
    height: 100%

  &__icon
    font-size: 26px

  &__text
    font-size: 14px
    text-decoration: underline
    line-height: 17px
