@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/mixins"

.react_input_text
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start

  &--disabled
    opacity: 0.7

  &__label
    margin-bottom: 6px
    font-size: 14px
    height: $input-label-height

  &__label-secondary
    color: $grey-350

  .has-error
    input
      border-color: $error

  &__container
    width: 100%
    position: relative

  &__input
    +text
    width: 100%
    height: $input-height
    min-height: $input-height
    padding: 13.75px 17px
    border: 1px solid $grey-25
    border-radius: 4px
    font-size: 13px
    background-color: $white

    &::placeholder
      color: $grey-350

    &:focus
      border-color: $black
      box-shadow: none
      outline: none

    &:disabled
      cursor: not-allowed

    &[type="number"]
      -moz-appearance: textfield
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

    &--with-right-item
      input
        overflow: hidden
        padding-right: 40px
        text-overflow: ellipsis
        white-space: nowrap

  &__item_right
    position: absolute
    right: 15px
    top: 50%
    transform: translateY(-50%)
    color: $black
    font-weight: 500
    font-size: 16px
    user-select: none
    display: grid
    place-items: center
    button
      &:hover
        color: initial !important

    &--pointer-events-none
      pointer-events: none

  &__message
    font-size: 12px
    margin-top: 10px
