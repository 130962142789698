@import "../../nuuns/functions"
@import "../../nuuns/mixins"
@import "../../bulma-custom/utilities/mixins"
@import "../../bulma-custom/utilities/initial-variables"

@import "../../campsider/icomoon/variables"

.aside-modal-reinsurance
  flex: 1
  display: flex
  flex-direction: column

  &__intro
    max-width: 360px
    text-align: center
    margin: 27px auto 28px

  &__state
    padding: 15px 5px 15px 24px
    &:not(:last-child)
      border-bottom: 1px solid $grey-light

  &__state-title
    position: relative
    &:before
      position: absolute
      left: -25px
      top: 2px
      font-size: 15px
      font-family: $icomoon-font-family
      content: $icon--check
      color: $reinsurance




