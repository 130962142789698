@import "../../../../../nuuns/mixins"
@import "../../../../../nuuns/functions"
@import "../../../../../bulma-custom/utilities/initial-variables"
@import "../../../../../bulma-custom/utilities/mixins"

.my-wallet-modal
  &__kyc-requirements
    font-size: $size-7
    color: $grey-350
    line-height: 20px
    margin-top: -11px

  &__custom-error
    font-size: 12px
    color: $error
    margin-top: -14px

  &__success-icon
    display: block
    height: 41px
    width: 41px
    color: $primary
    margin: 35px auto 30px
    font-size: 41px
