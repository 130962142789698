/**
 * Based on "~swiper/modules/navigation/navigation"
 */
@at-root
  :root
    --swiper-navigation-size: 44px

.swiper-button-prev,
.swiper-button-next
  opacity: 1
  position: absolute
  top: 50%
  transform: translateY(-50%)
  //width: calc(var(--swiper-navigation-size) / 44 * 27)
  //height: var(--swiper-navigation-size)
  //margin-top: calc(0px - (var(--swiper-navigation-size) / 2))
  z-index: 10
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  transition: opacity 0.3s ease-in-out

  &.ci-arrow-left-primary
    transform: translateY(-50%) rotate(180deg)

  &.swiper-button-disabled
    opacity: 0
    cursor: auto
    pointer-events: none

  &.swiper-button-hidden
    opacity: 0
    cursor: auto
    pointer-events: none

  .swiper-navigation-disabled &
    display: none !important


.swiper-button-prev,
.swiper-rtl .swiper-button-next
  left: 10px
  right: auto

.swiper-button-next,
.swiper-rtl .swiper-button-prev
  right: 10px
  left: auto

.swiper-button-lock
  display: none

