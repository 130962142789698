.return
  display: flex
  justify-content: center
  align-items: center
  padding: toRem(20px)
  background-color: $beige

  > a
    flex-direction: row-reverse

    &::after
      transform: rotate(180deg)
