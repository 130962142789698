.socials
  ul
    display: flex
    justify-content: center
    align-items: center
    
    li
      height: 1.688rem
      width: 1.688rem
      margin-right: 2.75rem
      display: flex
      justify-content: center
      align-items: center

      &:last-child
        margin-right: 0