@import "advisor-widget-reduced"

@keyframes advisor-slide-left
  0%
    right: -400px
  75%
    right: 28px
  100%
    right: 24px

@keyframes advisor-slide-left-catalog
  0%
    right: -400px
  75%
    right: 28px
  100%
    right: 66px

@keyframes advisor-slide-right
  0%
    right: 24px
  100%
    right: -400px

.advisor-widget-fixed-container
  z-index: 2 !important
  position: fixed
  bottom: 24px
  right: -400px
  animation: advisor-slide-left 0.4s ease 1.1s forwards !important

  .advisor-widget
    padding: 0

  &--hidden
    animation-delay: 0s !important
    animation: advisor-slide-right 0.2s ease forwards !important

  &--catalog
    animation: advisor-slide-left-catalog 0.4s ease 1.1s forwards !important

.advisor-widget-mobile-container
  width: 100%

.advisor-widget
  padding: 0 15px
  +desktop
    padding: 0 24px

  &__container
    user-select: none
    position: relative

    display: flex
    flex-direction: column
    gap: 15px

    padding: 40px 24px 24px 24px
    background-color: white
    border-radius: 4px 0 4px 4px
    border: 2px solid $primary

    &--offline
      border-radius: 4px

    +desktop
      padding: 25px 17px 14px 17px
      width: 268px
      height: 208pxpx
      gap: 16px

  &__status
    position: absolute
    right: -2px
    top: -24px
    background-color: $primary
    border-radius: 4px 4px 0 0
    overflow: hidden

  &__header
    position: relative
    min-height: 110px

    +desktop
      min-height: unset

  &__texts
    width: 178px
    display: flex
    flex-direction: column
    gap: 12px

    +desktop
      width: 170px

  &__title
    z-index: 1
    display: flex
    justify-content: center
    align-items: center
    gap: 6px
    padding: 5px 10px

    font-size: 14px
    font-weight: $weight-medium
    white-space: nowrap

    border-radius: 4px
    border: 1px solid $grey-50
    background-color: white

  &__subtitle
    z-index: 1
    font-size: 13px
    line-height: 18px
    strong
      color: $primary
      font-weight: $weight-medium

  &__figure
    z-index: 0
    position: absolute
    top: -12px
    right: 0
    width: 35%
    min-width: 104px
    height: 110px

    +desktop
      top: -8px
      min-width: unset
      width: 69px
      height: 92px

  &__image-container
    width: 100%
    height: 100%
    position: relative
    background-color: #F9EDE6
    border-radius: 4px 4px 0 0

  &__pedro
    position: absolute
    bottom: 0
    right: 50%
    transform: translateX(50%)
    height: 118%

    +desktop
      height: 100%

  &__footer
    display: flex
    flex-direction: column
    gap: 12px
    +desktop
      gap: 11px

  &__close
    cursor: pointer
    width: 100%
    font-size: 12px
    color: #1A1A1A
    text-decoration: underline
    text-align: center


.advisor-online-status
  user-select: none
  display: flex
  justify-content: center
  align-items: center
  gap: 5px

  width: 59px
  height: 16px

  background-color: $primary
  border-radius: 0 0 4px 4px

  &--medium
    width: 75px
    height: 24px

  &__dot
    width: 6px
    height: 6px
    border-radius: 50px
    background-color: #68CF8F

    &--medium
      width: 7px
      height: 7px

  &__text
    line-height: 0
    color: $white
    font-size: 9px

    &--medium
      font-size: 12px
