@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"
@import "../../campsider/icomoon/variables"

.button
  border-radius: 4px
  width: fit-content
  display: flex
  gap: toRem(10px)
  align-items: center
  justify-content: center
  padding: toRem(9px) toRem(20px)
  text-align: center
  list-style: none
  cursor: pointer
  -webkit-appearance: none
  transition: color, background, box-shadow ease-in-out 0.3s
  white-space: nowrap
  font-style: normal
  i
    font-size: toRem(21px)

  // TEXT-TRANSFORM modifier
  &--uppercase
    text-transform: uppercase

  // FONT-WEIGHT modifier
  &--weight-bold
    font-weight: $weight-bold
  &--weight-medium
    font-weight: $weight-medium

  // HEIGHT modifier
  &--h35
    height: 35.5px
  &--h38
    height: 38px
  &--h41
    height: 41px
  &--h42
    height: 42px
  &--h43
    height: 43px
  &--h45
    height: 45px
  &--h40
    height: 40px
  &--h48
    height: 48px

  // WIDTH modifier
  &--w40
    width: 40px

  // FONT-SIZE / LINE HEIGHT MODIFIER
  &--font-small
    font-size: 12px
  &--font-medium
    font-size: 13px !important
  &--font-thin
    font-size: 14px !important
  &--font-normal
    font-size: toRem(16px)
    line-height: toRem(24px)
  &--font-large
    font-size: 18px

  // WIDTH MODIFIER
  &--fullwidth
    width: 100%
    min-width: unset
  &--w160
    min-width: toRem(160px)
  &--w200
    min-width: toRem(200px)

  // PADDING MODIFIER
  &--min-padding
    padding: toRem(9px)

  // COLOR MODIFIER (including border color)
  &--primary
    background-color: $primary
    color: $white
    border: none
    +geomanistBook

    &:hover, &:active
      color: $beige
    &:hover
      box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825), inset toRem(-2.78947e-16px) toRem(-4.55556px) toRem(4.55556px) #CC6024, inset toRem(2.78947e-16px) toRem(4.55556px) toRem(4.55556px) #CC6024
    &:active
      box-shadow: toRem(1.00421e-15px) toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825), inset toRem(-2.49937e-16px) toRem(-4.08178px) toRem(4.08178px) #BC4B0B, inset toRem(2.49937e-16px) toRem(4.08178px) toRem(4.08178px) #BC4B0B

  &--secondary
    background-color: $beige
    color: $primary
    border: none

    &:hover, &:active
      color: $primary
    &:hover
      box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825)
    &:active
      box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825), inset 0 toRem(1px) toRem(10px) #EBE6DC

  &--ternary
    color: $black !important
    background-color: white !important
    border: 1px solid $grey-50
    transition: border, color, ease-in-out 0.3s

    &:hover,
    &:active
      color: $primary !important
      border-color: $black

  &--quaternary
    color: $black !important
    background-color: white !important
    border: none

    &:hover,
    &:active
      color: $primary !important
      border-color: $black

  &--quinary
    color: $primary !important
    background-color: white !important
    border: 1px solid $primary

    &:hover,
    &:active
      color: $white !important
      background-color: $primary !important
      border-color: $primary


  &--is-danger
    background-color: $red
    color: $white
    border: none

  &--is-success
    background-color: $green
    color: $white
    border: none

  &--loading
    pointer-events: none
    &:after
      content: ""
      animation: spinAround 500ms infinite linear
      border: 2px solid $primary
      border-radius: $radius-rounded
      display: block
      position: relative
      border-right-color: $white
      border-top-color: $white
      height: 1em
      width: 1em
      min-width: 1em
      min-height: 1em

  &--disabled,
  &:disabled
    opacity: 0.2
    cursor: not-allowed

  &--underlined
    &:hover
      text-decoration: underline
      text-underline-offset: toRem(2px)

  &--exit
    position: fixed
    z-index: 99
    bottom: toRem(40px)

  &--clean
    position: fixed
    z-index: 99
    top: 4px
    left: 4px

  &--exit
    left: toRem(10px)

@keyframes spinAround
  from
    transform: rotate(0deg)
  to
    transform: rotate(359deg)







/***************************************************
/*  Specific buttons, only use in specific case,   *
/*         They aren't classical ".button"         *
/*      TODO : move in another place or IdK        *
/***************************************************/
// Usefull ? To delete ?
.btn-reset
  border: none
  margin: 0
  padding: 0
  width: auto
  overflow: visible
  background: transparent
  /* inherit font & color from ancestor */
  color: inherit
  font: inherit
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal
  text-align: inherit
  outline: none
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit
  -moz-osx-font-smoothing: inherit
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner
    border: 0
    padding: 0




.button-wishlist
  +flexCenter
  cursor: pointer
  z-index: 1
  position: relative
  height: 30px
  width: 30px
  background-color: transparentize($white, 0.3)
  border: 1px solid $grey-600
  border-radius: 50px
  color: $grey-600
  font-size: 14px
  padding: 0

  &:before
    font-family: $icomoon-font-family
    content: $icon--heart

  +desktop
    font-size: 15px

  &:hover,
  &--filled
    &:before
      color: $primary
      content: $icon--heart-filed

  &--desktop-larger
    height: 40px
    width: 40px
    font-size: 19px

// Specific button which reset button style to make him look as a normal string
.button-reset
  display: inline-block
  border: none
  margin: 0
  background: none
  font-family: inherit
  font-size: inherit
  line-height: inherit
  cursor: pointer
  text-align: center
  -webkit-appearance: none
  -moz-appearance: none
  color: inherit
  &:focus
    outline: none
    outline-offset: 0
  &:hover,
  &:focus
    color: inherit

  &--more
    text-decoration: underline

  &--white
    color: white



// todo create a component (lot of style come from button-reset, except : display, margin  and text-align)
.button-price-decrease
  // from button-reset without removed overide styles
  border: none
  text-decoration: none
  background: none
  font-family: inherit
  font-size: 1rem
  line-height: 1
  cursor: pointer
  -webkit-appearance: none
  -moz-appearance: none
  &:focus
    outline: none
    outline-offset: 0
  &:hover,
  &:focus
    color: $primary
  // only related from button-price-decrease
  margin-top: -5px
  display: flex
  align-items: center
  gap: 12px
  width: fit-content
  text-align: left
  padding: 0
  color: $primary
  &__icon
    font-size: 18px
  &__text
    font-size: 14px
    text-decoration: underline


.button-facebook
  +flexCenter
  border-radius: 4px
  width: 46px
  height: 46px
  max-width: 46px
  max-height: 46px
  background-color: $blue-facebook
  color: $beige

  &:hover, &:active
    color: $beige
  &:hover
    box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.3)
  &:active
    box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825), inset 0 toRem(1px) toRem(10px) #EBE6DC

  i
    margin-left: 14px


.button-google
  +flexCenter
  border-radius: 4px
  width: 46px
  height: 46px
  max-width: 46px
  max-height: 46px

  background-color: $grey-100
  color: $black

  &:hover
    box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825)
  &:active
    box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825), inset 0 toRem(1px) toRem(10px) #EBE6DC
