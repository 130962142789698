.react_cart_modal_footer
  display: flex
  flex-direction: column
  gap: 15px
  padding: 20px
  background-color: $beige

  &__price
    +text
    display: flex
    justify-content: space-between
    font-weight: $weight-medium

  &__trustpilot
    display: flex
    justify-content: center

  +desktop
    padding: 30px
    gap: 20px
