// NEW VARIABLES
$baseline: 16 // px baseline for rem conversion

$primary: #BE4A09

$reinsurance: #4C8076
$reinsurance-100: #DAF8EA
$black: #262F2C
$light: #ffffff
$beige: #F4F2EE
$notification: #BF1313
$grey-900: #4E504C // dark-grey
$grey-600: #646661 // mid-grey
$grey-350: #A8A8A8
$grey-300: #B4B7B1 // light-grey
$grey-100: #D7D8D6 // superlight-grey
$grey-50: #D5D5D5
$grey-30: #F7F7F7
$grey-25: #ECECEE
$grey-15: #F2F2F2
$grey-card-border: #E8E8E8
$error: #C63331

// TODO: remove this
// Colors
$blue-facebook: #3C5A9A
$blue-dark: #072a36
$blue-medium: #144353
$blue: #6396A8
$blue-light: #C7D7DD
$blue-light-transparent: rgba(119,215,221, 0.24)
$blue-dark-transparent: rgba(7,42,54, 0.01)
$blue-paypal: #009DE1
$blue-paypal-dark: #113984
$green-review: #4C8076
$red-banner: #CC0900
$green-oney: #81BC00


$white: #FFFFFF
$dark: #2E2E37
$sand: #f7f5f5
$beigeHighTransparency: rgba(242, 238, 237, 0.64)
$beigeTransparent: rgba(242, 238, 237, 90)

$grey: #DADBDF
$grey-lighter: #ECECEE
$grey-light: #D4D4D4
$grey-dark: #5E5C68
$grey-darker: #777777
$grey-dark-transparent: rgba(51, 51, 51, 0.7)
$superlight-grey: #D7D8D6

$temp-grey: #5E5C68
$temp-grey-lighter: #ECECEE
$temp-grey-light: #DADBDF
$temp-grey-dark: #2E2E37

$orange-lighter: #FFE4D6
$orange-light: #FF9966
$orange: #EA7847
$red: #D63737
$yellow: #FFD900

$green: #6ED094
$green-oney: #8BBC05

// Spaces
$space-between-components-touch: 25px
$space-between-components: 30px

// Responsiveness
$tablet: 767px
$mobileM: 375px
$desktop: 1023px
$large: 1224px // todo remove this...
$xlarge: 1312px  // todo remove this...
$fullhd-enabled: true

// TODO : clean $large and $xlarge var.
// TODO BIS : remove +large +xlarge mixins use
// TODO TER : configure gap with 24px (see container for more info)
//            configure $fullHd to 1312px


// Font weight (Add 2 news)
$weight-extra-light: 200
$weight-black: 900

$radius: 0px !default
$tag-radius: 4px !default

// Container specific values
// 1440px - 2 x 64 = 1312px...
$container-max-width: 1360px // 1440px - 2 x 64px + 2 x 24px
$container-content-horizontal-padding: 24px


// Custom : Used in autocomplete-searchbox and searchbox
$main-header-searchbox-height-mobile: 36px
$main-header-searchbox-height-desktop: 42px

// Custom : Used in main-header, menu/dropdown
$main-header-desktop-height: 150px
$main-header-touch-height: 164px
$main-header-one-row-height: 90px
$announces-height: 32px

// Form
$input-height: 46px
$input-label-height: 20px




