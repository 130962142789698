// WARNING : Do not remove this banner. It will be used for other campaigns with Coupon

@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"

.banner
  min-height: 44px
  background: #FFC7A7
  color: $primary
  text-align: center
  padding: 12px 24px 8px
  font-size: 14px
  line-height: 20px

  &--clickable
    cursor: pointer
    &:hover
      text-decoration: underline

  &--campaing-banner
    font-size: toRem(14px)
    line-height: 1.7em
    background: #FFC7A7
    color: $primary
    +until(420px)
      font-size: toRem(12px)

  &--fractionated-payment
    background: rgba($blue-paypal, 0.15)
    color: $blue-paypal

  &--bike
    color: $primary
    background-color: #F5E4DA

  &__code
    display: inline-block
    position: relative
    white-space: nowrap
    border: 1px $primary dashed
    border-radius: 4px
    background: $white
    color: $primary
    font-weight: bold
    padding: 2px 10px
    margin-left: 7px

    &--pointer
      cursor: pointer

  &__left-icon
    margin-right: 4px

  &__strong
    margin-top: 1px
    color: inherit
    display: inline

  &__short-date
    +flexCenter
    min-width: 38px

  &__copied-success
    color: inherit
    text-decoration: underline
    &:hover,
    &:focus,
    &:active
      color: inherit

  &__copied
    +overlay
    position: absolute
    opacity: 0
    z-index: -1
    animation: all ease-in-out 0.5s
    background: $white
    padding: 2px

    &--visible
      opacity: 1
      z-index: 1


  &__link
    +inlineFlexCenter
    white-space: nowrap
    border: 1px $primary solid
    border-radius: 4px
    background: $white
    color: $primary
    font-weight: bold
    padding: 2px 10px
    margin-left: 7px
    gap: 4px
    transform: translateY(-2px)

    &:hover
      color: $primary
      box-shadow: 0 toRem(2px) toRem(5px) rgba(0, 0, 0, 0.1825)

  &__icon
    font-size: 0.7em



