@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/mixins"

.select-options
  width: 100%
  display: flex
  flex-direction: column
  align-items: flex-start

  &__label
    margin-bottom: 6px
    font-size: 14px
    height: $input-label-height

  &__label-secondary
    color: $grey-350

  &__container
    position: relative
    width: 100%

  &__select
    font-size: toRem(13px)
    width: 100%
    padding: 0 40px 0 15px
    height: $input-height
    min-height: $input-height
    border: 1px solid $grey-25
    border-radius: 4px
    outline: none
    appearance: none
    cursor: pointer
    background-color: white
    color: $black !important
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    &--empty
      color: $grey-350 !important

  &__icon
    pointer-events: none
    font-size: 16px
    position: absolute
    right: 20px
    top: 50%
    transform: translateY(-50%)

