[data-dropdown]
  .dropdown__sort, .dropdown__status
    background: $beige
    width: 100%
    height: 100%
    +tablet
      border-top: 2px solid $primary
      box-shadow: 0 4px 10px 5px rgba(0,0,0,0.1)
      margin-top: -3px
      padding: 35px 28px
    +mobile
      display: flex
      flex-direction: column

    ul
      +mobile
        flex: 1
        padding: 39px 27px
        border-top: 1px solid $grey-300

    li
      &:not(:last-child)
        margin-bottom: 16px

    &-title
      padding: 32px 27px 27px
      justify-content: center

    &-submit
      color: $beige

    &-footer
      padding: 0 24px 56px
      color: $beige

    &-label
      display: block
      position: relative
      cursor: pointer
      user-select: none
      padding-left: 26px
      height: 20px


      &:hover
        .dropdown__sort-checkmark
          background: rgba(255,255,255,0.5)

    &-radio
      position: absolute
      opacity: 0
      cursor: pointer

      &:checked ~ .dropdown__sort-checkmark:after
        background-color: $primary

    &-checkmark
      +flexCenter
      position: absolute
      top: 2px
      left: 0
      height: 16px
      width: 16px
      background: transparent
      border-radius: 50%
      border: 1px solid $grey-100
      padding: 2px
      &:after
        content: ""
        display: block
        width: 10px
        height: 10px
        border-radius: 50%
        background: transparent
