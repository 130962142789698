@import "../nuuns/mixins"

$sliced-header-max-width: 1440
$sliced-default-left-column-width: 823
$slice-default-left-column-height-mobile: 420

.sliced-header
  width: 100%
  margin-left: auto
  margin-right: auto

  &__columns
    margin-bottom: 0 !important
    &--desktop-break
      +touch
        display: block !important

  // The left part contains is quite allow us to have a clipped background
  // All the stuff concerning this background must be
  &__left
    position: relative
    * // Make the content of the left column above the picture
      position: relative
      z-index: 3
    +tablet
      max-width: calc(#{$sliced-default-left-column-width} / (#{$sliced-header-max-width}) * 100%)
    +mobile
      height: #{$slice-default-left-column-height-mobile}px
      width: 100%
    &::after
      +overlayAbsolute
      content: ""
      background-color: $beige
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      +tablet
        clip-path: polygon(0 0, 100% 0%, 87% 100%, 0% 100%)
      +mobile
        height: #{$slice-default-left-column-height-mobile}px
        clip-path: polygon(0 0, 100% 0%, 100% 87%, 0% 100%)

    // Custom Modifier
    &--login
      &-no-webp
        &::after
          background-image: url('../../images/login/sylvain-mauroux-background.jpg')
      &-webp
        &::after
          background-image: url('../../images/login/sylvain-mauroux-background.webp')

    &--profile
      &-no-webp
        &::after
          background-image: url('../../images/public-profile/public-profile.jpg')
      &-webp
        &::after
          background-image: url('../../images/public-profile/public-profile.webp')

    &--custom-background
      &-no-webp,
      &-webp
        +desktop
          height: 800px
        &::after
          background-image: var(--background)

    &--ad-filed
      &-no-webp
        &::after
          background-image: url('../../images/screens/thank-you/ad-filed/ad-filed.jpg')
      &-webp
        &::after
          background-image: url('../../images/screens/thank-you/ad-filed/ad-filed.webp')

    &--ad-modified
      &-no-webp
        &::after
          background-image: url('../../images/screens/thank-you/ad-modified/ad-modified.jpg')
      &-webp
        &::after
          background-image: url('../../images/screens/thank-you/ad-modified/ad-modified.webp')

    &--order-confirmed
      &-no-webp
        &::after
          background-image: url('../../images/screens/thank-you/order-confirmed/order-confirmed.jpg')
      &-webp
        &::after
          background-image: url('../../images/screens/thank-you/order-confirmed/order-confirmed.webp')

    &--error
      &-no-webp,
      &-webp
        +tablet
          height: 571px
      &-no-webp
        &::after
          background-image: url('../../images/screens/error/david-marcu.jpg')
          background-position: 50% 100%
      &-webp
        &::after
          background-image: url('../../images/screens/error/david-marcu.webp')
          background-position: 50% 100%

    &--add-product
      &-no-webp,
      &-webp
        +desktop
          max-width: calc(#{$sliced-default-left-column-width} / (#{$sliced-header-max-width}) * 100%)
        +touch
          height: #{$slice-default-left-column-height-mobile}px
          width: 100%
          max-width: unset
        &::after
          background-color: $beige
          +desktop
            clip-path: polygon(0 0, 100% 0%, 87% 100%, 0% 100%)
          +touch
            clip-path: polygon(0 0, 100% 0%, 100% 87%, 0% 100%)

    &--how-it-works
      &-no-webp
        &::after
          background-image: url('../../images/how-it-works/how-it-works.jpg')
      &-webp
        &::after
          background-image: url('../../images/how-it-works/how-it-works.webp')

  // TODO Add custom modifiers here, and/or customize those already available.

  &__left-title
    &--profil
      padding: 64px 0 0 64px

    &__left-title
      &--profil
        padding: 64px 0 0 64px
        // TODO Add custom modifiers here, and/or customize those already available.

  &__right
    //TODO merge all modifier as default properties when all pages are done
    &--error
      +tablet
        flex: none
        width: #{$sliced-header-max-width - $sliced-default-left-column-width}px

    &--login
      +tablet
        flex: none
        width: #{$sliced-header-max-width - $sliced-default-left-column-width}px

    &--add-product
      flex: none
      +desktop
        width: #{$sliced-header-max-width - $sliced-default-left-column-width}px

// TODO : add Custom Style if required
