@import "../bulma-custom/utilities/initial-variables.sass"

.user-product-preview-accessories
  display: flex
  flex-direction: column
  align-items: flex-end

  .user-product-preview
    width: 240px

.border-wrapper
  display: flex
  margin-top: -20px
  margin-bottom: 20px
  span.border
    border-top: 2px solid $blue-medium
    width: 40%
    margin: auto

.user-product-preview
  display: flex
  flex-direction: row
  border: 1px solid $grey-lighter
  border-radius: 4px
  height: 75px
  width: 270px
  background: $white
  margin-bottom: 20px
  position: relative


  figure
    padding: 5px
    align-items: center
    display: flex

    img
      width: 54px
      height: 54px
      min-width: 54px
      min-height: 54px

  .user-product-data
    font-size: 12px
    justify-content: center
    display: flex
    flex-direction: column
    padding: 5px

    .brand
      font-weight: 500
      text-transform: capitalize

    .description
      font-size: 12px
      font-weight: 300

    .user-product-price
      color: $orange
      font-weight: 500

    .product-price
      font-size: 10px
      color: $blue
      text-decoration: line-through

  .remove-product
    position: absolute
    top: -5px
    right: -5px
    cursor: pointer

