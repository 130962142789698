.account-product-card
  border: 1px solid $grey-100
  border-radius: toRem(4px)
  display: flex
  flex-direction: column
  gap: toRem(10px)
  justify-content: space-between
  padding: toRem(18px) toRem(20px)
  width: 100%

  +desktop
    align-items: center
    flex-direction: row

  &.is-order
    .account-product-card__infos-container figure
      width: toRem(128px)
      min-width: toRem(128px)
      height: toRem(133px)

  &.is-modal
    .account-product-card__infos-container figure
      width: toRem(114px)
      min-width: toRem(114px)
      height: toRem(114px)

  &.is-order
    +mobile
      align-items: flex-start
      flex-direction: column
      gap: toRem(30px)

  &__order-container
    display: flex
    flex-flow: column
    gap: toRem(16px)

    .is-flex
      gap: toRem(16px)

    figure
      aspect-ratio: 1/1
      background-color: $beige
      border-radius: toRem(4px)
      height: toRem(133px)
      overflow: hidden
      width: auto

  &__infos-container
    display: flex
    gap: toRem(16px)

    figure
      background-color: $beige
      width: toRem(184px)
      min-width: toRem(184px)
      height: toRem(184px)
      overflow: hidden
      border-radius: toRem(4px)

      +touch
        width: toRem(140px)
        min-width: toRem(140px)
        height: toRem(140px)

      +mobile
        width: toRem(100px)
        min-width: toRem(100px)
        height: toRem(100px)

      img
        height: 100%
        object-fit: cover
        width: 100%
        &.is-hoverable
          transition: ease-in-out transform 250ms
          &:hover
            transform: scale(1.1)

  &__infos-container,
  &__order-container
    &__infos
      &__brand
        font-weight: 500!important
        text-transform: uppercase

      &__metadata
        display: flex
        align-items: center
        gap: toRem(15px)

        span:not(:first-child)
          position: relative

          &::before
            content: ''
            width: toRem(4px)
            height: toRem(4px)
            position: absolute
            top: 50%
            left: toRem(-9px)
            border-radius: toRem(2px)
            background-color: $black
            transform: translateY(-50%)

      &__price
        display: flex
        align-items: center
        gap: toRem(10px)
        margin-top: toRem(5px)

        span
          font-weight: bold

        s
          font-size: toRem(12px)
          line-height: toRem(16px)
          text-decoration-line: line-through
          color: rgba(38, 47, 44, 0.5)
          font-weight:  500

  &__sold-by
    display: flex
    align-items: center
    gap: toRem(15px)

    > div
      display: flex
      align-items: flex-start
      gap: toRem(7px)
      font-weight: bold

      span
        position: relative
        top: toRem(7px)

    img
      width: toRem(40px)
      height: toRem(40px)
      border-radius: toRem(40px)
      object-fit: cover

  .additional-infos
    &.desktop
      margin-top: toRem(12px)

      +mobile
        display: none

    &.mobile
      display: none

      +mobile
        display: block

  &__ctas
    align-items: center
    min-width: toRem(296px)
    display: flex
    flex-direction: column
    gap: toRem(10px)

    > button
      width: 100%
