.split-payment-form
  display: flex
  flex-direction: column
  gap: 32px

  $this: &

  &__plan-selector
    display: flex
    justify-content: center
    align-items: center
    gap: 36px

  &__timeline
    position: relative
    margin-left: 20px

  &__item
    position: relative
    margin-bottom: 8px

    &--active
      #{$this}__dot
        background-color: $primary
        border-color: $primary
      #{$this}__content
        font-weight: $weight-medium

  &__dot
    width: 9px
    height: 9px
    border: 1px solid $grey-50
    border-radius: 50%
    position: absolute
    left: -20px
    top: 7px

  &__line
    width: 1px
    background-color: $grey-50
    position: absolute
    left: -16px
    top: 16px
    bottom: -16px

  &__content
    font-size: 14px
    display: flex
    justify-content: space-between
    align-items: center
    padding-left: 10px

  &__date
    margin-right: 10px


