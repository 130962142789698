.form-grid
  display: grid !important
  grid-template-columns: 1fr 1fr !important
  grid-column-gap: 13px !important
  grid-row-gap: 25px !important

  &--compact
    grid-row-gap: 13px !important

  +until(365px)
    grid-template-columns: 1fr !important
