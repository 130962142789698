.modal-success-message
  display: flex
  flex-direction: column
  align-items: center
  gap: 26px

  &__icon
    font-size: 54px

  &__text
    text-align: center
