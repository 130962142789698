@import "../bulma-custom/utilities/initial-variables.sass"
@import "../bulma-custom/utilities/mixins.sass"
@import "../nuuns/mixins"

.author
  &__media
    margin-right: 20px

  &__image
    border: 5px solid $primary
    height: 100% !important

  &__intro
    +title
    color: $primary
    text-transform: uppercase
    display: block
    margin-bottom: 3px

    &--bottom
      text-transform: none
      color: $black
      font-size: toRem(26px)
      line-height: toRem(36px)
      +desktop
        font-size: toRem(28px)
        line-height: toRem(35px)

  &__sports
    font-weight: bold
    display: flex
    flex-direction: row
    align-items: center
    flex-wrap: wrap

  &__sport
    margin: 2.5px
    &:first-child
      margin-left: 10px

  &__description
    background: $beige
    padding: 13px 20px 30px
    margin-top: 25px
