@import './parts/button'
@import './parts/placeholder'

.images-input
  &__photos
    overflow: hidden
    +until(374px)
      grid-template-columns: 1fr
      grid-template-rows: repeat(8, 60lvw)
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: repeat(4, 116px)
    grid-gap: 13px
    +from(380px)
      grid-template-rows: repeat(4, 34lvw)
    +tablet
      grid-template-columns: 1fr 1fr 1fr 1fr
      grid-template-rows: repeat(2, 16lvw)
    +desktop
      grid-template-rows: repeat(2, 116px)
      grid-gap: 26px

  &__photo
    +flexCenter
    border-radius: 4px
    overflow: hidden
    position: relative

  &__photo-remove, &__photo-move
    +flexCenter
    background-color: white
    cursor: pointer
    position: absolute
    top: 10px
    right: 10px
    width: 22px
    height: 22px
    border-radius: 50px
    i
      font-size: 10px

  &__photo-move
    cursor: grab
    top: 39px
    i
      font-size: 15px
