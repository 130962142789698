.main-header-logo
  display: flex
  height: 100%
  width: 100%
  align-items: center
  margin-top: 2px

  &--home
    background: url('../../../images/logos/campsider.svg') no-repeat center
    text-indent: -100%
    overflow: hidden
    background-size: 100%

