@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/derived-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"

// TODO REFACTO
#header-categories-secondary-block .column
  ul
    height: toRem(200px)

.header-dropdown-container
  display: none

.dropdown-item,
.main-menu-icon
  text-transform: uppercase
  position: relative
  padding: 0

  a.title-link
    font-weight: 700

.dropdown-item
  justify-content: flex-start

  +until($navbar-breakpoint)
    width: 100%

  &.active, &:hover
    a
      position: relative
      color: $primary

      &::before
        background-color: $primary

  a
    display: flex
    justify-content: space-between
    align-items: center
    color: $beige
    padding: toRem(15px) 0
    z-index: 1000
    white-space: nowrap

    +until($navbar-breakpoint)
      width: 100%
      position: relative
      color: $dark

    &::before
      content: ''
      width: 100%
      height: toRem(2px)
      position: absolute
      bottom: toRem(-2px)
      left: 0
      background-color: transparent

      +until($navbar-breakpoint)
        content: none

    i
      display: none
      color: $primary
      font-size: toRem(10px)
      transform: rotate(-90deg)

      +until($navbar-breakpoint)
        display: inline

.main-menu-icon
  justify-content: center
  align-items: center

.main-menu-icon, .dropdown-item
  .header-dropdown-container
    #header-dropdown-categories-titles
      margin-top: toRem(30px)!important
      li
        padding: toRem(8px) toRem(12px) !important
        width: toRem(136px)

        &.selected
          border-right: toRem(2px) solid $orange
          a
            color: $orange

      a:hover
        color: $orange !important

      .categories-list
        display: none
        flex-wrap: wrap

    #header-categories-secondary-block
      #categories-header-groups-main
        display: flex
      .columns
        display: none
        ul
          flex-wrap: wrap

      .categories-list
        flex-direction: column

        .more-link
          align-self: flex-end

        ul:first-child
          flex-direction: column
          height: toRem(340px)
          width: 100%

          li
            margin-bottom: toRem(20px)
            a
              color: $blue-dark
              font-weight: 700
            ul
              margin-top: toRem(10px)
              height: fit-content
              li
                margin-top: 0
                margin-bottom: 0
                height: fit-content
                a
                  color: $blue-medium
                  font-weight: 400

  &:hover
    .header-dropdown-container
      +from($navbar-breakpoint)
        display: flex

  .header-dropdown-container
    width: toRem(260px)
    position: absolute
    left: toRem(-155px)
    height: fit-content
    color: $blue-dark

    .user-product-preview
      width: toRem(220px)

    /** Cart section **/
    .cart-content
      margin: toRem(10px) auto 0

    .cart-resume-order
      margin-bottom: toRem(-32px)
      display: flex
      justify-content: center

    .cart-resume-item

      font-size: toRem(14px)
      margin-bottom: toRem(10px)

    .cart-resume-total

      font-size: toRem(24px)
      margin-top: toRem(20px)
      margin-bottom: toRem(20px)
      display: flex
      justify-content: space-between
      text-transform: uppercase
      font-weight: 400

      .price
        color: $orange

      .originalPrice
        text-decoration: line-through
        color: $blue-light
        margin-left: toRem(10px)
        font-size: toRem(20px)


#header-cart-dropdown
  padding: toRem(10px)


#header-resell-dropdown
  flex-direction: column

  button
    margin-top: toRem(15px)
    margin-bottom: toRem(-25px)
