// TODO move in common folder
.address-autocomplete
  position: relative

  &__predictions
    padding-left: 0 !important
    list-style-type: none !important
    display: flex
    flex-direction: column

    background-color: white
    z-index: 1
    width: 100%
    position: absolute
    top: 55px

    &--with-label
      top: 82px

    +tablet
      top: 61px
      &--with-label
        top: 84px

    font-size: 13px
    border: 1px solid $black
    border-radius: 4px

  &__predictions-title
    text-transform: uppercase
    padding: 7px 15px
    +tablet
      padding: 9px 15px

  &__prediction
    padding: 7px 15px
    +tablet
      padding: 9px 15px

    &:hover
      background-color: $sand
      cursor: pointer
    &:last-of-type
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px

    strong
      font-weight: $weight-medium
