.select-field
  min-height: toRem(54px)
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 toRem(20px)
  border: 1px solid $grey-100
  border-radius: toRem(4px)

  &__choice
    color: $primary

    &--disabled
      cursor: not-allowed
