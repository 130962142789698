.apps-container
  position: relative
  padding: toRem(20px) 0 toRem(120px)

  +desktop
    padding: toRem(82px) 0 toRem(105px)

.apps
  background-color: $black
  border-radius: toRem(20px)
  text-align: center
  padding: toRem(44px) 30% toRem(44px) 15%

  +desktop
    text-align: left

  +large
    padding: toRem(44px) 20% toRem(44px) 5%

  +touch
    padding: toRem(44px) toRem(44px) toRem(168px)

  &::after
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-size: 264px
    background-repeat: no-repeat
    background-image: url('../../images/campsider/apps/full-smartphone.png')
    background-position-x: calc(50% + toRem(55px))

    +desktop
      left: 58%
      background-size: auto
      background-position: toRem(57px) 0

    +touch
      background-position-y: bottom

  & > *
    color: $beige

  & > h2
    text-align: left

    +touch
      text-align: center

  &__links
    position: relative
    z-index: 1
    margin-top: toRem(20px)

    +desktop
      margin-top: toRem(30px)

  &__item
    display: block
    margin: auto
    width: toRem(152px)

    +desktop
      display: inline-block
      margin-left: toRem(4px)
      width: toRem(211px)

      &:first-child
        margin-left: 0

.app-links
  background-size: auto toRem(134px)
  background: url('../../images/campsider/apps/smartphone.png') no-repeat 0 100%
  padding-left: toRem(116px)
  height: toRem(170px)
  display: inline-flex
  flex-direction: column
  align-items: stretch
  justify-content: center

  +desktop
    background-size: auto
    padding-left: toRem(174px)

  a
    margin-bottom: toRem(12px)

    &:last-child
      margin-bottom: 0
