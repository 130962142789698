.carousel
  overflow: hidden
  display: flex
  transition: all 1s
  .column
    position: relative
    img,
    h2,
    span
      pointer-events: none // prevent drag on image
      +unselectable

  &.transition > *
    transition: all 0.5s

  &.static
    flex-wrap: wrap

  .arrow
    +unselectable
    position: absolute !important
    display: flex
    flex: 1
    align-items: center
    height: fit-content !important
    justify-content: center
    transition: opacity 500ms
    width: auto !important
    align-self: center

    &.hidden
      opacity: 0
    &:not(.hidden)
      opacity: 1
    &.is-right
      justify-content: flex-start
      right: 0
      padding-right: 0 !important

    &.is-left
      justify-content: flex-end
      left: 0
      padding-left: 0 !important

  & + ul.dots
    display: flex
    text-align: center
    +mobile
      justify-content: center
      
    li
      display: inline-block
      text-align: center
      width: toRem(12px)
      height: toRem(12px)
      border-radius: 100%
      margin: toRem(10px)
      transition: all 0.5s
      &:not(.is-selected)
        background-color: $beige
      &.is-selected
        background-color: $primary
