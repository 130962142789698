.modal-type
    &--is-delete
        flex-direction: column

        .campsider-modal__content
            padding: toRem(48px) toRem(35px)
            text-align: center

            form
                fieldset
                    display: flex
                    justify-content: center
                    align-items: center
                    flex-direction: column
                    gap: toRem(10px)

                select
                    width: toRem(268px)
                    height: toRem(54px)
                    -webkit-appearance: none
                    border: 1px solid $grey-100
                    border-radius: toRem(4px)
                    text-align: center
                    outline: none
                    cursor: pointer

                span
                    color: $primary

                    &.hidden
                        display: none

                > div
                    display: flex
                    justify-content: center
                    align-items: center
                    flex-direction: column
                    gap: toRem(17px)
                    margin-top: toRem(50px)
