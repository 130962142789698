.headline
  font-size: 14px
  color: $orange
  display: flex
  justify-content: space-between
  margin: 40px 0

  span
    width: max-content
    display: flex
    font-weight: 500

  .bar
    background: $orange
    width: 100%
    margin: auto 10px

  a
    color: $grey
    font-size: 12px
    font-weight: 300
    text-decoration: underline