.slideshow
  +unselectable
  position: fixed
  width: 100vw
  height: 100vh
  background: rgba(0, 0, 0, 0.7)
  top: 0
  left: 0
  z-index: 10002
  display: flex
  align-item: center
  justify-content: center
  .main-wrapper
    width: 1088px
    margin-right: 3rem
    margin-left: 3rem
    height: 100vh
    .top-wrapper
      display: flex
      height: 70vh
      align-items: center
      justify-content: center
      position: relative
      .image-wrapper
        padding: 0
        margin: 0
        width: 100%
        height: 100%
        position: relative
        display: flex
        align-items: center
        justify-content: center
        overflow: hidden
        .slideshow-image
          pointer-events: none // prevent drag on image
          max-width: 100%
          max-height: 100%

          border: 10px solid #fff
          position: absolute
          transition: all 500ms
          &.is-left
            margin-left: -200%
          &.is-middle
            margin-left: 0
          &.is-right
            margin-left: 200%
          &.has-no-transition
            transition: none !important

      .arrow
        width: 2rem !important
        display: flex
        align-items: center
        justify-content: center
        position: absolute !important
        z-index: 2
        transition: 500ms
        height: 2rem !important
        cursor: pointer
        &.is-right
          right: -3rem !important

        &.is-left
          left: -3rem !important

        a
          width: fit-content

    .bottom-wrapper
      display: flex
      height: 30vh
      width: 100%
      flex-direction: row
      align-items: center
      justify-content: flex-start
      flex-wrap: nowrap
      overflow: hidden
      .thumbnail
        width: 25%
        flex: none
        position: relative
        padding: 4px
        transition: all 300ms
        figure
          overflow: hidden
        &:not(.active)
          img
            filter: blur(1px) brightness(50%)
        &.has-no-transition
          transition: none !important
        &.active
          filter: none
          img
            border: 4px solid #fff
        img
          object-fit: cover
          border-radius: 4px

.slideshow-clickable
  cursor: zoom-in
  transition: all 300ms
  &:hover
    filter: brightness(120%)
