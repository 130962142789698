@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/mixins"

.react-table
  font-size: 14px
  border-radius: 4px
  box-shadow: 0 4px 4px 0 #00000040

  &__head
    border-radius: 4px
    height: 37px
    background-color: $primary
    font-weight: $weight-bold

  &__head-row-cell
    min-width: 100px
    padding: 0 6px
    vertical-align: middle
    height: 37px
    color: white
    text-align: center !important

  &__body-row
    &:nth-child(odd)
      background-color: $white
    &:nth-child(even)
      background-color: $beige

  &__body-row-cell
    vertical-align: middle
    height: 37px
    text-align: center !important
    border-right: 1px solid $grey-25
    &:last-of-type
      border-right: 0

    &--primary
      color: white
      background-color: $primary
      font-weight: $weight-medium
      width: 94px
      +desktop
        width: 165px

  &__body-row:last-of-type
    td:first-of-type
      border-radius: 0 0 0 4px
    td:last-of-type
      border-radius: 0 0 4px 0
