.campsider-modal[data-id="product-state"]
    .campsider-modal__content
        padding: toRem(48px) toRem(30px)

        h3
            text-align: center

        ul
            text-align: left

            li
                display: flex
                gap: toRem(15px)
                padding-bottom: toRem(12px)
                margin-top: toRem(12px)

                &:first-child
                    margin-top: toRem(0px)

                &:not(:last-child)
                    border-bottom: 1px solid $grey-100
                    

                span
                    color: $reinsurance

                > div
                    display: flex
                    flex-direction: column
                    gap: toRem(12px)

        .ctas
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            text-align: center
