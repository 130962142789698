@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"

@import "autocomplete-searchbox-input"

.autocomplete-searchbox
  $gap: 10px

  width: 100%
  display: flex
  flex-direction: column
  gap: $gap
  position: absolute
  height: calc(100svh - $gap * 2)
  top: calc(0px - 2 * $gap)
  left: $gap
  padding: 0
  z-index: -1
  overflow: hidden
  border-radius: 4px

  &--open
    width: calc(100svw - ($gap * 2))
    opacity: 1
    z-index: 31
    +desktop
      $width: 650px
      height: auto
      width: $width
      left: calc(50% - $width / 2)
      top: 70px

  &__content
    background-color: $white
    position: relative
    height: 100%
    border-radius: 4px

  $mobile-button-height: 85px

  &__content-lists
    display: flex
    flex-direction: column
    gap: 30px
    padding: 25px 15px
    height: 100%
    max-height: calc(100svh - 63px - $mobile-button-height - 3 * $gap)
    overflow-y: auto
    +desktop
      display: grid
      grid-template-columns: 1fr 1fr
      gap: 0
      grid-row-gap: 30px
      grid-column-gap: 70px
      padding: 25px 20px
      height: auto
      max-height: 500px

  &__mobile-button
    position: absolute
    bottom: 0
    left: 0
    height: $mobile-button-height
    width: 100%
    padding: 15px
    border-top: 1px solid $grey-50

  &__list
    display: flex
    flex-direction: column
    gap: 14px

    +desktop
      &--categories
        grid-row: 1 / 3
        grid-column-start: 2

  &__list-title
    margin-bottom: 4px
    display: flex
    align-items: center
    justify-content: space-between

  &__list-item
    &--with-erase
      display: flex
      align-items: center
      justify-content: space-between
      gap: 10px
      i
        font-size: 12px

  &__link
    &:hover
      text-decoration: underline
    &--with-image
      display: flex
      align-items: center
      gap: 15px
      figure
        +flexCenter
        width: 56px
        height: 56px
        border-radius: 4px
        background-color: $grey-30
      img
        max-height: 45px
        max-width: 45px
        width: auto

  &__erase-icon
    font-size: 10px
    color: $grey-350

  &__overlay
    +overlayFixed
    top: 0
    z-index: 30
    display: none
    cursor: default

    &--visible
      display: block
      background: transparentize($black, 0.25)
