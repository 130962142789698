@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"

@import "./modal-success-message"

// TODO - Will replace twig aside modals

$aside-width-desktop: 510px
$aside-width-desktop-larger: 70lvw
$aside-width-desktop-larger-max: 900px

.react-aside-modal
  +overlayFixed
  z-index: 1000 !important
  max-width: unset
  $this: &

  &--no-animation
    #{$this}__content
      animation: none
      transform: translateX(0)
    #{$this}__background
      animation: none
      opacity: 0.5

  &--visible
    display: block

  &__background
    +overlayFixed
    background: $black
    opacity: 0
    animation: 500ms ease-in-out reveal forwards

  &__content
    padding-bottom: 40px
    position: fixed
    top: 0
    bottom: 0
    right: 0
    background: $white
    width: 100%
    display: flex
    flex-direction: column
    overflow: auto
    --aside-modal-content-width: 100%
    max-width: 100vw
    animation: 250ms ease-in-out slide-up forwards

    +tablet
      --aside-modal-content-width: #{$aside-width-desktop}
      transform: translateX(#{$aside-width-desktop})
      max-width: $aside-width-desktop
      animation: 500ms ease-in-out slide forwards !important
      &--larger
        --aside-modal-content-width: #{$aside-width-desktop-larger}
        transform: translateX(#{$aside-width-desktop-larger})
        max-width: $aside-width-desktop-larger-max

  &__content--no-padding-bottom
    padding-bottom: 0

  &__header
    min-height: 85px
    margin: 0 20px
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $grey-light

    +tablet
      margin: 0 30px

  &__body
    flex: 1

  &__close
    color: $black
    cursor: pointer

  &__title
    +title
    text-transform: initial
    font-size: toRem(22px)
    display: flex
    align-items: center
    gap: 8px

  &__error
    margin-bottom: 20px

  &__error-message
    margin-bottom: 20px

  .error-boundary-component
    margin: 20px
    +tablet
      margin: 30px

.aside-modal-common-content
  margin: 20px
  font-size: 14px
  display: flex
  flex-direction: column
  gap: 20px
  strong
    font-weight: $weight-medium
  ul
    list-style-type: disc
    padding-left: 20px
  ol
    padding-left: 20px
  +tablet
    margin: 20px 30px

  &--no-margin
    margin: 0

  &--full-height
    display: flex
    height: 100%
    flex-direction: column

@keyframes reveal
  0%
    opacity: 0
  100%
    opacity: 0.5

@keyframes slide
  0%
    transform: translateX(var(--aside-modal-content-width))
  100%
    transform: translateX(0px)

@keyframes slide-up
  0%
    transform: translateY(100%)
  100%
    transform: translateY(0)
