@import "../../../bulma-custom/utilities/initial-variables"
@import "../../../bulma-custom/utilities/mixins"
@import "../../../nuuns/functions"
@import "../../../nuuns/mixins"

.react_cart_modal_product
  &__product
    display: flex
    align-items: center
    gap: 19px
    padding: 24px 0
    border-bottom: 1px solid $grey-50
    flex: none
    width: 100%

  &__figure
    display: block
    position: relative
    width: 80px
    height: 80px
    img
      border-radius: 4px
      width: 100%
      height: 100%
      object-fit: cover
    &--guarantee
      +flexCenter
      border-radius: 4px
      background-color: #F8F8F8
      cursor: default
      i
        font-size: 25px

  &__texts
    flex: 1
    display: flex
    align-items: center

  &__texts-block-left
    display: flex
    flex-direction: column
    max-width: 170px

    +tablet
      max-width: 270px

  &__texts-block-rigth
    flex: 1
    display: flex
    flex-direction: column
    align-items: flex-end

  &__texts-brand
    +text
    font-weight: 500
    font-size: 16px
    line-height: 28px

  &__texts-title
    +text
    font-weight: 400
    font-size: 13px
    line-height: 18px

  &__texts-delete
    +text
    font-weight: 400
    font-size: 10px
    line-height: 18px
    color: $grey-350
    position: relative
    width: fit-content
    cursor: pointer
    &:after // Set a non cropped underline unlike text-decoration
      content: ""
      height: .5px
      width: 100%
      position: absolute
      bottom: 4.2px
      left: 0
      background-color: $grey-350
    +tablet
      font-size: 11px

  &__texts-price
    +text
    font-weight: 500
    font-size: 16px
    line-height: 24px

  &__texts-original-price
    +text
    font-weight: 400
    font-size: 14px
    text-decoration: line-through

  +tablet
    height: 156px
