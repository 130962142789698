@import "../../nuuns/mixins"
@import "../../bulma-custom/utilities/mixins"

.collapse
  width: 100%
  display: flex
  flex-direction: column
  gap: 20px
  background-color: white
  padding: 20px
  position: relative
  height: fit-content

  &--clickable
    cursor: pointer

  &--disabled
    cursor: not-allowed
    opacity: 0.5

  &--borderless
    border: none
    padding: 0

  &__header
    display: flex
    justify-content: space-between
    align-items: center

  &__header__title
    +text
    font-weight: 700
    font-size: 16px
    line-height: 28px

    &--waiting
      color: $grey-350

  +tablet
    &__header__title
      font-size: 22px

  +desktop
    border: 1px solid $grey-50
    border-radius: 4px
    max-width: 792px

    &--borderless
      border: none
