@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/mixins"

.paypal-sentence, .oney
  display: flex
  align-items: center
  +touch
    justify-content: center
  +until(400px)
    display: block

  &__logo
    flex: none
    margin-left: 0.8ch
    margin-bottom: -2px

  &__tag
    display: inline-flex
    justify-content: center
    align-items: center
    width: 22px
    height: 22px
    border-radius: 22px
    color: $white !important
    text-decoration: none
    margin-left: 0.8ch
    margin-right: 0.8ch

.paypal-sentence
  &__tag
    background: $blue-paypal

.oney
  &__tag
    background: $green-oney
