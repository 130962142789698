@import "saved-search-chips"

.save-search-modal
  margin: 0 20px

  +tablet
    margin: 0 30px

  &__text
    margin: 23px 0 30px 0

  &__action
    margin-top: 55px
