@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"

$main-header-searchbox-height-mobile: 36px
$main-header-searchbox-height-desktop: 42px

.searchbox-trigger
  display: flex
  flex-direction: row
  align-items: center
  position: relative
  width: 100%
  height: $main-header-searchbox-height-mobile
  border-radius: toRem(8px)
  border: none
  padding: 5px 10px
  background: $white
  gap: 10px

  +desktop
    height: $main-header-searchbox-height-desktop

  &__search
    +geomanist
    -webkit-appearance: none
    border: none
    border-radius: toRem(8px)
    background-color: $white
    color: $black
    height: 100%
    width: calc(100% - 24px)
    padding-left: 0
    position: relative

    &::placeholder
      color: $black
      text-overflow: ellipsis
      overflow: hidden

    &:focus
      outline: none

  &__submit
    cursor: pointer
    font-size: 14px !important
    color: $black
