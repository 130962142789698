@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/mixins"
@import "../nuuns/functions"
@import "../nuuns/mixins"

.campsider-modal
  +overlayFixed
  z-index: 10000
  display: flex
  justify-content: stretch
  align-items: stretch
  // Dialog reset
  border: none
  background: none
  padding: 0
  margin: 0
  width: 100%
  height: 100%

  +desktop
    justify-content: center
    align-items: center

  &.hidden
    display: none

  &__bg
    +overlayFixed
    display: inline-block
    background-color: $black
    opacity: .5
    z-index: 0

  &__cross
    width: toRem(20px)
    height: toRem(20px)
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: toRem(15px)
    right: toRem(15px)
    border: 1px solid $primary
    color: $primary
    border-radius: toRem(20px)
    background-color: $white
    z-index: 1
    cursor: pointer
    i
      font-size: toRem(10px)
      font-weight: bold

  &__content
    +overlayFixed
    width: 100%
    background-color: $white
    overflow: auto
    +desktop
      position: relative
      border-radius: toRem(4px)
      height: fit-content
      max-width: toRem(420px)

    .clip-path
      clip-path: polygon(0 0, 100% 0%, 100% 93%, 0% 100%)
      height: toRem(220px)
      overflow: hidden

    .loader
      animation: spin 2s linear infinite
      border: toRem(5px) solid rgba(46, 51, 38, 0.3)
      border-top: toRem(5px) solid #2E3326
      border-radius: 50%
      height: toRem(53px)
      width: toRem(53px)

  &__header
    margin-bottom: toRem(32px)
    padding: 0 toRem(16px)
    +desktop
      padding: 0 toRem(20px)

  &__body
    margin-bottom: toRem(40px)
    padding: 0 toRem(16px)
    width: 100%
    +desktop
      padding: 0 toRem(40px)

  &__reservation
    align-items: center
    border: solid toRem(1px) $grey-50
    border-radius: toRem(4px)
    display: flex
    height: toRem(40px)
    justify-content: center
    +desktop
      margin: 0 toRem(20px)

  &__address
    border: solid toRem(1px) $grey-50
    border-radius: toRem(4px)
    padding: toRem(12px) 0
    width: 100%
