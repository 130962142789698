.checkbox-block
  user-select: none
  padding: 20px
  display: grid
  grid-template-columns: auto 1fr
  grid-template-rows: auto 1fr
  grid-gap: 16px

  background: white
  border: 1px solid $grey-50
  &--error
    border-color: $danger
  border-radius: 4px

  cursor: pointer

  &__box
    background: white
    width: 21px
    height: 21px
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid $grey-50
    border-radius: 2px

  &__box-center
    width: 68.5%
    height: 68.5%
    transition: all 0.2s ease
    border-radius: 2px

    &--checked
      background: $primary

  &__title
    font-size: 13px
    line-height: 1.2
    display: flex
    align-items: center
    +tablet
      font-size: 14px

  &__content
    font-size: 13px
    grid-column-start: 2
    +tablet
      font-size: 14px
