$footer-background-color: $white-bis !default

.footer
  background-color: $footer-background-color
  padding: 2rem 0.75rem 0.75rem 0.75rem
  a
    color: $grey
  a:hover
    color: $red
  a.icon
    background: $grey
    color: $white
    border-radius: 50%
    margin: 0.25rem
  a.icon:hover
    background: $red
    color: $white
    border-radius: 50%

  .footer-logo
    width:  150px
    height: 43.15px
  .footer-copyright
    text-align: center
    font-size: 0.9375rem
    color: $grey

  &__cs
    position: relative
    z-index: 2

  li a
    font-size: 0.875rem

  .is-sn
    width: 32px
    height: 32px
    i
      font-size: 22px
