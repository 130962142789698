.product-price-tag
  +flexCenter
  gap: 4px
  height: 25px
  min-width: 37px
  padding: 0 6px
  font-size: 13px
  font-weight: $weight-medium
  color: white
  background: $primary
  width: fit-content

  i
    font-size: 15px

  &--special-offer
    background: $black
    color: $white

  &--special-offer-unlimited
    background: $black
    color: $white
    text-transform: uppercase

  &--refurbished
    background: $reinsurance-100
    color: $reinsurance
    text-transform: uppercase

  &--state
    background: #485C5E
    color: $white
    text-transform: uppercase

  &--medium
    font-size: 14px
    height: 27px
