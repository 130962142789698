@import '../nuuns/functions'
@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/mixins"

.filters_panel
  &__panel
    display: grid
    grid-template-rows: 1fr 10fr 1fr
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    height: 100svh
    opacity: 0
    transform: translateY(100%)
    background-color: $beige
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2)
    transition: transform 0.3s ease-in-out

    +desktop // remove transition when resizing from mobile to desktop
      transition: none

    &--show-mobile-filters-panel
      z-index: 1000000
      transform: translateY(0)
      opacity: 1

  &__panel-header
    display: grid
    place-items: center
    border-bottom: 1px solid $grey-300
    height: 74px
    margin-top: 20px

    // small mobile dash on top of bottom sheets
    &:before
      content: ""
      width: 2.5rem
      height: 0.375rem
      position: absolute
      top: 10px
      left: 50%
      -webkit-transform: translateX(-50%)
      transform: translateX(-50%)
      background-color: $grey-50
      border-radius: 1.25rem

  &__panel-header__button
    i
      color: $primary

  &__panel-content
    padding: 0 toRem(30px) toRem(20px) toRem(30px)
    & > *
      padding: toRem(15px) 0
      border-bottom: 1px solid $grey-50
      &:last-child
        border-bottom: none

  &__panel-footer
    padding: 22px 24px
    display: grid
    place-items: center
    gap: 20px

  +desktop
    .mobile
      display: none

    &__panel
      display: block
      position: initial
      height: auto
      width: auto
      transform: translateY(0)
      opacity: 1
      background-color: $white
      box-shadow: none

    &__panel-content
      padding: 0
      & > *
        padding: 0
        border-bottom: none
