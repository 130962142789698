.photo-placeholder
  object-fit: cover
  width: 100%
  height: 100%
  max-height: 100%

  +flexCenter
  border: 1px dashed $grey-50
  border-radius: 4px

  &--error
    border-color: $danger

  &__text
    color: $grey-350
    pointer-events: none
    font-size: 13px
    +tablet
      font-size: 14px
