@import "../bulma-custom/utilities/initial-variables"
@import '../nuuns/functions'
@import '../nuuns/mixins'

.accordion_filter
  border-bottom: 1px solid $grey-50

  &__header
    padding: 14.5px 0
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer
    i
      font-size: 13px

    &__title
      +text
      font-weight: 500
      font-size: 16px

  &__content
    display: none
    &.show
      margin-bottom: 20px
      display: flex
      flex-direction: column
      gap: 12px
