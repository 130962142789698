@import "../../bulma-custom/utilities/initial-variables"
@import '../../nuuns/functions'
@import '../../nuuns/mixins'
@import '../../bulma-custom/utilities/mixins'

.page-header-v2
  min-height: 64px

  padding: 13px
  margin-bottom: $space-between-components

  border-radius: toRem(4px)
  border: 1px solid $grey-50

  &__content
    display: flex
    flex-direction: column

  &__title
    +title
    font-size: 20px
    gap: 7px
    line-height: 24.64px
    margin-top: 4px

  &__title__counter
    +text
    font-size: 16px
    color: $grey-600
    margin-left: 6px


  +tablet
    margin-top: 0
    min-height: 75px
    padding: 13px 20px

    &__title
      line-height: normal
      font-size: 26px


  +tablet
    &__content
      flex-direction: row
      align-items: center
      gap: 11px
