[data-dropdown] .dropdown__notifications
  .titles
    display: flex
    justify-content: space-between
    align-items: center

    h3
      width: 100%
      height: 100%
      position: relative
      display: flex
      justify-content: center
      align-items: center
      gap: toRem(6px)
      padding: toRem(10px)
      text-align: center
      text-transform: none
      font-weight: 700
      border-bottom: 2px solid transparent
      cursor: pointer
      transition: all 0.3s ease-in-out

      &.empty::after
        content: none

      &::after
        content: attr(data-count)
        width: toRem(10px)
        height: toRem(10px)
        display: flex
        justify-content: center
        align-items: center
        border-radius: toRem(10px)
        background-color: $primary
        color: $white
        font-weight: 700
        font-size: toRem(8px)
        line-height: toRem(8px)

      &:hover
        color: $primary

      &.active
        color: $primary
        border-color: $primary

  > ul[data-tab]
    display: none
    flex-direction: column
    text-transform: none

    &.active
      display: flex

    > li
      white-space: normal

      &.active
        a
          > .text > div span
            font-weight: 700

          &::before
            opacity: .1

      a
        align-items: flex-start
        gap: toRem(12px)
        padding: toRem(15px) toRem(46px) toRem(15px) toRem(20px)
        border: 1px solid $grey-100

        &::before
          content: ''
          width: 100%
          height: 100%
          position: absolute
          top: 0
          left: 0
          background-color: $primary
          opacity: 0
          transition: all 0.3s ease-in-out

        > .text > span
          font-weight: 400
          font-size: toRem(9px)
          line-height: toRem(12px)
          color: $grey-300

        i
          position: relative
          top: toRem(3px)
          color: $primary

    > a
      justify-content: center
      padding: toRem(18px) 0
