@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"

.autocomplete-searchbox-input
  position: relative

  display: flex
  align-items: center
  width: 100%
  height: 63px

  border-radius: 4px
  border: none
  background: $white

  &__icon
    cursor: pointer
    padding: 20px
    font-size: 25px
    color: $grey-350

  &__input
    +geomanist
    -webkit-appearance: none
    border: none
    color: $black
    background-color: $white
    height: 100%
    width: calc(100% - 24px)
    padding-left: 0
    position: relative
    border-radius: 4px

    &::placeholder
      color: $black
      text-overflow: ellipsis
      overflow: hidden

    &:focus
      outline: none

  &__button
    margin-right: 10px
    +desktop
      max-width: 138px

  &__close
    +flexCenter
    height: 43px
    width: 43px
    margin: 10px
    padding: 16px
    border-radius: 4px
    background-color: $grey-30
    i
      font-size: 12px
