.form-pop-in
  width: inherit

  .step-link
    display: flex
    font-size: 12px
    cursor: pointer

    span
      width: 15px
      height: 15px
      font-size: 8px
      margin-right: 5px
      box-shadow: none

  .pop-in
    width: fit-content
    margin-top: 18px
    margin-right: 80px

    +desktop
      min-width: 480px
    +mobile
      width: 100%

    form
      background: white
      padding: 36px 70px
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.16)
      border-radius: 4px
      position: relative
      margin-bottom: 50px

      +touch
        padding: 36px 20px !important

        p
          margin: 0

      .radio-group
        label
          font-size: 12px !important

      .radio-group-inputs
        border: 1px solid $blue-dark
        display: flex
        height: 42px
        width: 204px
        justify-content: center
        align-items: center
        padding: 2px
        margin: auto
        position: relative

        .control
          width: 100px
          text-align: center

        .field
          margin-bottom: 0

        input
          position: absolute
          top: 5px
          left: 5px
          z-index: auto
          opacity: 0

        .control
          label
            background-color: $white
            color: $blue-dark
            padding: 0
            margin: 0
            width: 100px
            position: absolute
            z-index: 2
            left: 0
            top: 3px
            height: 38px
            line-height: 38px
            font-size: 14px !important

            &.active
              background: linear-gradient(to right, $blue, $blue-medium) !important
              color: $white
              font-size: 14px !important


    .form-action-buttons .column
      width: 70%
      display: flex
      justify-content: space-around
      position: absolute
      bottom: -20px
      left: 0
      right: 0
      margin: auto

      +mobile
        width: 100%

  .notice
    margin: 15px 0
    display: flex
    justify-content: center
    font-style: italic
    font-size: 12px
    color: $blue-dark

    &.notice-password
      color: $grey
      justify-content: left

  .main-title-1-start
    font-weight: 400

  .info, .email, .warning
    font-size: 12px

  .info
    color: $blue-dark

  .warning
    color: $blue-medium
    font-weight: 200

  .email
    color: $orange
    font-weight: 800

  .more-link
    justify-content: flex-end !important
    margin-top: 15px