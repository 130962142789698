@import './parts/message'
@import './parts/call'

.customer-advice-modal
  padding: 0 20px

  +tablet
    padding: 0 30px

  &__text
    font-size: 14px
    padding: 24px 0
    strong
      font-weight: $weight-medium

  &__forms
    display: flex
    flex-direction: column
    gap: 14px

    &--only-call
      +flexCenter
      height: 282px
      border-radius: 4px
      border: 2px solid $primary
