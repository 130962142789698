@import "guarantee-card-list"

.guarantee-card
  padding: 24px 24px 15px 24px
  border: 1px dashed $grey-50
  border-radius: 4px

  &__title
    line-height: 15px
    font-size: 12px
    color: $primary

  &__value
    font-size: 26px
    font-weight: $weight-medium

    &--monthly
      font-weight: normal
      font-size: 14px
