@font-face
  font-family: 'Geomanist Ultra'
  src: url('../../fonts/Geomanist-Ultra.woff2') format('woff2'),url('../../fonts/Geomanist-Ultra.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Geomanist'
  src: url('../../fonts/Geomanist-Black.woff2') format('woff2'),url('../../fonts/Geomanist-Black.woff') format('woff')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'Geomanist'
  src: url('../../fonts/geomanist-bold-webfont.woff2') format('woff2'), url('../../fonts/geomanist-bold-webfont.woff') format('woff')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'Geomanist'
  src: url('../../fonts/geomanist-book-webfont.woff2') format('woff2'), url('../../fonts/geomanist-book-webfont.woff') format('woff')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'Geomanist'
  src: url('../../fonts/geomanist-medium-webfont.woff2') format('woff2'), url('../../fonts/geomanist-medium-webfont.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Geomanist'
  src: url('../../fonts/geomanist-regular-webfont.woff2') format('woff2'), url('../../fonts/geomanist-regular-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Geomanist Book'
  src: url('../../fonts/geomanist-book-webfont.woff2') format('woff2'), url('../../fonts/geomanist-book-webfont.woff') format('woff')
  font-weight: 400
  font-style: normal
