@import "../bulma-custom/utilities/mixins"
@import "../bulma-custom/utilities/initial-variables"


.header
  display: flex
  justify-content: space-between

  &--underlined
    border-bottom: 1px solid $grey-100
    margin-bottom: 22px

    +desktop
      margin-bottom: 35px

  &__title
    display: inline-flex

    &--underlined
      font-size: 16px
      border-bottom: 3px solid $black
      line-height: 31px
      font-weight: 500

      +desktop
        font-size: 20px

  &__link
    display: inline-flex
    flex-direction: row
    align-items: center
    gap: 8px

    &:after
      content: $icon--arrow-right
      font-family: $icomoon-font-family
      font-size: 20px
      color: $primary
      text-decoration: none !important
