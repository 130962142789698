.campsider-modal[data-id="promo-code"]
    .campsider-modal__content
        padding-bottom: toRem(60px)

        > figure
            width: 100%
            height: toRem(140px)
            clip-path: polygon(0 0, 100% 0%, 100% 93%, 0% 100%)
            overflow: hidden

            img
                width: 100%
                height: 100%
                object-fit: cover

        > div
            padding: 0 toRem(26px)

            h3, p
                text-align: center

            p
                margin-top: toRem(20px)

            form
                max-width: toRem(300px)
                min-height: toRem(28px)
                display: flex
                justify-content: space-between
                align-items: center
                margin-right: auto
                margin-left: auto
                margin-top: toRem(35px)
                background: rgba(38, 47, 44, 0.2)
                border-radius: toRem(8px)

                button, input
                    -webkit-appearance: none
                    border: none
                    background: none

                input
                    width: 100%
                    height: 100%
                    outline: 0
                    padding: 0 toRem(60px) 0 toRem(10px)

                button
                    display: flex
                    justify-content: center
                    align-items: center

        // ul
        //     padding-right: toRem(48px)
        //     padding-left: toRem(48px)

        //     li
        //         display: flex
        //         align-items: center
        //         gap: toRem(15px)

        //         &:not(:first-child)
        //             margin-top: toRem(20px)

        //         > span
        //             display: inline-block
        //             min-width: toRem(40px)
        //             width: toRem(40px)
        //             font-weight: 900
        //             font-size: toRem(48px)
        //             color: $primary

        //         > div
        //             display: flex
        //             flex-direction: column
        //             gap: toRem(12px)

        // .ctas
        //     display: flex
        //     flex-direction: column
        //     justify-content: center
        //     padding-bottom: toRem(26px)
        //     align-items: center
        //     text-align: center
