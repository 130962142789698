.saved-search-chips
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 12px

  &--large
    padding: 12px 0

  > span
    font-size: 14px
    display: flex
    justify-content: center
    align-items: center
    padding: 2px 10px
    gap: 10px
    border-radius: 4px
    border: 1px solid $grey-50
    background-color: #FBFBFB
    color: $black
