@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/mixins"
@import "../nuuns/functions"
@import "../nuuns/mixins"

$aside-width-desktop: 420px

.aside-modal
  +overlayFixed
  z-index: 1000
  display: none
  max-width: unset

  &--visible
    display: block

  &__background
    +overlayFixed
    background: $black
    z-index: 0
    opacity: 0

    &--visible
      animation: 500ms ease-in-out show-user-reviews-background forwards

  &__content
    position: fixed
    top: 0
    bottom: 0
    right: 0
    background: $white
    padding: 38px $container-content-horizontal-padding
    width: 100%
    z-index: 1
    display: flex
    flex-direction: column
    overflow: auto
    --aside-modal-content-width: 100%
    max-width: 100vw

    +tablet
      --aside-modal-content-width: #{$aside-width-desktop}
      transform: translateX(#{$aside-width-desktop})
      max-width: $aside-width-desktop
      padding: 38px 34px

    &--visible
      +tablet
        animation: 500ms ease-in-out show-user-reviews-content forwards

  &__header
    text-align: center
    position: relative
    padding-bottom: 20px
    border-bottom: 1px solid $grey-light

  &__body
    flex: 1
    padding: 20px 0
    display: flex
    flex-direction: column

    &--loading
      display: none

  &__close
    +flexCenter
    position: absolute
    height: 40px
    right: 0
    top: 0

  &__title
    +display
    padding: 0 30px
    font-size: toRem(42px)
    line-height: toRem(46px)

  // Come from modal (.loader)
  &__loader
    display: none

    &:after
      content: ""
      animation: spin-user-reviews-loader 2s linear infinite
      border: toRem(5px) solid rgba(46, 51, 38, 0.3)
      border-top: toRem(5px) solid #2E3326
      border-radius: 50%
      height: toRem(53px)
      width: toRem(53px)

    &--loading
      +flexCenter
      flex: 1


  &__error
    margin-bottom: 20px

  &__error-message
    margin-bottom: 20px


@keyframes show-user-reviews-background
  0%
    opacity: 0
  100%
    opacity: 0.5

@keyframes show-user-reviews-content
  0%
    transform: translateX(var(--aside-modal-content-width))
  100%
    transform: translateX(0px)


@keyframes spin-user-reviews-loader
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
