#ski-finder-container
  min-height: 70px
  +from(1439px)
    min-height: 80px

.ski-finder-touch
  +from(1439px)
    display: none

  min-height: 70px
  height: 70px
  padding: 16px 19px
  display: flex
  gap: 16px
  align-items: center

  border-radius: 4px
  background: rgba(190, 74, 9, 0.09)

  &__icon
    font-size: 38px

  &__texts
    line-height: 19px
    font-size: 14px
    display: flex
    flex-direction: column
    b
      font-size: 16px

  &__button
    margin-left: auto
    justify-self: flex-end

  &--redirection
    margin: 0 15px 20px 15px !important
    border-radius: 4px
    a
      margin-left: auto
    +desktop
      display: none

.ski-finder-desktop
  $this: &

  display: none
  +from(1439px)
    display: flex
    flex-direction: row

  min-height: 80px
  height: 80px
  padding: 21px 20px
  gap: 20px
  align-items: center

  border-radius: 4px
  background: rgba(190, 74, 9, 0.09)

  &__icon
    font-size: 38px

  &__texts
    line-height: 19px
    font-size: 14px
    display: flex
    flex-direction: column
    b
      font-size: 16px

  &__form
    margin-left: auto
    justify-self: flex-end
    display: flex
    gap: 15px

  &--redirection
    +desktop
      padding: 30px 37px
      height: 215px
      min-width: 280px
      margin-top: 25px !important
      display: flex
      flex-direction: column
      #{$this}__form
        width: 100%
        a
          width: 100%
      #{$this}__texts
        align-items: center
