@import "../bulma-custom/utilities/initial-variables"
@import "../nuuns/mixins"

.chip
  +text
  +flexCenter
  font-size: 14px
  line-height: 12px
  padding: 0 4px

  border: 1px solid $grey-50
  border-radius: 4px
  background-color: transparent
  cursor: pointer

  transition: all .20s ease-in-out

  &:hover
    color: white
    background-color: $primary
    border-color: $primary

  &--active
    color: white
    background-color: $primary
    border-color: $primary

  &--filter
    width: 111px
    height: 39px

  &--link
    height: 29px
    padding: 0 10px
