@import '../block/brands'
@import '../block/newsletter'
@import '../block/questions'

.main-footer
  background-color: $black
  color: $beige
  padding: toRem(40px) 0 toRem(62px)
  z-index: 1
  position: relative
  overflow-x: hidden

  +desktop
    padding: toRem(60px) 0 toRem(12px)

  #footer-logo
    img
      width: auto
      height: toRem(30px)
      object-fit: contain

  #footer-resume
    border-bottom: 1px solid $beige

  #footer-editorial
    margin-top: toRem(24px)
    margin-bottom: 0

    +desktop
      margin-bottom: toRem(24px)

  #footer-editorial, #footer-copyright
    & *
      color: $beige

  #footer-lists
    padding-top: toRem(16px)

  #footer-payments, #footer-socials
    display: flex
    align-items: center

  #footer-payments, #footer-socials
    justify-content: center

    +desktop
      justify-content: start

  #footer-payments
    +desktop
      justify-content: right

  #footer-payments, #footer-socials
    margin-bottom: toRem(60px)

    +desktop
      margin-bottom: 0

.footer-link
  text-align: center

  +desktop
    text-align: left

  & *
    color: $beige

  &__title
    margin-bottom: toRem(24px)

  &__list
    margin-bottom: toRem(40px)

    +desktop
      margin-bottom: toRem(48px)

  &__item
    a
      color: $beige

      &:hover
        text-decoration: underline
