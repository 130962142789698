[data-dropdown]
  .dropdown__form
    background: $beige
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    margin-top: -15px

    &__header
      text-align: center
      background: transparent
      color: $black
      padding: 41px 0 20px
      text-transform: uppercase
      &::before
        content: ''
        width: 40px
        height: 6px
        position: absolute
        top: 10px
        left: 50%
        transform: translateX(-50%)
        background-color: $grey-300
        border-radius: 20px
      +tablet
        display: none
      +mobile
        padding: 41px 24px 20px

    &__content
      flex: 1
      overflow: hidden
      overflow-y: auto

      &--textarea
        padding: 20px 13px
        +mobile
          padding: 20px 24px

      &--input
        padding: 0 13px 20px
        +mobile
          padding: 20px 24px

      &--phone
        padding: 0
        display: flex
        flex-direction: column

      &--select-v2
        padding: 0

      &--checkboxes
        overflow-y: auto
        +tablet
          max-height: 204px
          padding: 10px 13px
        +mobile
          padding: 30px 45px

      &--shipments
        padding: 30px 13px 33px
        max-height: 204px
        +mobile
          padding: 30px 24px

      &--category
        padding: 0



    &__search
      .search
        margin-bottom: 0
      +mobile
        padding: 0 24px 0
      +tablet
        padding: 20px 13px 0

    &__separator
      height: 1px
      background-color: $grey-300
      width: 100%
      margin: 20px 0 0

    &__footer
      background: transparent

      +tablet
        display: none
      +mobile
        padding: 20px 24px 20px
