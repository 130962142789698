.skeletons
  display: flex
  flex-direction: column
  gap: 8px

  &__item
    width: 100%
    min-width: 20px
    min-height: 20px
    background: $grey-30
    border-radius: 4px
