p.no-products
  width: 480px
  margin: 90px auto 50px
  text-align: center

  a
    color: $blue-dark
    span
      color: $blue
      text-decoration: underline
