.page-header
  width: 100%
  min-height: toRem(186px)
  position: relative

  +tablet
    min-height: toRem(200px)

  &::after
    content: ""
    width: 25%
    height: 100%
    position: absolute
    top: 0
    right: 0
    background-color: $beige
    clip-path: polygon(48px 0%, 100% 0%, 100% 100%, 0% 100%)
    z-index: 0

    +tablet
      width: 50%
      clip-path: polygon(57px 0%, 100% 0%, 100% 100%, 0% 100%)
  > div
    position: relative
    z-index: 1

    .breadcrumb
      display: block
      padding-top: toRem(10px)

      > ul, ol
        +mobile
          justify-content: center

    > div
      h1
        max-width: toRem(300px)
        padding-top: toRem(36px)

        +tablet
          max-width: none

    > a
      margin-top: toRem(60px)

  &__title
    +mobile
      max-width: toRem(300px)
