.swiper-pagination
  width: 100% !important
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: wrap

  &__bullet
    flex: none
    background: rgba(#000, 0.5)
    height: 3px
    width: 0
    margin: 5px 0
    border-radius: 3px
    transition: background ease-in-out 200ms, margin ease-in-out 500ms, width ease-in-out 500ms

    &--active-main
      flex: none
      width: 20px
      margin: 5px 2px

    &--active
      width: 35px
      background: rgba($white, 1)
