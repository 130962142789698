
.display--one
  +display
  font-size: toRem(96px)
  line-height: toRem(96px)

  +touch
    font-size: toRem(60px)
    line-height: toRem(60px)

  +mobile
    font-size: toRem(42px)
    line-height: toRem(46px)

  // WTF is this class for?
  &.mobile
    font-size: toRem(42px)
    line-height: toRem(46px)

.display--two
  +display
  font-size: toRem(60px)
  line-height: toRem(64px)

  +mobile
    font-size: toRem(42px)
    line-height: toRem(46px)

.title--h1
  +title
  font-size: toRem(48px)
  line-height: toRem(52px)

  +mobile
    font-size: toRem(40px)
    line-height: toRem(44px)

.title--h2
  +title
  font-size: toRem(28px)
  line-height: toRem(35px)

.title--h3
  +title
  font-size: toRem(32px)
  line-height: toRem(36px)

  &.mobile, +mobile
    font-size: toRem(26px)
    line-height: toRem(32px)

.title--h4
  +title
  font-size: toRem(26px)
  line-height: toRem(32px)

  &.v2
    font-size: toRem(26px)
    line-height: toRem(32px)

    +mobile
      font-weight: 500
      font-size: toRem(20px)
      line-height: toRem(24px)

  +mobile
    font-size: toRem(20px)
    line-height: toRem(24px)

.title--h5
  +title
  font-size: toRem(20px)
  line-height: toRem(24px)

/**
 * TODO REFACTO : Add the .text component
 * Add the with --one,[...], --five modifier
 * Add --one-tablet ,[...], --five-tablet modifier to handle specific text on devises > to tablet
 * Add --one-mobile ,[...], --five-mobile modifier to handle specific text on mobile device
 * Add --bold  modifier too
 */
.text--one
  +text
  font-size: toRem(18px)
  line-height: toRem(28px)

.text--two
  +text
  font-size: toRem(16px)
  line-height: toRem(24px)

.text--three
  +text
  font-size: toRem(14px)
  line-height: toRem(20px)

// TODO factorise and improve bem convention (.text with +text / an specific modifier --four / --bold etc.)
.text--four
  +text
  font-size: toRem(12px)
  line-height: toRem(16px)

.text--five
  +text
  font-size: toRem(10px)
  line-height: toRem(16px)

.hover--primary
  &:hover
    color: $primary!important
    transition: color 0.3s ease-in-out

    > *
      color: inherit!important

.hover--underlined
  cursor: pointer
  &:hover
    text-decoration: underline
