$connected: #289E0A

.main-header-ctas
  height: 100%
  display: flex
  align-items: center
  gap: 24px
  justify-content: flex-end

  +desktop
    gap: 30px

  &__assistance
    width: 160px
    display: none
    +desktop
      display: block
  &__assistance-link
    display: flex
    color: white
    align-items: center
    gap: 12px
    margin-right: 2px
    &:hover
      color: white
  &__assistance-icon
    font-size: 19px
  &__assistance-texts
    line-height: 20px
  &__assistance-schedule
    font-size: 12px
  &__assistance-phone
    font-size: 16px
    font-weight: $weight-medium

  &__sell
    +geomanist
    color: $white
    font-size: 13px
    line-height: 1em

    +desktop
      +flexCenter
      color: $beige
      text-transform: uppercase
      font-size: 16px
      font-weight: $weight-bold
      background: $primary
      border-radius: 4px
      height: 42px
      width: 136px
      &:hover, &:active
        color: $beige
      &:hover
        box-shadow: 0 toRem(4px) toRem(10px) rgba(0, 0, 0, 0.1825), inset toRem(-2.78947e-16px) toRem(-4.55556px) toRem(4.55556px) #CC6024, inset toRem(2.78947e-16px) toRem(4.55556px) toRem(4.55556px) #CC6024

  &__login
    +geomanist
    color: $white
    font-size: 13px
    line-height: 1em

    +desktop
      display: none

  &__icon
    +flexCenter
    gap: toRem(5px)
    padding: 0
    border: none
    background-color: transparent
    cursor: pointer
    color: $white
    font-size: 17px

    +desktop
      font-size: 24px

    &--back
      font-size: 1rem
      +desktop
        font-size: 1rem

    &:hover
      color: $primary

    &--connected,
    &--notice
      position: relative

      &::before
        position: absolute
        border-radius: 100%

    &--connected
      &::before
        content: ''
        background-color: $connected
        width: toRem(8px)
        height: toRem(8px)
        bottom: 0
        right: 0
        transform: translateY(50%)

    &--notice
      &::before
        +geomanist
        +flexCenter
        content: attr(data-count)
        width: 20px
        height: 20px
        right: -11px
        top: 1px
        transform: translateY(-50%)
        font-style: normal
        font-weight: 700
        font-size: 12px
        line-height: 8px
        color: #fff
        background-color: #BF1313

    &--hidden-notice
      &::before
        display: none
