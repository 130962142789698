@keyframes slide-left
  0%
    transform: translateX(80%)
  10%, 90%
    transform: translateX(0%)
  100%
    transform: translateX(-100%)

.timed_slider
  height: 46px
  display: flex
  justify-content: space-between
  align-items: center
  overflow: hidden
  border-bottom: 1px solid $grey-50

  &__item
    display: flex
    align-items: center
    gap: 10px
    &--active
      animation-name: slide-left
      width: 100%

  &__icon
    width: 25px
    height: 25px
    border-radius: 4px
    display: grid
    place-items: center
    background: $grey-50

  &__text
    +text
    font-size: 13px
    font-weight: 400

  &__dots
    height: 100%
    display: flex
    align-items: center
    gap: 5px
    z-index: 1
    background-color: $white

  &__dots__dot
    width: 4px
    height: 4px
    border-radius: 20px
    background-color: $grey-50
    transition: background-color .25s ease-in-out
    &--active
      background-color: black
