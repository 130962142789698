.fake-input-trigger
  display: flex
  flex-direction: column
  gap: 6px

  &__label
    font-size: 14px

  &__content
    line-height: 1
    cursor: pointer
    height: 46px
    padding: 0 17px
    display: flex
    justify-content: space-between
    align-items: center
    font-size: 13px
    border: 1px solid $grey-25
    border-radius: 4px
    color: $grey-350

  &__content-icon
    font-size: 15px
    color: $primary
