.btn-main-cta

  color: $beige
  background: $orange
  border-radius: 4px
  letter-spacing: 3px
  font-weight: 500
  height: 45px
  min-width: 150px
  width: fit-content
  padding: 0 25px
  text-transform: uppercase
  font-size: 12px
  border: none
  cursor: pointer
  justify-content: center
  align-items: center
  align-content: center
  display: flex

  a

    color: $beige

  &:focus
    outline: none

  &:disabled
    background: $grey-lighter
    color: $grey-darker
    cursor: auto

.btn-main-cta-inverted

  color: $orange
  background: $white
  border: 2px solid $orange
  border-radius: 4px
  letter-spacing: 3px
  font-weight: 500
  height: 45px
  min-width: 150px
  width: fit-content
  padding: 0 25px
  text-transform: uppercase
  font-size: 12px
  cursor: pointer
  justify-content: center
  align-items: center
  align-content: center
  display: flex

  a

    color: $beige

  &:focus
    outline: none

  &:disabled
    background: $white
    color: $grey
    border: 1px solid $grey
    cursor: auto

.btn-nd-cta

  color: $blue-dark
  background: $beige
  border-radius: 4px
  letter-spacing: 3px
  font-weight: 500
  height: 45px
  min-width: 150px
  width: fit-content
  padding: 0 25px
  text-transform: uppercase
  font-size: 12px
  border: $blue-dark 2px solid !important
  cursor: pointer
  justify-content: center
  align-items: center
  align-content: center
  display: flex
  a

    color: $blue-dark

.btn-soft-green

  color: $green
  background: $beigeHighTransparency
  border-radius: 4px
  letter-spacing: 3px
  font-weight: 500
  height: 45px
  min-width: 150px
  width: fit-content
  padding: 0 25px
  text-transform: uppercase
  font-size: 12px
  cursor: pointer
  justify-content: center
  align-items: center
  align-content: center
  display: flex
  border: none
  a

    color: $green

.btn-soft

  color: $blue-dark
  background: $beigeHighTransparency
  border-radius: 4px
  letter-spacing: 3px
  font-weight: 500
  height: 45px
  min-width: 150px
  width: fit-content
  padding: 0 25px
  text-transform: uppercase
  font-size: 12px
  cursor: pointer
  justify-content: center
  align-items: center
  align-content: center
  display: flex
  border: none
  a

    color: $blue-dark

.btn-main-cta, .btn-nd-cta, .btn-soft, .btn-soft-green
  i
    margin-left: 10px

.btn-main-cta, .btn-nd-cta
  &.absolute
    position: absolute
    left: 0
    right: 0
    bottom: -25px
    margin: auto

.arrow-link

  color: $white
  border-radius: 22.5px
  letter-spacing: 2px
  text-transform: uppercase
  font-size: 10px
  font-weight: 600
  border: none
  width: 36px
  height: 36px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  &::before, &::after
    vertical-align: -webkit-baseline-middle

.modal-close-bis
  background-color: rgba(255,255,255,0)
  border: 2px solid black
  position: absolute
  right: 20px
  transition: all .3s ease-in
  top: 20px
  -moz-appearance: none
  -webkit-appearance: none
  border-radius: 290486px
  cursor: pointer
  pointer-events: auto
  display: inline-block
  flex-grow: 0
  flex-shrink: 0
  font-size: 0
  height: 20px
  max-height: 20px
  max-width: 20px
  min-height: 20px
  min-width: 20px
  outline: none
  vertical-align: top
  width: 20px
  @media screen and (max-width: 415px)
    top: 15px
    right: 15px

  &::before,
  &::after
    background-color: black
    content: ""
    display: block
    left: 50%
    position: absolute
    top: 50%
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg)
    transform: translateX(-50%) translateY(-50%) rotate(45deg)
    -webkit-transform-origin: center center
    transform-origin: center center

  &:before
    height: 2px
    width: 50%

  &:after
    height: 50%
    width: 2px

  &:hover
    background-color: black

    &::before,
    &::after
      background-color: white

  &.is-sticky
    position: sticky

  &.is-reverse
    border-color: white

    &::before,
    &::after
      background-color: white

    &:hover
      background-color: white

      &::before,
      &::after
        background-color: black

  &.is-large
    height: 32px
    max-height: 32px
    max-width: 32px
    min-height: 32px
    min-width: 32px
    width: 32px

  &.is-big
    max-height: 40px
    max-width: 40px
    min-height: 40px
    min-width: 40px

/* CAMPSIDER */
.c-btn
  background: $white
  border: 1px solid $temp-grey-dark
  border-radius: 0.25rem
  cursor: pointer
  font-family: 'Geomanist', 'Arial', sans-serif
  font-size: 0.875rem
  line-height: 0.875rem
  text-align: center
  padding: 0.75rem
  display: inline-block
  min-width: 15.625rem
  transition-property: background, color
  transition-duration: 400ms
  transition-timing-function: ease-in-out

  &:hover
    background: $temp-grey-dark
    color: $temp-grey-lighter

  &:disabled
    cursor: not-allowed
    opacity: .4

    &:hover
      background: $white
      color: inherit

  & + .c-btn
    margin-left: 1rem

  +mobile
    width: 100%

    & + .c-btn
      margin-left: 0
      margin-top: 0.625rem

  &--main
    background: $temp-grey-dark
    color: $temp-grey-lighter

    &:hover
      background: $temp-grey-lighter
      color: $temp-grey-dark

    &:disabled
      &:hover
        background: $temp-grey-dark
        color: $temp-grey-lighter

  &--secondary
    background: $temp-grey-lighter

  &--danger
    background: $red
    color: $temp-grey-lighter

    &:hover
      background: #ff4668
