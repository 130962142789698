.newsletter
  height: 232px
  border-top: 1px solid $grey-50
  border-bottom: 1px solid $grey-50
  padding: 30px 15px
  margin: 50px 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  &__title
    text-align: center
    font-size: 16px
    font-weight: $weight-medium

  &__subtitle
    text-align: center
    font-size: 14px

  &__form
    width: 100%
    margin-top: 37px
    display: flex
    justify-content: center
    form
      width: 100%
      max-width: 390px
      display: flex
      gap: 11px
      input
        +text
        width: 100%
        padding: 15px
        border: 1px solid $grey-25
        border-radius: 4px
        font-size: 13px
        background-color: $white

        &::placeholder
          color: $grey-350

        &:focus
          border-color: $black
          box-shadow: none
          outline: none

        +tablet
          padding: 18px 15px


  +tablet
    &__title
      font-size: 18px

  +widescreen
    margin: 50px 25px
    border: 1px solid $grey-50
    border-radius: 4px
