.accordions
  display: flex
  flex-direction: column
  gap: toRem(15px)

  +touch
    width: 100%
    min-width: auto

  &__item
    background-color: $beige
    border-radius: toRem(4px)
    overflow: hidden

    &.open
      h4 i
        transform: rotate(0)

      .accordions__item__content
        height: auto
        max-height: toRem(318px)
        padding: 0 toRem(16px) toRem(16px) toRem(16px)
        opacity: 1
        transition: all ease-out 0.3s

        +desktop
          padding: 0 toRem(32px) toRem(32px) toRem(32px)

    h4
      display: flex
      justify-content: space-between
      align-items: center
      padding: toRem(32px)
      font-weight: 500
      cursor: pointer

      +touch
        padding: toRem(16px)

      i
        color: $primary
        transform: rotate(180deg)
        transition: transform ease-in-out 0.3s

    &__content
      height: 0
      opacity: 0
      transition: all ease-in 0.3s

      h5
        font-weight: 500
        margin-bottom: toRem(10px)
