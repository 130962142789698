.searchable-select
  position: relative

  &__options
    list-style-type: none !important
    padding: 7px 0 7px 0 !important
    position: absolute
    top: 78px
    z-index: 1

    width: 100%
    background-color: white
    border-radius: 4px
    border: 1px solid $grey-50

    max-height: 315px
    overflow-y: scroll !important

  &__option
    user-select: none
    font-size: 14px
    cursor: pointer
    padding: 11px 20px 11px 15px

    &:hover
      background-color: $grey-15

    &--no-result
      cursor: default
      &:hover
        background-color: white
