@import "../../bulma-custom/utilities/initial-variables.sass"
@import "../../bulma-custom/utilities/mixins.sass"
@import "../../nuuns/functions.sass"

.announces
  padding: 4px 0
  background-color: $reinsurance
  color: $white
  text-align: center
  font-weight: 400
  font-size: toRem(14px)
  line-height: toRem(10px)
  max-height: toRem($announces-height)

  .with-link
    +overlay
    width: 100%
    height: 100%
    z-index: 1

  &--danger
    background-color: $danger
    font-size: toRem(12px)
    line-height: toRem(12px)
    padding: toRem(8px) 0
    a, strong
      font-weight: bold
      color: $white

  .swiper-slide
    +flexCenter
    +text
    font-size: toRem(14px)
    color: $white
    height: 24px
    gap: 8px
    width: 100%

    +between(1024px, 1280px)
      &:last-child
        display: none

    +from(1024px)
      flex: none
      width: unset
      padding-left: 48px
      &:first-child
        transform: translate3d(-10px, 0, 0)
        justify-content: left
        flex: 1
        padding-left: 0

  .swiper-button-prev,
  .swiper-button-next
    width: 24px
    height: 24px
    top: 0

  .swiper-button-prev
    transform: rotate(90deg)
    left: -8px

  .swiper-button-next
    transform: rotate(270deg)
    right: -8px

  .lang-switcher
    min-width: 54px
    .lang-switcher-trigger
      cursor: pointer
    .lang-switcher-menu
      align-items: center
      background: white
      border: 1px solid $grey-50
      border-radius: 4px
      display: flex
      height: auto
      justify-content: center
      margin-top: 4px
      position: relative
      width: 54px
      z-index: 99
      .lang-switcher-content
        flex-flow: column
