@import "../utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"

.container
  margin: 0 auto
  position: relative
  border: none !important
  width: 100%
  // Figma width for desktop :  1440px
  // Figma padding for desktop : 64px x 2 (left and right)
  // Figma padding for mobile : 24px x 2
  // 1360px = 1440px - 2 x 64px - 2 x 24px (to have a fluid layout)
  max-width: $container-max-width
  padding: 0 24px

  &.is-paddingless-touch
    +touch
      padding: 0

  &.is-paddingless-mobile
    +touch
      padding: 0

  &.is-paddingless
    padding: 0

  // Use to simplify swiper integration
  &.has-half-padding
    padding: 0 12px

  &.has-15px-padding-touch
    +touch
      padding: 0 15px

  &.has-three-quarter-padding
    padding: 0 16px

  // Variable that can be used by childrens
  +from(1360px)
    --container-remaining-space: calc(100vw - 1360px)
  +until(1360px)
    --container-remaining-space: calc(100vw - 48px)


.container-large
  +widescreen
    width: 1280px
    max-width: 1280px
