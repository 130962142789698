#notification-cookie
  position: fixed
  bottom: 0
  z-index: 30
  left: 0
  right: 0
  background-color: $beige

  p
    color: $grey
    font-size: 12px
    text-align: center

    +touch
      display: flex
      flex-direction: column
      align-items: center

    .btn-nd-cta
      margin: 20px auto 0


#notifications
  position: absolute
  width: 100%
  height: 60px
  z-index: 31 // Must be above header
  padding: 24px

  .notification
    box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)

  .delete
    top: auto

  .notification:not(.remove)
    animation-duration: 1s
    animation-name: slidein
    animation-iteration-count: 1
    animation-fill-mode: forwards
    right: -100%
    opacity: 0

  .notification.remove
    animation-duration: 500ms
    animation-name: slideout
    animation-iteration-count: 1
    animation-fill-mode: forwards
    right: 0

@keyframes slidein
  from
    right: -100%
    opacity: 0
  to
    right: 0
    opacity: 100

@keyframes slideout
  from
    right: 0
    opacity: -100%
  99%
    right: -100%
    opacity: 0
  to
    right: -100%
    opacity: 0
    height: 0
    margin: 0
    padding: 0
    overflow: hidden
