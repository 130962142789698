@import "../../bulma-custom/utilities/initial-variables"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"
@import "../../campsider/icomoon/variables"

.main-header-menu
  position: relative
  margin-left: -24px
  width: calc(100% + 48px)
  +desktop
    margin-left: 0
    width: 100%
    display: flex
    align-items: center
    gap: 40px

  &__categories-container
    position: relative
    +desktop
      max-width: clamp(732px, 71vw, 1068px)
      flex: 1

  &__categories
    display: flex
    align-items: center
    flex-wrap: nowrap
    padding: 0 24px
    overflow-x: scroll
    overflow-y: visible
    cursor: pointer

    &::-webkit-scrollbar
      display: none !important
    &::-webkit-scrollbar-thumb
      display: none !important

    +desktop
      height: 40px
      padding: 0 24px 0 0

    &:before
      content: ""
      position: absolute
      top: 0
      left: 0
      width: 24px
      height: 100%
      background: linear-gradient(90deg, transparentize($black, 0) 50%, transparentize($black, 1) 100%)
      +desktop
        display: none
    &:after
      content: ""
      position: absolute
      top: 0
      right: 0
      width: 24px
      height: 100%
      background:  linear-gradient(90deg, transparentize($black, 1) 0%, transparentize($black, 0) 50%)

  &__categories-link
    color: $white
    white-space: nowrap
    font-size: 13px
    padding: 0 14px
    &:first-of-type
      padding-left: 0
    +desktop
      padding: 0 50px 18px 0
      &:last-of-type
        padding-right: 0
        &:after
          width: calc(100%)
      font-size: 14px
      position: relative
      &:after
        content: ""
        position: absolute
        bottom: 0
        left: 0
        width: calc(100% - 50px)
        height: 3px
        background: transparent
      &.active, &:hover
        text-shadow: .5px 0 white
        &:after
          background: white

  &__blog
    display: none
    +desktop
      display: block
      margin-left: auto
      padding-bottom: 16px
  &__help
    display: none
    +desktop
      display: block
      padding-bottom: 16px

  &__cta
    display: flex
    flex-direction: row
    color: $white
    text-transform: uppercase
    white-space: nowrap

    &:hover
      color: $primary

    &--burger
      width: 158px
      &:before
        font-family: $icomoon-font-family
        font-size: 15px
        content: $icon--burger-menu
        margin-right: 11px

    &--blog
      justify-content: center
