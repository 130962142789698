@use "sass:math"

@import "../../bulma-custom/utilities/initial-variables.sass"
@import "../../bulma-custom/utilities/derived-variables.sass"
@import "../../campsider/icomoon/variables"
@import "../../bulma-custom/utilities/mixins.sass"
@import "../../nuuns/mixins.sass"
@import "../../nuuns/functions.sass"

$itemHeight: 66px

.menu-dropdown
  $this: &
  width: 100%
  display: block
  position: absolute
  top: 100vh
  left: 0
  padding: 0
  z-index: -1
  background: $white
  transition: top ease-in-out 0.3s
  overflow: hidden
  border-radius: 4px
  +desktop
    transition: unset

  &--open
    $topGap: 10px
    top: calc($topGap - $announces-height)
    left: $topGap
    width: calc(100% - ($topGap * 2))
    opacity: 1
    z-index: 31
    +desktop
      $leftGap: 24px
      left: $leftGap
      top: calc(($main-header-desktop-height - $announces-height) + ($topGap * 2))
      width: calc(100% - ($leftGap * 2))

  &__content
    display: grid
    grid-template-columns: 100% 100% 100%
    height: calc(100svh - 20px)
    position: relative
    z-index: 0
    transition: ease-in-out transform 0.3s
    background: $white
    border-radius: 4px
    +desktop
      height: auto
      max-height: calc(100svh - $main-header-desktop-height - 40px)
      overflow-y: auto
      grid-template-columns: 100%
      grid-template-rows: auto auto
      transition: unset

  &__background
    +overlayFixed
    top: 0
    z-index: 30
    display: none
    +desktop
      top: $main-header-desktop-height

    &--visible
      display: block
      animation: show-menu-dropdown-background ease-in-out 0.3s forwards
      +desktop
        animation: unset
        background: transparentize($black, 0.25)

  &__items-container
    position: relative
    height: auto
    overflow-y: auto
    +desktop
      height: 100%
      overflow-y: unset
      overflow-x: hidden

  &__level-title
    display: flex
    align-items: center
    justify-content: space-between
    p
      display: flex
      align-items: center
      gap: 12px
      span
        text-transform: uppercase
        font-weight: $weight-medium
        font-size: 16px
      a
        text-shadow: .5px 0 $primary
        font-size: 13px
        color: $primary
        &:hover, &:focus
          text-decoration: underline


  &__items
    height: auto
    position: absolute
    width: 100%
    background-color: $white
    transition: transform ease-in-out 0.3s
    +desktop
      width: auto
      padding: 30px 30px 0 30px
      position: relative
    &--hidden
      padding: 0
      height: 0
      overflow: hidden

  &__items-list
    +desktop
      display: flex
      column-gap: 38px
      row-gap: 25px
      flex-wrap: wrap

  &__item
    +desktop
      width: 280px

  &__item-sub-level
    +flexCenter
    height: 33px
    min-width: 253px

  &__item-sub-level-link
    width: 100%
    font-size: 14px
    padding: 6px 12px
    border-radius: 4px
    &:hover, &:focus
      background-color: rgba(190, 74, 9, 0.1)
      color: $primary
    &:focus
      text-decoration: underline

    &--primary
      color: $primary
      &:hover, &:focus
        text-decoration: underline
        background-color: unset

  &__cta
    +text
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 20px
    cursor: pointer

    height: $itemHeight
    padding: 0 20px
    font-size: 16px
    +desktop
      cursor: auto
      height: 56px
      margin: 25px 0
      padding: 0
      color: $primary
      font-weight: $weight-medium

    &:after
      font-family: $icomoon-font-family
      content: $icon--angle-down
      color: $primary
      font-size: 14px
      transform: rotate(-90deg)
      margin-left: auto
      +desktop
        display: none

    &--close
      &:after
        transform: rotate(90deg)
        content: $icon--cross
        color: $black

    &--back
      cursor: default
      padding: 0
      gap: 0
      i
        padding: 20px
        font-size: 14px
      a
        padding: 20px
        text-shadow: .5px 0 $primary
        font-size: 13px
        color: $primary
        &:hover, &:focus
          text-decoration: underline
      &:after
        content: unset

    &--last-level
      &:focus
        text-decoration: underline
      &:after
        content: unset

    &--primary
      color: $primary

    &--primary
      color: $primary

  &__cta-close
    padding: 0 20px
    margin-left: auto
    font-size: 14px
    color: $black

  &__illustration
    +flexCenter
    width: 56px
    height: 56px
    background-color: #F7F7F7
    border-radius: 4px
    img
      max-height: 45px
      max-width: 45px
      width: auto

  &__reassurance
    padding: 68px 30px 30px 30px

@keyframes show-menu-dropdown-background
  0%
    background: transparentize($black, 1)
  100%
    background: transparentize($black, 0.25)
