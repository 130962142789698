.breadcrumb
  width: 100%
  margin-bottom: 0 !important
  padding: 16px 0 16px

  ul
    flex-wrap: nowrap !important

  li
    display: none !important

    & + li::before
      color: $grey-300 !important
      content: "/" !important
      font-size: 12px
      padding: 0 5px

    a
      color: $grey-300 !important
      padding: 0
      display: block

      &::first-letter
        text-transform: uppercase !important

      &:hover
        text-decoration: underline

    &:last-child
      display: flex !important
      a
        color: $primary !important

  li:last-child
    overflow: hidden
    a
      white-space: nowrap !important
      overflow: hidden !important
      text-overflow: ellipsis !important
      max-width: 100% !important

  &__ellipsis
    color: $grey-300 !important
    font-size: 12px

  +tablet
    li
      display: flex !important
    &__ellipsis
      display: none !important
