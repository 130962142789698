.reinsurance
  background-color: $reinsurance
  color: $beige
  margin-top: toRem(40px)
  padding: toRem(10px) 0
  position: relative
  z-index: 1

  +desktop
    padding: toRem(40px) 0

  &__item
    margin: toRem(12px) 0
    display: flex
    flex-direction: column
    align-items: center
    text-align: center

    +desktop
      margin: 0
      flex-direction: row

  &__text
    margin-top: toRem(10px)
    margin-left: 0

    +desktop
      margin-top: 0
      margin-left: toRem(10px)
