.advisor-widget-reduced
  cursor: pointer
  $this: &

  &--small
    #{$this}__status
      top: -18px

  &__container
    padding: 0 16px
    position: relative
    width: 198px
    height: 50px

    background-color: white
    border: 2px solid $primary
    border-radius: 0 4px 4px 4px

    &--small
      width: 100%
      min-width: 179px
      height: 42px
      padding: 0 12px

    &--offline
      border-radius: 4px

  &__status
    position: absolute
    left: -2px
    top: -24px
    background-color: $primary
    border-radius: 4px 4px 0 0
    overflow: hidden

  &__title
    height: 100%
    display: flex
    align-items: center
    gap: 6px
    font-size: 14px
    font-weight: $weight-medium

  &__pedro
    position: absolute
    bottom: -0.5px
    right: 7px
    height: 144%


