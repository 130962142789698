@charset "utf-8"
// Custom~ Nuuns font helpers
@import "nuuns/mixins"
@import "nuuns/functions"
@import "campsider/font"
// Utilities
@import "bulma-custom/utilities/initial-variables.sass"
@import "bulma-custom/utilities/derived-variables.sass"
@import "~bulma/sass/utilities/_all.sass"
@import "bulma-custom/utilities/mixins.sass"
@import "bulma-custom/utilities/controls.sass"
// Base
@import "~bulma/sass/base/minireset.sass"
@import "~bulma/sass/base/generic.sass"
@import "bulma-custom/base/helpers.sass"
// Elements
@import "bulma-custom/elements/box.sass"
@import "bulma-custom/elements/button.sass"
@import "bulma-custom/elements/link.sass"
@import "bulma-custom/elements/container.sass"
@import "bulma-custom/elements/content.sass"
@import "bulma-custom/elements/form-pop-in.sass"
@import "bulma-custom/elements/icon.sass"
@import "bulma-custom/elements/image.sass"
@import "~bulma/sass/elements/notification.sass"
@import "~bulma/sass/elements/progress.sass"
@import "~bulma/sass/elements/table.sass"
@import "~bulma/sass/elements/tag.sass"
@import "~bulma/sass/elements/title.sass"
@import "~bulma/sass/elements/other.sass"
@import "bulma-custom/layout/footer"
@import "bulma-custom/layout/column"
// Form
@import "bulma-custom/form/tools.sass"
@import "~bulma/sass/form/_all.sass"
// Custom:Components
@import "bulma-custom/components/pagination.sass"
@import "bulma-custom/components/breadcrumb.sass"
@import "bulma-custom/components/tabs.sass"
@import "bulma-custom/components/select_field.sass"
@import "bulma-custom/components/cart_summary.sass"
@import "~bulma/sass/components/pagination.sass"
@import "~bulma/sass/components/breadcrumb.sass"
@import "~bulma/sass/components/card.sass"
@import "~bulma/sass/components/dropdown.sass"
@import "~bulma/sass/components/level.sass"
@import "~bulma/sass/components/media.sass"
@import "~bulma/sass/components/message.sass"
@import "~bulma/sass/components/panel.sass"
@import "bulma-custom/components/card.sass"
@import "component/author.sass"
// Custom:Grid
@import "~bulma/sass/grid/_all.sass"
// Custom:Layout
@import "~bulma/sass/layout/hero.sass"
@import "~bulma/sass/layout/section.sass"
@import "bulma-custom/layout/ui-kit.sass"
// Custom:Extensions
@import '~bulma-tooltip'
@import '~bulma-switch'
@import '~bulma-checkradio'
@import '~bulma-carousel'
@import "~@creativebulma/bulma-divider"
// Custom:Helpers
@import "~bulma/sass/helpers/typography.sass"
@import "~bulma/sass/helpers/spacing.sass"
// Nuuns helpers
@import "nuuns/helpers.sass"
@import "nuuns/notifications.sass"
@import "nuuns/readmore.sass"
@import "nuuns/buttons.sass"
// Campsider helpers
@import "campsider/icons.sass"
@import "campsider/icomoon/style.sass"
@import "component/loading.sass"
@import "component/headline.sass"
@import "component/with_link.sass"
@import "component/product_card.sass"
@import "component/product_metadatas_chips"
@import "component/products/adverts/reinsurance_card"
@import "component/guide_summary.sass"
@import "component/products_filters.sass"
@import "component/searchbox/autocomplete-searchbox"
@import "component/searchbox/searchbox-trigger"
@import "component/brands_list.sass"
@import "component/no_products.sass"
@import "component/user_product_preview.sass"
@import "component/login_modal.sass"
@import 'component/facebook_connect'
@import 'component/slideshow'
@import 'component/birthday'
@import 'component/account_product_card'
@import "component/paypal_sentence"
@import "component/header"
@import "component/accordion_filter"
@import "component/inputs/checkbox"
@import "component/filters_panel"
@import "component/dropdown_menu"
@import "component/chip"
@import "component/featured_seller_products"
@import "component/swiper-pagination"
@import "component/warning_block"
@import "component/guides"
@import "component/ski_finder"
@import "component/collapse_block"
@import "component/advisor-widget/advisor-widget"
// Blocks
@import 'block/highlightedBox'
@import "block/main-header"
@import "block/main_footer"
@import 'block/try'
@import 'block/reinsurance'
@import 'block/carousel'
@import 'block/shortCard'
@import 'block/payments'
@import 'block/socials'
@import 'block/apps'
@import 'block/focus'
@import 'block/faq'
@import 'block/modal'
@import 'block/aside-modal'
@import 'block/modals/add_phone'
@import 'block/modals/delete_type'
@import 'block/modals/promo_code'
@import 'block/modals/product_state'
@import "block/gallery"
@import 'block/aside-modal/aside-modal-reinsurance'
@import 'block/aside-modal/aside-modal-shipping'
@import 'block/blog/article/article_card'
@import "block/categories/purchase-protection/purchase-protection"
@import "block/form-grid"
// React components
@import 'react_component/modals/aside-modal'
@import 'react_component/modals/customer-advice/customer-advice'
@import 'react_component/modals/save-search/save-search'
@import 'react_component/modals/product-reservation/product-reservation'
@import 'react_component/_screens/account/my-wallet/components/my-wallet-modal' // TODO make common
@import 'react_component/cart/cart-modal'
@import 'react_component/common/timed-slider'
@import 'react_component/common/loader'
@import 'react_component/common/toast'
@import 'react_component/common/numbered-dot'
@import 'react_component/common/bordered-frame'
@import 'react_component/common/info-block'
@import 'react_component/common/navigable-box'
@import 'react_component/common/faq'
@import 'react_component/common/size-finder'
@import 'react_component/common/skeletons'
@import 'react_component/common/guarantee-card/guarantee-card'
@import "react_component/common/split-payment-form/split-payment-form"
// Layout
@import 'block/layout/menu-aside'
@import 'layout/page-sliced-header'
@import 'layout/account-page-layout'
@import 'block/layout/dropdown'
@import 'block/layout/page-header'
@import 'block/layout/page-header-v2'
@import 'block/layout/accordions'
@import 'block/layout/return'
// React components
@import 'react_component/common/text_input'
@import 'react_component/common/textarea'
@import 'react_component/common/radio_group'
@import 'react_component/common/table'
@import 'react_component/common/select-options'
@import 'react_component/common/searchable-select'
@import 'react_component/common/collapse'
@import 'react_component/common/images-input/images-input'
@import 'react_component/common/fake-input-trigger'
@import 'react_component/common/checkbox-block'
@import 'react_component/common/phone-input-wrapper'
@import 'react_component/common/file-input'
@import "react_component/_screens/order/components/common/address_autocomplete" //move into common
// React modules
@import 'react_component/module/warning-block.module.sass'
// Libraries
@import "~swiper/swiper.scss"
@import "swiper-custom/navigation"
@import "intl-tel-input/build/css/intlTelInput"
@import '~js-datepicker/src/datepicker'

html
  background-color: $black

  #main-header-burger-menu
    .open
      display: inherit

    .close
      display: none

  &.menu--open,
  &.menu-dropdown-open,
  &.modal--open,
  &.gallery-open,
  &.messages-mobile-open,
  &.searchbox-mobile--open
    overflow: hidden !important
    touch-action: none
    -ms-touch-action: none
    html, body
      overflow: hidden !important

    #main-header-burger-menu
      .open
        display: none

      .close
        display: inherit

  &.searchbox-mobile--open
    +touch
      +overlayFixed
      #main-header
        bottom: 0
        top: 0
        //height: unset

#root
  position: relative
  flex: 1

body,
html
  +geomanist
  overflow-x: hidden

  &:not(.instant-scroll)
    scroll-behavior: smooth

  +tablet
    overflow: initial !important

body
  background-color: $white
  color: $black
  +tablet
    overflow: hidden

button, input, optgroup, select, textarea
  +geomanist

strong
  color: $black

mark
  background-color: yellow

b, strong
  font-weight: $weight-medium

.no-padding
  +tablet
    padding: 0 !important

.has-background-black
  background-color: $black !important

.mt-40
  margin-top: toRem(40px)

.is-fullwidth
  width: 100%

.has-tag-new
  position: relative
  &:after
    width: 38px
    height: 20px
    +flexCenter
    content: "NEW !"
    position: absolute
    top: -3px
    right: -9px
    transform: rotate(24deg)
    background-color: $primary
    color: white
    font-size: 10px
    font-weight: $weight-medium
    border-radius: 4px

.cursor-pointer
  cursor: pointer

// utile pour créer un effet de gras sur du texte sans saut visuel désagréable
.text-shadow-5
  text-shadow: .5px 0

// utile pour afficher du contenu venant d'un WYSIWYG sans avoir le style par défaut du website, à compléter
.raw-wysiwyg
  font-size: 14px !important
  a
    text-decoration: underline !important
  ul, ol
    list-style-type: disc !important
    padding-left: 20px !important
  h1,  h2,  h3,  h4,  h5,  h6
    font-size: initial !important
    font-weight: $weight-medium !important

.ul-check-dot
  ul
    list-style-type: none
    li
      display: flex
      align-items: center
      margin-bottom: 14px
      &:last-child
        margin-bottom: 0
    li::before
      content: ""
      display: inline-block
      width: 18px
      height: 18px
      min-width: 18px
      min-height: 18px
      background-image: url('../images/icons/check-dot.png')
      background-repeat: no-repeat
      background-size: contain
      margin-right: 12px

+until(1384px)
  :root
    --horizontal-min-padding: 24px
+between(1384px, 1465px)
  :root
    --horizontal-min-padding: calc((100vw - 1336px) / 2)
+from(1464px)
  :root
    --horizontal-min-padding: 64px

// Clean and Override Browsers styles.
input[type=search]::-webkit-search-cancel-button
  appearance: none !important
