@import "../../bulma-custom/utilities/initial-variables"
@import "../../nuuns/functions"

.warning-block
  align-items: center
  background: $grey-30
  border: 1px solid $grey-50
  display: flex
  font-size: toRem(14px)
  gap: toRem(20px)
  justify-content: center
  margin-bottom: toRem(16px)
  padding: toRem(16px) toRem(20px)
  width: 100%
