.highlighted-box
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24)
  display: flex
  justify-content: center
  flex-direction: column
  padding: 16px
  height: fit-content

  +mobile
    margin-left: 0
  +tablet-only
    margin-left: 0

  .highlighted-box-header
    padding: 0 24px 15px

    p
      font-size: 14px

    .title-front
      margin-bottom: 10px

    .bold-title-start, .bold-title-end
      font-size: 30px

    .bold-title-end
      padding-left: 5px

  .highlighted-box-footer
    margin-top: 20px
    padding: 0 24px
    font-size: 12px
    font-weight: 300

    a
      text-decoration: underline







