@import "../../nuuns/mixins"
@import "../../nuuns/functions"
@import "../../bulma-custom/utilities/initial-variables.sass"
@import "../../bulma-custom/utilities/mixins.sass"


$control-height-v1: 24px
$control-height-v2: 54px

.control
  &.is-campsider
    margin-bottom: 30px
    transition: all ease-in-out 0.3s

    .select
      width: 100%
      &.is-multiple
        height: $control-height-v1 * 4
        select
          height: $control-height-v1 * 4
      &:not(.is-multiple)
        height: $control-height-v1
      &:after
        display: none !important

      select
        width: 100%

      option
        padding-left: 0

    .input,
    .select select
      border: none
      box-shadow: none
      border-radius: 0
      border-bottom: 1px $grey-100 solid
      padding-top: 0
      padding-bottom: 0
      height: $control-height-v1
      +tablet
        padding-left: 45% !important
      +mobile
        padding-left: 50% !important

      &:focus,
      &:hover
        outline: none
        box-shadow: none
        border: none
        border-bottom: 1px $primary solid
        color: $primary

      &::placeholder
        color: $grey-600
        opacity: 1 !important

    .is-left
      text-align: left
      height: $control-height-v1
      display: block
      +tablet
        width: 45% !important
      +mobile
        width: 50% !important
      .label
        color: $black

    .label
      display: inline-block

    .optional
      margin-left: toRem(4px)

    &.is-invisible
      height: 0
      overflow: hidden
      margin-bottom: 0
      border: none

    // Use for phone only
    .dropdown-countries
      position: absolute
      bottom: -200px
      height: 200px
      width: min(calc(35% + 80px), 270px)
      right: 0

    // Use for phone only
    .iti
      width: 100%
      top: 0 !important
      left: 0 !important

      &__country-list
        width: 100%
        overflow-x: hidden
        border: none
        box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1)
        background: $beige

      &__flag-container
        right: min(35%, 190px)
        left: unset !important

      &__selected-flag
        background: transparent
        &:hover
          box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1)
          background-color: transparentize($primary, 0.9) !important

      &__country
        padding: 13px 9px !important
        line-height: 1em
        cursor: pointer
        position: relative
        font-size: 1rem
        height: 50px
        &:hover
          background-color: transparentize($primary, 0.9) !important

      &__country
        color: $black
        font-size: toRem(12px)
        display: flex
        align-items: center

      &__country-name
        white-space: normal
        line-height: 1.2rem
        margin-left: 10px

      &__dial-code
        color: $grey-darker
        font-size: toRem(14px)

  &.is-campsider-v2
    box-shadow: none
    border-radius: 4px
    border: 1px $grey-100 solid
    height: $control-height-v2
    margin-bottom: 10px
    padding-top: 0
    padding-bottom: 0
    background: $white

    +tablet
      padding-left: 45% !important
    +mobile
      padding-left: 50% !important

    &.active, // dropdown.sass handling
    &:hover
      outline: none
      box-shadow: none
      border: 1px $primary solid
      color: $primary

    &.has-dropdown-button-left
      .input[type=button]
        text-align: left !important

    .select-v2
      ul
        height: 100%
        overflow-y: auto
        &.no-padding-top-tablet
          +tablet
            padding-top: 0
        +mobile
          margin-top: -15px
          padding: 15px 0
        +tablet
          &.is-category-selector
            max-height: 300px
          &:not(.is-category-selector)
            max-height: 204px
          padding: 15px 0


      li:not(.is-ignored)
        padding: 15px 20px
        line-height: 1em
        cursor: pointer
        position: relative

        &:not(.empty-message):hover,
        &.is-selected
          background: transparentize($primary, 0.9)

        &.empty-message
          cursor: default
          color: $grey-600

        &.with-picture
          padding-left: 56px
          .image
            position: absolute
            top: 11px
            left: 20px

        &.is-separator
          height: 1px
          overflow: hidden
          border-bottom: 1px solid $grey-100
          padding: 0
          margin: 15px

    .checkboxes
      display: flex
      flex-wrap: wrap
      gap: toRem(10px)
      height: unset !important


    .input
      box-shadow: none
      border: none
      padding: 0 6px 0 0 !important
      height: calc(#{$control-height-v2} - 3px)

    .input[type=button]
      color: $primary
      height: calc(#{$control-height-v2} - 3px)
      text-align: right
      text-overflow: ellipsis
      //white-space: nowrap
      overflow: hidden
      width: 100%

      &:hover,
      &:focus
       text-decoration: underline
      // TODO update max-width 33px : related to the product description bug only
      +desktop
        max-width: 333px
      +tablet-only
        max-width: calc(60vw - 48px)
      +mobile
        max-width: calc(50vw - 48px)

    .input[type=text],
    .input[type=email],
    .input[type=date]
      width: 100%
      word-break: break-word
      background: transparent
      border-bottom: 1px solid $grey-100
      &::placeholder
        color: $grey-600
        opacity: 1 !important
        vertical-align: top

    .textarea
      width: 100%
      height: 140px
      word-break: break-word
      background: transparent
      border: none
      box-shadow: none
      border-bottom: 1px solid $grey-100
      padding: 0
      min-height: unset

      &::placeholder
        color: $grey-600
        opacity: 1 !important
        vertical-align: top

    .is-left
      text-align: left
      height: calc(#{$control-height-v2} - 2px) !important
      padding: 16px 14px
      display: flex
      justify-content: flex-start
      align-items: center
      +tablet
        width: 45%  !important
      +mobile
        width: 50%  !important

      .label
        font-weight: $weight-medium
        color: $black
        display: inline-block
        margin-bottom: 0

      .optional
        margin-left: toRem(6px)
        color: $grey-600

      .more-info
        padding: 0
        margin-left: 10px
        color: $reinsurance
        cursor: pointer
        font-size: 12px

    // use for money widget only
    .is-right
      padding: 16px 14px
      height: calc(#{$control-height-v2} - 2px) !important


    // Use for phone only
    .dropdown-countries
      width: 100%
      position: relative
      flex: 1
      &:before
        content: ""
        height: 1px
        background: $grey-100
        display: block
        margin-left: 13px
        margin-right: 13px
        margin-bottom: 20px
        z-index: 1

    // Use for phone only
    .phone
      box-shadow: none
      border: none
      height: calc(#{$control-height-v2} - 3px)
      padding-left: 110px !important
      border-bottom: none !important

    // Use for phone only
    .iti
      width: 100%

      &--container
        position: relative
        top: unset !important
        left: unset !important
        width: 100%
        display: block !important
        overflow-x: hidden
        padding: 0 1px !important
        margin-top: -19px // 20px (to prevent padding-bottom of dropdown-countries) - 1px (his before height)
        height: 100%
        +desktop
          height: 200px

      &__selected-flag
        background-color: $beige !important
        padding: 0 10px 0 20px !important

      &__highlight
        background-color: transparentize($primary, 0.9) !important

      &__country-list
        background-color: $beige !important
        width: 100%
        overflow-x: hidden
        padding-top: 5px
        top: 0
        height: 100%
        border: none !important
        +touch
          max-height: 100%

      &__divider
        height: 1px
        overflow: hidden
        border-bottom: 1px solid $grey-100
        padding: 0
        margin: 15px 13px

      &__country
        padding: 15px 20px !important
        line-height: 1em
        cursor: pointer
        position: relative
        font-size: 1rem
        &:hover
          background-color: transparentize($primary, 0.9) !important

      &__country
        color: $black
        font-size: toRem(12px)
        display: flex
        align-items: center

      &__country-name
        white-space: normal
        line-height: 1.2rem
        margin-left: 10px

      &__dial-code
        color: $grey-darker
        font-size: toRem(14px)

  &.is-campsider-v3
    box-shadow: none
    border-radius: 4px
    border: 1px $grey-100 solid
    height: $control-height-v2
    margin-bottom: 10px
    padding-top: 0
    padding-bottom: 0
    background: $white

    +tablet
      padding-left: 45% !important
    +mobile
      padding-left: 50% !important

    &.active, // dropdown.sass handling
    &:hover
      outline: none
      box-shadow: none
      border: 1px $primary solid
      color: $primary

    .is-left
      text-align: left
      height: calc(#{$control-height-v2} - 2px) !important
      padding: 16px 14px
      display: flex
      justify-content: flex-start
      align-items: center
      +tablet
        width: 45%  !important
      +mobile
        width: 50%  !important

      .label
        font-weight: $weight-medium
        color: $black
        display: inline-block
        margin-bottom: 0

      .optional
        margin-left: toRem(6px)
        color: $grey-600

      .more-info
        padding: 0
        margin-left: 10px
        color: $reinsurance
        cursor: pointer
        font-size: 12px

    .input
      box-shadow: none
      border: none
      padding: 0 6px 0 0 !important
      height: calc(#{$control-height-v2} - 3px)

    .input[type=text]
      width: 100%
      word-break: break-word
      background: transparent
      &::placeholder
        color: $grey-600
        opacity: 1 !important
        vertical-align: top

  &.has-error
    border-color: $error
    //box-shadow: 0 0  5px transparentize($notification, 0.5)
    .label
      color: $error !important
  &.has-no-label
    padding-left: 12% !important


.field
  &.is-campsider
    width: 100%
    .control
      flex: none
      width: 50%

.field,
.control
  &.is-campsider
    .label,
    .input,
    option
      font-weight: 400
      font-size: toRem(16px)
      line-height: toRem(24px)

  &.is-campsider-v2
    transition: all ease-in-out 0.3s
    &.is-invisible
      height: 0
      overflow: hidden
      margin-bottom: 0
      border: none

    &.has-icons-left
      .icon
        pointer-events: auto

    .label,
    .input,
    option
      font-weight: 400
      font-size: toRem(14px)


.dropdown-control
  // TODO movee into dropdown block / layout / dropdown / control.sass
  position: relative
  width: calc(100% - 6px)
  .dropdown
    background: $beige
    border-top: 2px solid $primary
    z-index: 2
    width: 100% !important
    box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.1)
    +tablet
      margin-top: -6px
    +mobile
      border-top: 0
