@import "../../nuuns/mixins"
@import "../../nuuns/functions"
@import "../../bulma-custom/utilities/initial-variables.sass"
@import "../../bulma-custom/utilities/mixins.sass"

.add-phone-modal
  flex-direction: column

  &__step
    padding: 20px 26px 0
    text-align: center

  &__form
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 50px

  &__ctas
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 80px

  /** TODO MKP-415 : Make it global **/
  .control
    &.is-phone
      position: relative

      .input
        box-shadow: none
        border-radius: 4px
        border: 1px $grey-100 solid
        height: 54px
        margin-bottom: 10px
        padding-top: 0
        padding-bottom: 0
        background: $white
        padding-left: 100px !important

        &.is-open
          border-width: 1px 1px 0 1px
          border-radius: 4px 4px 0 0
          box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.1) !important

      .countries
        position: relative



  /** TODO MKP-415 : Make it global **/
  .iti
    width: 100%

    &--container
      position: static !important
      width: 100%
      display: block !important
      overflow-x: hidden
      padding: 0 1px !important
      margin-top: -1px

    &__selected-flag
      background-color: $beige !important
      padding: 0 10px 0 20px !important

    &__highlight
      background-color: transparentize($primary, 0.9) !important

    &__country-list
      background-color: $beige !important
      width: 100%
      overflow-x: hidden
      border-width: 0 1px 1px 1px !important
      border-radius: 0 0 4px 4px
      box-shadow: 0 12px 10px 4px rgba(0, 0, 0, 0.1) !important
      padding-top: 5px

    &__divider
      height: 1px
      overflow: hidden
      border-bottom: 1px solid $grey-100
      padding: 0
      margin: 0 10px

    &__country
      padding: 15px 20px !important
      line-height: 1em
      cursor: pointer
      position: relative
      font-size: 1rem
      &:hover
        background-color: transparentize($primary, 0.9) !important

    &__country
      color: $black
      font-size: toRem(12px)

    &__dial-code
      color: $grey-darker
      font-size: toRem(14px)


