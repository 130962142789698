.cart-summary
  display: flex
  flex-direction: column
  align-items: center
  gap: toRem(10px)
  margin-bottom: toRem(20px)
  padding: toRem(20px) toRem(27px)
  border: 1px solid $grey-100
  border-radius: toRem(4px)

  +mobile
    margin-top: toRem(20px)

  table
    width: 100%

    tbody
      tr
        &.inactive
          display: none

        td:first-child
          display: flex
          align-items: center
          gap: toRem(10px)

        td:last-child
          text-align: right

        td
          .helper
            position: relative

          i
            cursor: pointer
            color: $reinsurance

      tr:not(:last-child)
        td
          margin-bottom: toRem(3px)

  > button
    width: 100%
