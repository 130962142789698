@import "../../campsider/icomoon/variables"
@import "../../bulma-custom/utilities/initial-variables"

.pagination
  width: 300px
  margin: auto !important
  justify-content: center !important

  .column.has-text-centered
    min-width: 100px
    justify-content: space-around
    display: flex
    align-items: baseline

  a.page,
  .dots
    width: 25px

  .dots
    margin-left: -7px
    margin-right: -7px

  a,
  button
    color: $blue-dark
    font-weight: 700
    padding: 0 10px
    &:hover:not(.more-link)
      text-decoration: underline
      color: $primary

    &.current-page
      color: $primary

    &.more-link
      .arrow-link-simple,
      .arrow-link-double
        font-size: 12px
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        border: 2px solid $primary
        background: $white
        height: 24px
        width: 24px
        border-radius: 12px
        margin-left: 0
        &:before,
        &:after
          font-family: $icomoon-font-family
          color: $primary
          font-size: 12px
        &:hover
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24)

      .arrow-link-simple,
      .arrow-link-double
        margin: 0 -5px
        &:before
          content: $icon--angle-left-bold

      .arrow-link-simple
        &.is-right
          &:before
            transform: scaleX(-1)
            margin-right: 3px
        &.is-left
          &:before
            margin-right: -1px

      .arrow-link-double
        &:after
          content: $icon--angle-left-bold
        &.is-right
          &:before
            transform: scaleX(-1)
            margin-right: -3px
          &:after
            transform: scaleX(-1)
            margin-right: 2px
        &.is-left
          &:before,
          &:after
            margin-right: -3px


.campsider_pagination
  display: flex
  justify-content: center
  height: 54px

  &__wrapper
    display: flex
    align-items: center
    gap: 5px

    padding: 0 4px
    border: 1px solid $grey-50
    border-radius: 4px

    min-width: 300px

    &__page_number
      +text

      padding: 11px 0
      min-width: 29.5px

      display: grid
      place-items: center

      cursor: pointer

      &:hover
        text-decoration: underline

      &--active
        padding: 4px 0
        width: max-content
        background-color: $primary
        color: $white
        border-radius: 4px
        &:hover
          text-decoration: none

      &--ellipsis
        cursor: default
        &:hover
          text-decoration: none

    &__left_arrow
      margin-right: auto
      transform: rotate(90deg)
      color: $primary
      &:hover
        text-decoration: none
      &--disabled
        color: $grey-50

    &__right_arrow
      margin-left: auto
      transform: rotate(-90deg)
      color: $primary
      &:hover
        text-decoration: none
      &--disabled
        color: $grey-50
