$menu-touch-top: 51px
$menu-desktop-top: 50px
$trigger-min-width: 140px
$trigger-max-width: 220px

.dropdown_menu
  position: relative

  &--auto-width
    width: 100%

  $this: &

  &__label
    display: block
    margin-bottom: 6px
    font-size: 14px
    height: $input-label-height

  &__label-secondary
    color: $grey-350

  &__trigger
    background-color: white
    +text
    font-size: 14px

    height: 46px
    min-width: $trigger-min-width
    max-width: $trigger-max-width

    display: flex
    align-items: center
    justify-content: space-between

    padding: 0 15px
    border: 1px solid $grey-25
    border-radius: 4px

    cursor: pointer
    user-select: none

    +desktop
      height: 39px
      &--h46
        height: 46px

    &--auto-width
      gap: 7px
      min-width: unset
      max-width: unset
      justify-content: center
      +desktop
        min-width: unset
        justify-content: space-between

    &--error
      border-color: #DD0000

  &__trigger__title
    &--error
      color: #DD0000
    &--default
      color: $grey-350

  &__trigger__icon
    +text
    transform: rotate(0deg)
    transition: all 0.25s ease

    &--active
      transform: rotate(180deg)

    &--error
      color: #DD0000 !important

  &__menu
    width: 100%
    min-width: $trigger-min-width
    position: absolute
    top: $menu-touch-top
    z-index: 11

    background-color: white

    border: 1px solid $grey-25
    border-radius: 4px

    display: flex
    flex-direction: column

    a:hover
      color: $black !important

    +desktop
      top: $menu-desktop-top

    &--light
      border: none
      padding: 0

    &--auto-width
      max-width: unset
      width: 100%
      right: 0

  &__menu__item
    padding: 15px 15px 12px 15px
    +text
    font-size: 14px
    cursor: pointer
    user-select: none
    &:hover
      background-color: rgba(0, 0, 0, 0.03)

  &--with-label
    #{$this}__menu
      top: calc($menu-touch-top + ($input-label-height * 1.3))
      +desktop
        top: calc($menu-desktop-top + ($input-label-height * 1.3))
