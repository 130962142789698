.nuuns-loading
  +flexCenter
  +overlayFixed
  background: rgba(0,0,0,0.5)

  &:after
    animation: spinAround 500ms infinite linear
    border: 2px solid $grey-lighter
    border-radius: $radius-rounded
    border-right-color: rgba(255,255,255,0)
    border-top-color: rgba(255,255,255,0)
    content: ""
    display: block
    height: 3em
    position: relative
    width: 3em
