.textarea-input
  display: flex
  flex-direction: column
  gap: 6px

  &__label, &__label-secondary
    font-size: 14px

  &__label-secondary
    color: $grey-350

  textarea
    resize: vertical
    +text
    width: 100%
    min-height: 170px
    padding: 15px
    border: 1px solid $grey-25
    border-radius: 4px
    font-size: 14px
    background-color: $white

    &::placeholder
      color: $grey-350

    &:focus
      border-color: $black
      box-shadow: none
      outline: none
