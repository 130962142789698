@import '../bulma-custom/utilities/initial-variables'
@import '../bulma-custom/utilities/mixins'
@import '../nuuns/functions'
@import '../nuuns/mixins'
@import '../campsider/icomoon/style'
@import 'product_price_tag'

.product-card
  $this: &
  position: relative
  cursor:  pointer
  background: $white

  &__link:hover
    color: inherit

  &__picture
    overflow: hidden

  &__figure
    background: $white

  &__image
    object-fit: contain
    transition: transform ease-in-out 500ms

    &--top-center
      object-fit: cover
      object-position: top center

    &--large
      object-fit: cover
      height: 228px !important
      +tablet
        height: 231px !important

  &:hover
    #{$this}__image
      transform: scale(1.05)
    #{$this}__title
      text-decoration: underline

  &--in-featured-seller-slider
    background: #faf1eb

  &__wishlist
    position: absolute
    top: 10px
    right: 10px
    z-index: 1

  &__header
    &--large
      display: grid
      grid-template-columns: auto auto
      grid-column-gap: 4px

  &__brand
    font-size: 18px
    font-weight: $weight-medium
    text-transform: uppercase

  &__title
    display: block
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden

    &:hover
      color: $black

  &__price-infos
    margin-top: 12px
    display: flex
    align-items: baseline
    gap: 6px

    &--large
      margin-top: 0
      flex-direction: column
      align-items: flex-end
      gap: 0

  &__price
    +text
    font-size: 18px
    line-height: 1.5
    font-weight: $weight-medium

    &--discount
      color: $primary

  &__original-price
    +text
    font-size: 13px
    line-height: 1
    color: $grey-300
    display: flex

  &__discount-percentage
    color: $primary
    font-family: 'Geomanist Medium', sans-serif

  &__tag
    +text
    display: flex
    flex-direction: row
    align-items: center
    font-size: 12px
    line-height: 20px
    height: 22px
    padding: 0 9px
    border-radius: 2px
    color: $black
    gap: 8px

  &__bottom-tags
    position: absolute
    bottom: 8px
    left: 0
    z-index: 1
    +flexCenter
    gap: 4px

  &__swiper
    border: 1px solid $grey-card-border
    border-radius: 4px
    margin-bottom: 12px
    overflow: hidden
    position: relative
    &:hover
      #{$this}__navigation
        opacity: 1

  &__navigation
    +flexCenter
    background: $white
    height: 25px
    width: 25px
    border-radius: 13px
    overflow: hidden
    position: absolute
    transform: translateY(-50%)
    top: 50%
    z-index: 10
    cursor: pointer
    opacity: 0
    transition: opacity ease-in-out 500ms
    box-shadow: 0 4px 4px 0 rgba(#000, 0.15)

    +mobile
      display: none

    &:before
      content: $icon--angle-down
      font-family: $icomoon-font-family
      font-size: 11px
      color: $black

    &:hover
      &:before
        color: $primary

    +mobile
      display: none

    &--next
      right: 10px
      &:before
        transform: rotate(-90deg)

    &--prev
      left: 10px
      &:before
        transform: rotate(90deg)

    &--disabled
      background: rgba($white, 0.7) !important
      cursor: not-allowed

@keyframes product-card-visible
  from
    opacity: 0
  to
    opacity: 1
