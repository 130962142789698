.birthday-label
    padding-top: 0
    font-size: 12px !important
    color: $blue-dark

.birthday
  display: flex
  justify-content: space-between

  .day, .month
    margin-right: 20px

    +touch
      margin-right: 10px

  .day
    display: flex
    flex: 1


  .month
    display: flex
    flex: 4

  .year
    display: flex
    flex: 2
