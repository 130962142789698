.navigable-box
  background-color: white
  border-radius: 4px
  border: 1px solid $grey-50

  &__data
    max-height: 315px
    overflow-y: auto
    padding: 7px 0

  &__back
    cursor: pointer
    padding: 11px 20px 11px 15px
    display: flex
    align-items: center
    gap: 9px
    &:hover
      span
        text-decoration: underline

  &__back-icon
    font-size: 14px

  &__back-text
    user-select: none
    font-size: 12px

  &__node
    user-select: none
    font-size: 14px
    cursor: pointer
    padding: 11px 20px 11px 15px
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px

    &:hover
      background-color: $grey-15

    &--no-result
      cursor: default
      &:hover
        background-color: white

  &__next-icon
    font-size: 15px

  &__dot
    +flexCenter
    width: 15px
    height: 15px
    border: 1px solid $grey-350
    border-radius: 50px

    &--active
      &:before
        content: ''
        width: 8px
        height: 8px
        background-color: $primary
        border-radius: 50px
        margin: 5px auto
