.facebook-button
  background: #4267b2
  border-radius: 5px
  color: white
  height: 40px
  text-align: center
  width: 250px !important
  cursor: pointer
  margin: auto !important
  display: flex
  justify-content: center
  padding: 5px !important

  img
    margin-right: 15px

  .icn
    color: white
    display: flex
    align-items: center
    width: 100%
    height: 100%
    font-size: 14px

    span
      margin-left: 20px