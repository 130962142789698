@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/mixins"
@import "../nuuns/functions"
@import "../nuuns/mixins"

.gallery
  +overlayFixed
  display: none
  background: $white
  z-index: 100

  &--visible
    display: block

  &__header
    width: 100%
    display: flex
    flex-direction: row
    position: absolute
    height: 52px
    top: 0
    left: 0
    right: 0
    z-index: 1
    justify-content: flex-end
    align-items: center

    +desktop
      height: 120px
      border: none

  &__content
    position: relative
    z-index: 0
    height: 100%
    overflow: hidden

  &__swiper
    height: 100%
    position: relative
    z-index: 0
    margin-left: auto
    margin-right: auto
    display: flex
    justify-content: stretch
    align-items: stretch

  &__swiper-component
    width: 100%
    height: 100%

  &__button
    position: absolute
    z-index: 1
    opacity: 1
    color: $primary
    padding: 0
    border: none
    margin: 0
    background: none
    border-radius: 20px
    overflow: hidden
    line-height: 1

    &:focus
      outline: none
      outline-offset: 0

    &--right
      top: 50%
      right: 0
      transform: translate3d(-60px, -50%, 0)
      +touch
        display: none

    &--left
      top: 50%
      left: 0
      transform: translate3d(60px, -50%, 0) rotate(180deg)
      +touch
        display: none

    &--close
      top: 0
      right: 0
      transform: translate3d(-60px, 40px, 0)
      +touch
        transform: translate3d(-20px, 40px, 0)

  &__icon
    cursor: pointer
    transition: opacity ease-in-out 0.3s
    color: $primary
    background: $white
    border-radius : 40px
    font-size: 40px

    +touch
      font-size: 20px

    &--disabled
      pointer-events: none
      opacity: 0

  &__slide
    overflow: hidden
    height: 100%
    width: 100%

  &__picture
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

  &__image
    max-height: 100%

    &--zoom-in
      cursor: zoom-in

    &--zoom-out
      cursor: zoom-out

  &__pagination
    +flexCenter
    +desktop
      display: none
    flex-direction: row
    position: absolute
    z-index: 1
    bottom: 0
    height: 92px
    width: 100%
    left: 50%
    transform: translateX(-50%)

  &__bullet
    flex: none
    background: $beige
    border-radius: 6px
    display: block
    margin: 0 1px
    width: 1px
    height: 1px

    &--active
      background: $primary
      width: 12px
      height: 12px
      margin: 0 10px

    &--active-main
      width: 12px
      height: 12px
      margin: 0 10px

    &--active-prev,
    &--active-next
      width: 8px
      height: 8px
      margin: 0 5px

    &--active-next-next,
    &--active-prev-prev
      width: 4px
      height: 4px
      margin: 0 3px
