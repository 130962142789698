@import "../../nuuns/mixins"
@import "../../nuuns/functions"
@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"

.react-file-input
  flex: 1

  &__body
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 10px
    position: relative
    text-align: center
    padding: 0 40px
    border: 1px dashed $grey-50
    border-radius: 4px
    height: 100%
    &--h116
      height: 116px

  &--error
    border-color: $error

  &__file
    +overlayAbsolute
    opacity: 0
    cursor: pointer

  &__icon
    font-size: 26px

  &__text
    font-size: 14px
    text-decoration: underline
    line-height: 17px

  &__error-text
    font-size: 12px
    color: $error
    margin-top: 10px

