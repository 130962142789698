.guides
  display: flex
  gap: 10px
  flex-wrap: wrap

.guide_chip
  $this: &

  +geomanistBook
  cursor: pointer
  display: flex
  align-items: center
  gap: 10px
  padding: 0 12px
  background-color: $grey-30
  height: 28px
  width: auto
  border: 1px solid $grey-50
  border-radius: 100px

  &:hover
    #{$this}__text
      text-decoration: underline

  &__icon
    font-size: 15px
  &__text
    font-size: 13px

  +tablet
    height: 32px
    padding: 0 14px

    &__icon
      font-size: 17px
    &__text
      font-size: 14px

