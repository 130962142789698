
.more-link

  letter-spacing: 2px
  color: $blue-dark
  font-weight: 500
  font-size: 14px
  display: flex
  text-align: center
  justify-content: center
  align-items: center
  text-transform: uppercase

  span
    margin-left: 10px
