.payments
  border: 1px solid #5E5C68
  border-radius: 8px
  padding: 0.75rem
  display: flex
  justify-content: space-around
  align-items: center

  img
    margin: 0 0.5rem

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0
