#questions-title
  height: fit-content
  width: 472px
  text-align: center
  margin: 40px auto 0

  +mobile
    margin-top: 0
    text-align: left
    padding: 35px 30px 0

  p
    margin-top: 0
    font-size: 14px

#questions-content, #ratings-content, .questions

  display: flex
  justify-content: space-around
  flex-wrap: wrap
  flex-direction: row
  margin: 0 auto

  +tablet-only
    justify-content: space-between

  &.container
    border: none

  .divider.is-vertical::before
    background: $grey-lighter

  .question
    display: flex
    position: relative
    line-height: 28px
    margin: 0 auto 60px
    min-height: 125px

    .question-type
      display: flex
      color: $orange
      text-transform: uppercase
      font-size: 12px
      align-items: center
      margin: 0
      width: 50px

      hr
        width: 100%
        background-color: $orange
        height: 1px
        margin: 9px 0px 9px 9px
    .question-content
      width: 100%

      h4

        font-size: 14px
        font-weight: 400
        margin-bottom: 20px
        letter-spacing: 2px

      p
        font-size: 14px
        line-height: 28px

    .type-text
      transform: rotate(-90deg)
      background: white
      padding: 0 5px
