@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/derived-variables"
@import "main-header/announces"
@import "main-header/main-header-ctas"

@import "main-header/main-header-logo"
@import "main-header/main-header-burger"
@import "main-header/main-header-menu" // The menu displayed at the bottom of the header (contains the sports, equipments etc.links)
@import "main-header/user-dropdown"
@import "main-header/menu-dropdown"
@import "main-header/main-header-order"

.main-header
  background: $black
  height: $main-header-touch-height
  z-index: 32

  +desktop
    height: #{$main-header-desktop-height}

  &--one-row
    height: $main-header-one-row-height
    +desktop
      height: $main-header-one-row-height

  &__annouces
    height: 32px
    width: 100%

  &__grid
    display: grid
    grid-template-columns: 20px 65px 1fr
    grid-template-rows: 24px 36px auto
    grid-column-gap: 14px
    grid-row-gap: 14px
    padding: 12px 0
    grid-template-areas: "logo logo ctas" "burger search search" "categories categories categories"

    +desktop
      height: 100%
      grid-template-columns: 158px 1fr auto
      grid-template-rows: 42px auto
      grid-column-gap: 35px
      grid-row-gap: 20px
      padding: 16px 0 0 0
      grid-template-areas: "logo search ctas" "menu menu menu"

    &--one-row
      grid-template-rows: 24px

  &__logo
    grid-area: logo
    +desktop
      margin-right: 10px

  &__ctas
    grid-area: ctas

  &__burger
    grid-area: burger
    +desktop
      display: none

  &__search
    grid-area: search

  &__menu
    grid-area: categories
    +desktop
      grid-area: menu




