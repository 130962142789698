$icomoon-font-family: "icomoon" !default
$icomoon-font-path: "../../../fonts" !default

$icon--case: "\e936"
$icon--arrow-left: "\e908"
$icon--truck: "\e932"
$icon--card-payment: "\e934"
$icon--package-delivery: "\e935"
$icon--filters-filled: "\e933"
$icon--mail: "\e900"
$icon--reconditioned: "\e92f"
$icon--check-macaroon: "\e930"
$icon--medal: "\e931"
$icon--simple-minus: "\e92d"
$icon--simple-plus: "\e92e"
$icon--star-angular: "\e921"
$icon--picto-check: "\e92a"
$icon--camera: "\e924"
$icon--cb: "\e923"
$icon--edit: "\e920"
$icon--comment: "\e901"
$icon--like: "\e902"
$icon--trash: "\e91c"
$icon--sort: "\e91b"
$icon--star-filled: "\e91a"
$icon--star: "\e903"
$icon--angle-down: "\e904"
$icon--filled-arrow-right-path1: "\e90b"
$icon--filled-arrow-right-path2: "\e92c"
$icon--arrow-right: "\e905"
$icon--bell: "\e906"
$icon--bell-filed: "\e925"
$icon--burger-menu: "\e907"
$icon--cross: "\e909"
$icon--eye: "\e90a"
$icon--filters: "\e90d"
$icon--heart: "\e90e"
$icon--heart-filed: "\e926"
$icon--home: "\e90f"
$icon--home-filed: "\e927"
$icon--logout: "\e910"
$icon--plane: "\e911"
$icon--plus: "\e912"
$icon--rounded-cross: "\e928"
$icon--question: "\e913"
$icon--search-menu: "\e914"
$icon--search: "\e915"
$icon--shield: "\e916"
$icon--shield-filled: "\e90c"
$icon--signal: "\e917"
$icon--trust: "\e918"
$icon--user: "\e919"
$icon--warning: "\e91d"
$icon--wifi: "\e91e"
$icon--search-menu-light: "\e91f"
$icon--angle-left-bold: "\e922"
$icon--share: "\e929"
$icon--check: "\e92b"
$icon--trend-up: "\e93c"
$icon--cart-market: "\e93b"
$icon--arrows-circle: "\e93a"
$icon--notice-pen: "\e939"
$icon--coin-stack: "\e938"
$icon--double-chevron-right: "\e937"
$icon--lock: "\e940"
$icon--cb-outline-thin: "\e941"
$icon--stopwatch: "\e93d"
$icon--magic: "\e93e"
$icon--headset: "\e93f"
$icon--fire: "\e942"
$icon--truck-outline: "\e943"
$icon--edit-filled: "\e944"
$icon--shield-outline: "\e945"
$icon--chat: "\e946"
$icon--back: "\e947"
$icon--chat-question: "\e948"
$icon--message: "\e949"
$icon--phone: "\e94a"
$icon--notification: "\e94b"
$icon--check-circle-filled: "\e94c"
$icon--eye-crossed: "\e94d"
$icon--gavel: "\e94e"
$icon--pencil: "\e94f"
$icon--download: "\e950"
$icon--move: "\e951"
$icon--check-thin: "\e952"
$icon--plus-thin: "\e953"
$icon--calendar: "\e954"
$icon--info: "\e955"
$icon--paste: "\e956"
$icon--circle-exclamation: "\e957"
$icon--anticlockwise-arrow: "\e958"
$icon--skis: "\e959"
$icon--level: "\e95a"
$icon--flash: "\e95c"
$icon--battery: "\e95d"
$icon--magic-outline: "\e967"
$icon--mesure: "\e95f"
$icon--bicycle-frame: "\e95b"
$icon--bicycle-fork: "\e960"
$icon--mountains-outline: "\e961"
$icon--bicycle-group: "\e962"
$icon--shield-outline-sharp: "\e963"
$icon--open-box-outline: "\e964"
$icon--clock: "\e965"
$icon--store: "\e966"

