.product-metadatas-chips
  display: flex
  gap: 10px

  $this: &

  &__chip
    line-height: 1.5
    text-align: left
    box-sizing: border-box
    padding: 2px 10px
    font-size: 14px
    height: 24px
    color: black
    background-color: $grey-30
    border-radius: 2px
    white-space: nowrap
    &:last-of-type
      overflow: hidden
      text-overflow: ellipsis

  &--medium
    #{$this}__chip
      height: 26px

