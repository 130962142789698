@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/derived-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"

.main-header-burger
  +flexCenter
  cursor: pointer
  height: 100%
  width: 100%
  grid-area: burger
  color: $white
  font-size: 18px

  &--cross
    font-size: 16px
