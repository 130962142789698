#size-finder-container
  min-height: 267px
  +tablet
    min-height: 156px

.size-finder
  +tablet
    display: grid
    justify-content: space-between
    grid-template-columns: 1.4fr 1fr

  &__form
    display: grid
    grid-template-columns: 1fr
    grid-row-gap: 26px
    margin-bottom: 40px
    +from(374px)
      grid-template-columns: 1fr 1fr
      grid-column-gap: 15px
    +from(424px)
      grid-column-gap: 26px
    +tablet
      margin-bottom: 0


  &__results
    display: flex
    flex-direction: column
    align-items: center
    +tablet
      margin: auto

  &__results-label
    font-size: 12px
    color: $primary

  &__results-result
    font-size: 26px
    font-weight: $weight-medium
    transition: transform 0.3s ease-in-out
    &.animate
      transform: scale(1.1)


