.purchase-protection-card
  $this: &

  flex: 1
  min-width: 212px
  height: 70px
  min-height: 70px

  padding: 0 4px
  display: flex
  justify-content: center
  align-items: center
  gap: 15px

  background-color: $grey-30
  border-radius: 4px
  cursor: pointer

  &:hover
    #{$this}__text
      text-decoration: underline

  &__icon
    color: $primary
    font-size: 30px

  &__text
    font-size: 14px
    line-height: 18px
    strong
      font-weight: $weight-medium
    +tablet
      font-size: 16px
    +desktop
      br
        display: none

  &--light
    height: auto
    padding: 0 !important
    background-color: white
    +tablet
      flex: 1 !important
      height: auto !important
      #{$this}__text
        font-size: 14px
    +desktop
      #{$this}__text
        br
          display: block

  +tablet
    width: auto
    height: 80px
    min-height: 80px

    border-radius: 0

    &:first-of-type
      border-top-left-radius: 4px
      border-bottom-left-radius: 4px
    &:last-of-type
      border-top-right-radius: 4px
      border-bottom-right-radius: 4px

    .swiper-slide
      width: auto

  &--compact
    padding: 0 10px
