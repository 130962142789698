@import "../bulma-custom/utilities/mixins"
@import "../bulma-custom/utilities/initial-variables"

.featured_seller_products
  background: #faf1eb
  border-top: 1px solid $primary
  border-bottom: 1px solid $primary
  padding: 20px 0
  margin: 13px 0

  grid-column: 1 / 3

  &--large
    border: 1px solid $primary
    border-radius: 4px
    grid-column: 1 / 1

  .swiper-button-disabled
    opacity: 0.5
    cursor: not-allowed

  &__header
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    gap: 20px

    padding: 0 15px
    margin-bottom: 25px

  &__header-texts
    display: flex
    flex-wrap: wrap

  &__header-texts-main
    display: flex
    flex-direction: column

  &__header-texts-title
    font-size: 16px
    font-weight: $weight-medium
    line-height: 1

  &__header-texts-title-icon
    margin-left: 6px
    color: $primary
    font-weight: $weight-semibold

  &__header-texts-subtitle
    font-size: 12px
    color: $grey-600

  &__actions-link
    max-height: 35.5px
    height: min-content

  &__actions-swipe-left,
  &__actions-swipe-right
    display: none
    height: 35.5px
    width: 38px
    border-radius: 4px
    border: 1px solid $grey-50
    cursor: pointer
    background: white
    i
      color: $primary

  &__actions-swipe-left
    i
      transform: rotate(90deg)

  &__actions-swipe-right
    i
      transform: rotate(270deg)

  +tablet
    border: 1px solid $primary
    border-radius: 4px

  +desktop
    grid-column: 1 / 4

    &--large
      grid-column: 1 / 3

    &__header-texts
      flex-direction: column

    &__actions
      display: flex

    &__actions-swipe
      display: flex
      gap: 12px

    &__actions-swipe-left
      margin-left: 26px

    &__actions-swipe-left,
    &__actions-swipe-right
      display: grid
      place-items: center

  +widescreen
    grid-column: 1 / 5

    &--large
      grid-column: 1 / 4

    &__actions-swipe-left,
    &__actions-swipe-right
      display: grid
      place-items: center
