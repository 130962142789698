=geomanistUltra()
  font-family: 'Geomanist Ultra', sans-serif !important
  font-display: auto

=geomanist()
  font-family: 'Geomanist', sans-serif !important
  font-display: auto

=geomanistBook()
  font-family: 'Geomanist Book', sans-serif !important
  font-display: auto

=flexCenter()
  display: flex
  justify-content: center
  align-items: center

=inlineFlexCenter()
  display: inline-flex
  justify-content: center
  align-items: center

=overlayFixed()
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0

=overlayAbsolute()
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0

=display
  +geomanistUltra
  line-height: 1.5
  font-style: normal
  text-transform: uppercase
  font-weight: normal
  color: $black
  list-style: none

=title
  font-style: normal
  font-weight: 700
  list-style: none
  color: $black

=text
  font-style: normal
  font-weight: 400
  list-style: none
  color: $black

  &--bold
    font-weight: 500

=ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
