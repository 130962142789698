@import "./dropdown/account"
@import "./dropdown/notifications"
@import "./dropdown/sort"
@import "./dropdown/form"

[data-dropdown]
  position: relative

  &[data-fullscreen-mobile].active
    +mobile
      .dropdown
        display: block
        position: fixed
        top: 0
        left: 0
        width: 100vw
        // calc 100% rather than 100% is a hack that helps the pop-in to take 100% of the available height.
        //This avoids having the safari's navigation bar to be above over our popin
        height: calc(100%)
        transition: all 0.3s ease-in-out
        transform: translateX(0)
        background: $beige

  .dropdown
    width: toRem(300px)
    display: none
    padding-top: toRem(15px)
    position: absolute
    top: auto
    left: 50%
    transform: translateX(-50%)
    color: $dark
    z-index: -1
    height: unset

    &__sort-title
      align-self: center

    // is-sort modifier used for categories pages
    &.is-sort
      +mobile
        border-top: none
        overflow: auto
        background-color: $beige
      +tablet
        width: 195px
        transform: translateX(-75%)

    > ul
      width: 100%
      background-color: $beige

      li
        margin: 0
        white-space: nowrap
        text-transform: none

        a
          position: relative
          display: flex
          justify-content: space-between
          align-items: center
          background-color: transparent
          transition: all 0.3s ease-in-out

  &.active,
  &[data-hover]:hover
    .dropdown
      display: inherit
      z-index: 10000
    +mobile
      .dropdown
        &.is-sort
          opacity: 1
          transform: translateY(0)

          &::before
            content: ''
            width: toRem(40px)
            height: toRem(6px)
            position: absolute
            top: toRem(10px)
            left: 50%
            transform: translateX(-50%)
            background-color: $grey-300
            border-radius: toRem(20px)

  &:not(.active)
    +mobile
      .dropdown
        &.is-sort
          opacity: 0
          transform: translateY(100vh)
