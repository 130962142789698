@import "../../common/steppers/simple-stepper"

.product-reservation
  height: 100%
  display: flex
  flex-direction: column

  &__block
    border-radius: 4px
    padding: 24px
    border: 1px solid $grey-50

  &__body
    flex: 1
    padding: 20px 20px 25px 20px
    margin: 0
    +tablet
      padding: 20px 30px 30px 30px

  &__product
    display: flex
    gap: 14px
    margin-bottom: 10px

  &__product-picture
    width: 68px
    height: 68px
    border-radius: 4px
    background-color: $grey-50
    object-fit: cover

  &__product-title
    margin: 6px 0 10px 0
    font-size: 16px
    color: $black
    font-weight: $weight-medium

  &__price
    display: flex
    justify-content: space-between
    &:first-of-type
      margin-bottom: 5px

  &__disclaimer
    font-size: 12px
    margin-top: 10px

  &__footer
    width: 100%
    +tablet
      position: initial
      box-shadow: none
