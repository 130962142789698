$image-height-mobile: 480px
$image-height-desktop: 630px

.focus
  display: flex
  flex-direction: column
  min-height: toRem(546px)

  &--image-first
    flex-direction: column-reverse

  +desktop
    flex-direction: row

  &--full
    height: $image-height-mobile
    min-height: auto
    overflow: hidden
    +desktop
      height: $image-height-desktop

  &-content
    padding: toRem(74px) toRem(24px) toRem(34px)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    +desktop
      padding: toRem(16px) toRem(100px)
      min-height: toRem(470px)
      max-width: toRem(655px)

    &--with-shape
      background-position: top
      background-size: cover

      & + .focus-image
        padding-top: 0

        +desktop
          padding-top: toRem(16px)

  &-image
    position: relative
    height: 100%
    width: 100%

    &--gradient
      &::before
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 1
        background: linear-gradient(180deg, rgba(33, 41, 44, 0) 49.72%, rgba(33, 41, 44, 0.8) 100%)

    &__legend
      display: flex
      flex-direction: column
      align-items: center
      gap: 20px
      position: absolute
      left: 0
      right: 0
      top: toRem(45px)

    &__x
      color: $white
      font-size: toRem(15px)
      line-height: toRem(15px)

    &__logo
      width: 116px
      height: auto

    &__brand
      width: 116px
      height: auto

  &-background
    overflow: hidden

    & > picture img
      height: $image-height-mobile
      object-fit: cover
      width: 100%

      +desktop
        height: $image-height-desktop

    &--center-top
      & > picture img
        object-position: center top

  &-extra
    position: relative
    z-index: 2
    margin-top: toRem(-48px) //96

    +desktop
      margin-top: toRem(-48px)

    &--with-link
      margin-top: toRem(-108px)

    &__link
      margin-bottom: toRem(20px)
      width: 100%

      +desktop
        margin-bottom: toRem(29px)
        width: fit-content
