.react_loader
  -webkit-animation: spinAround 500ms infinite linear
  -moz-animation: spinAround 500ms infinite linear
  animation: spinAround 500ms infinite linear
  border: 2.5px solid $black
  border-radius: 9999px
  border-right-color: transparent
  border-top-color: transparent
  content: ""
  display: block
  position: relative
