@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/mixins"

.react_radio_group
  display: flex
  flex-direction: column
  border: 1px solid $grey-50
  border-radius: 4px
  position: relative

  $this: &

  &--scrollable
    overflow-y: auto
    max-height: 202px

  &--lightest
    border: none

  &--primary-checked
    border: 2px solid $primary

  &__radio
    border-bottom: 1px solid $grey-50

    &[data-loading="true"]
      opacity: 0
      border-color: transparent

    &[data-loading="false"]
      opacity: 1

    &:first-of-type
      border-top-left-radius: 4px
      border-top-right-radius: 4px

    &:last-of-type
      border-bottom-left-radius: 4px
      border-bottom-right-radius: 4px
      border-bottom: none

    &--checked
      height: 100%
      &[data-loading="false"]
        background-color: #FAFAFA

    &--primary
      background: white !important

    &--light
      border-bottom: none
      background: white !important
      &:first-child
        padding-top: 7.5px
      &:last-child
        padding-bottom: 7.5px

    &--lightest
      padding-bottom: 15px
      border-bottom: none
      background: unset !important
      &:last-of-type
        padding-bottom: 0 !important
      &:last-child
        padding-bottom: 0 !important

  &__radio__input
    cursor: pointer
    position: relative
    padding: 0 20px
    display: flex
    align-items: center
    min-height: 77px
    &--primary
      min-height: 44px

    +desktop
      min-height: 60px
      &--primary
        min-height: 44px

    &[data-loading="true"]
      cursor: default

    input
      appearance: none
      -webkit-appearance: none
      -moz-appearance: none
      border: none
      outline: none

    &--light
      padding: 7.5px 15px !important
      min-height: auto
      &:hover
        background-color: $grey-30

    &--lightest
      padding: 0 !important
      min-height: auto

    &--has-subtitle
      min-height: 86px

  &__radio__input__dot
    width: 21px
    height: 21px
    min-width: 21px
    min-height: 21px
    margin-right: 15px
    border-radius: 50%
    border: 1px solid $grey-50
    transition: all .25s ease-in-out

    &--primary
      height: 12px
      width: 12px
      min-height: 12px
      min-width: 12px
      margin-right: 10px

    &--light
      background-color: white
      margin-right: 10px

    &--checked
      background-color: $primary
      border-color: $primary
      background-image: radial-gradient(circle, $white 3.2px, transparent 3.2px)
    &--primary-checked
      background-image: radial-gradient(circle, $white 2px, transparent 2px)

    &--readonly
      display: none

  &__radio__input__texts
    font-size: 14px
    line-height: 18px
    display: flex
    justify-content: space-between
    gap: 5px
    width: 100%

  &__radio__input__texts__title
    width: 100%
    line-height: 23px
    &--primary, &--bold
      font-weight: $weight-medium
    &--primary-checked
      color: $primary
    &--fs16
      font-size: 16px

  &__radio__input__texts__subtitle
    white-space: break-spaces
    font-weight: normal
    font-size: 14px
    color: $grey-350

  &__radio__input__texts__description
    white-space: nowrap
    font-weight: 500
    position: relative
    &--sub
      position: absolute
      bottom: -17px
      right: 0
      font-size: 11px
      color: $grey-350
      font-weight: normal
    &--fs16
      font-size: 16px

  &__radio__children
    cursor: auto
    margin: 0 20px 20px 20px

  +tablet
    &__radio__input__texts
      font-size: 14px


  /* large modifiers */

  &--guarantee-card
    #{$this}__radio__input__texts__title
      color: $primary
      font-size: 12px
      text-transform: uppercase
      text-shadow: .5px 0
    #{$this}__radio__input__texts__subtitle
      text-transform: none
      text-shadow: none
      color: $black
      font-size: 22px
      font-weight: $weight-medium
    #{$this}__radio__input__texts__subtitle-secondary
      text-transform: none
      text-shadow: none
      color: $black
      font-size: 14px
      margin-left: 4px
    #{$this}__radio__children
      border-top: 1px solid $grey-50
