@import '../../../bulma-custom/utilities/initial-variables'
@import '../../../bulma-custom/utilities/mixins'
@import '../../../nuuns/functions'
@import '../../../nuuns/mixins'
@import '../../../campsider/icomoon/style'


.reinsurance-card
  +flexCenter
  flex-direction: column
  background: $white
  border: 1px solid $grey-card-border
  padding: 50px 15px
  text-align: center
  font-size: 1em
  cursor: pointer


  +from(1378px) // Figma template
    --px: 1px
  +between(1216px, 1379px)
    // 958px : the available width at 1379px, 420px the unavailable width at 1379px
    --px: calc((100vw - 420px) / 958)
  +between(1023px, 1216px)
    // 958px : the available width at 1216px, 438px the unavailable width at 1379px.
    // 1.33 is a custom ratio due to the change from 4 cards to 3 cards
    --px: calc((100vw - 420px) / 958 * 1.33)
  +between(768px, 1023px)
    // 703 : the available width at 1024, 411px the unavailable width at 1024.
    // 1.5 is a custom ratio due to the change from 3 columns to 2 columns
    --px: calc(((100vw - 320px) / 703) * 1.5)

  +until(768px)
    --px: calc(100vw / 375)




  &__figure
    color: $reinsurance
    font-size: calc(36 * var(--px))
    height: calc(50 * var(--px))
    margin-bottom: calc(16 * var(--px))

    +desktop
      font-size: calc(43 * var(--px))
      height: calc(60 * var(--px))
      margin-bottom: calc(18 * var(--px))

  &__body
    // todo

  &__title
    +text
    font-weight: $weight-medium
    font-size: calc(16 * var(--px))
    line-height: calc(24 * var(--px))
    margin-bottom: calc(11 * var(--px))

    +desktop
      margin-bottom: calc(13 * var(--px))


  &__description
    +text
    margin-bottom: calc(9 * var(--px))
    font-size: calc(12 * var(--px))
    line-height: calc(14 * var(--px))

    +desktop
      margin-bottom: calc(24 * var(--px))
      font-size: calc(14 * var(--px))
      line-height: calc(17 * var(--px))

  &__link
    +text
    text-decoration: underline
    font-size: calc(12 * var(--px))
    line-height: calc(14 * var(--px))

    &:hover
      color: $primary

