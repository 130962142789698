.numbered-dot
  +flexCenter
  font-size: 12px
  font-weight: $weight-medium
  width: 22px
  height: 22px
  border-radius: 50%
  background-color: $primary
  color: white
  line-height: 0
  &--small
    width: 18px
    height: 18px
    font-size: 10px

  +desktop
    width: 26px
    height: 26px
    &--small
      width: 22px
      height: 22px
      font-size: 12px

  &--circle-19
    width: 19px
    height: 19px
  &--font-size-14
    font-size: 14px
  &--font-size-11
    font-size: 11px
  &--font-size-9
    font-size: 9px
  &--font-size-10
    font-size: 10px
  &--success
    background-color: #DCFCE7
    color: #085E25
  &--danger
    background: #FCF2F2
    color: #CF4C43
