.customer-advice-call
  cursor: initial
  padding: 12px 0
  display: flex
  justify-content: center

  &__content
    width: 245px
    display: flex
    flex-direction: column
    align-items: center

  &__number
    display: flex
    align-items: center
    gap: 15px
    font-size: 24px

  &__subtitle
    font-size: 14px
    text-align: center

  &__buttons
    width: 100%
    margin-top: 25px
    display: flex
    flex-direction: column
    gap: 14px

  &__or
    text-align: center
    font-size: 13px
    color: $grey-350
