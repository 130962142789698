.faq
  ul
    display: flex
    flex-direction: column
    gap: toRem(24px)
    margin-top: toRem(25px)

    li
      &.open
        > span
          i
            transform: rotate(180deg)

        > div
          display: block
          strong
            font-weight: $weight-medium
          a
            text-decoration: underline
          i
            font-family: "Geomanist", sans-serif !important
            font-style: italic

      > span
        display: flex
        justify-content: space-between
        align-items: center
        padding-bottom: toRem(6px)
        border-bottom: 1px solid $grey-100
        font-weight: 500
        cursor: pointer

        i
          transform: rotate(0)
          transition: all 0.3s ease-in-out

      > div
        display: none
        padding-top: toRem(15px)

  > div
    margin-top: toRem(60px)
    margin-bottom: toRem(100px)
    text-align: center

    > a
      color: $primary
