@import "variables"
@import "../../nuuns/mixins.sass"

@font-face
  font-family: '#{$icomoon-font-family}'
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xq9ik8')
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xq9ik8#iefix') format('embedded-opentype'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xq9ik8') format('truetype'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xq9ik8') format('woff'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xq9ik8##{$icomoon-font-family}') format('svg')
  font-weight: normal
  font-style: normal
  font-display: block

i[class*="icon--"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon--anticlockwise-arrow
  &:before
    content: $icon--anticlockwise-arrow

.icon--arrow-left
  &:before
    content: $icon--arrow-left

.icon--truck
  &:before
    content: $icon--truck

.icon--card-payment
  &:before
    content: $icon--card-payment

.icon--package-delivery
  &:before
    content: $icon--package-delivery

.icon--case
  &:before
    content: $icon--case

.icon--filters-filled
  &:before
    content: $icon--filters-filled

.icon--mail
  &:before
    content: $icon--mail

.icon--reconditioned
  &:before
    content: $icon--reconditioned

.icon--check-macaroon
  &:before
    content: $icon--check-macaroon

.icon--medal
  &:before
    content: $icon--medal

.icon--simple-minus
  &:before
    content: $icon--simple-minus

.icon--simple-plus
  &:before
    content: $icon--simple-plus

.icon--star-angular
  &:before
    content: $icon--star-angular

.icon--picto-check
  &:before
    content: $icon--picto-check

.icon--camera
  &:before
    content: $icon--camera

.icon--cb
  &:before
    content: $icon--cb

.icon--edit
  &:before
    content: $icon--edit

.icon--comment
  &:before
    content: $icon--comment

.icon--like
  &:before
    content: $icon--like

.icon--trash
  &:before
    content: $icon--trash

.icon--sort
  &:before
    content: $icon--sort

.icon--star-filled
  &:before
    content: $icon--star-filled

.icon--star
  &:before
    content: $icon--star

.icon--angle-down
  +flexCenter
  &:before
    content: $icon--angle-down

.icon--angle-right
  +flexCenter
  &:before
    content: $icon--angle-down
  transform: rotate(270deg)

.icon--angle-left
  +flexCenter
  &:before
    content: $icon--angle-down
  transform: rotate(90deg)

.icon--angle-up
  +flexCenter
  &:before
    content: $icon--angle-down
  transform: rotate(180deg)

.icon--filled-arrow-right .path1
  &:before
    content: $icon--filled-arrow-right-path1
    color: rgb(38, 47, 44)

.icon--filled-arrow-right .path2
  &:before
    content: $icon--filled-arrow-right-path2
    margin-left: -1em
    color: rgb(255, 255, 255)

.icon--arrow-right
  &:before
    content: $icon--arrow-right

.icon--bell
  &:before
    content: $icon--bell

.icon--bell-filed
  &:before
    content: $icon--bell-filed

.icon--burger-menu
  &:before
    content: $icon--burger-menu

.icon--cross
  &:before
    content: $icon--cross

.icon--eye
  &:before
    content: $icon--eye

.icon--filters
  &:before
    content: $icon--filters

.icon--heart
  &:before
    content: $icon--heart

.icon--heart-filed
  &:before
    content: $icon--heart-filed

.icon--home
  &:before
    content: $icon--home

.icon--home-filed
  &:before
    content: $icon--home-filed

.icon--logout
  &:before
    content: $icon--logout

.icon--plane
  &:before
    content: $icon--plane

.icon--plus
  &:before
    content: $icon--plus

.icon--rounded-cross
  &:before
    content: $icon--rounded-cross

.icon--question
  &:before
    content: $icon--question

.icon--search-menu
  &:before
    content: $icon--search-menu

.icon--search
  &:before
    content: $icon--search

.icon--shield
  &:before
    content: $icon--shield

.icon--shield-filled
  &:before
    content: $icon--shield-filled

.icon--signal
  &:before
    content: $icon--signal

.icon--trust
  &:before
    content: $icon--trust

.icon--user
  &:before
    content: $icon--user

.icon--warning
  &:before
    content: $icon--warning

.icon--wifi
  &:before
    content: $icon--wifi

.icon--search-menu-light
  &:before
    content: $icon--search-menu-light

.icon--angle-left-bold
  &:before
    content: $icon--angle-left-bold

.icon--share
  &:before
    content: $icon--share

.icon--check
  &:before
    content: $icon--check

.icon--trend-up
  &:before
    content: $icon--trend-up

.icon--cart-market
  &:before
    content: $icon--cart-market

.icon--arrows-circle
  &:before
    content: $icon--arrows-circle

.icon--notice-pen
  &:before
    content: $icon--notice-pen

.icon--coin-stack
  &:before
    content: $icon--coin-stack

.icon--double-chevron-right
  &:before
    content: $icon--double-chevron-right

.icon--lock
  &:before
    content: $icon--lock

.icon--stopwatch
  &:before
    content: $icon--stopwatch

.icon--magic
  &:before
    content: $icon--magic

.icon--headset
  &:before
    content: $icon--headset

.icon--lock
  &:before
    content: $icon--lock

.icon--back
  &:before
    content: $icon--back

.icon--truck-outline
  &:before
    content: $icon--truck-outline

.icon--fire
  &:before
    content: $icon--fire

.icon--edit-filled
  &:before
    content: $icon--edit-filled

.icon--shield-outline
  &:before
    content: $icon--shield-outline

.icon--chat
  &:before
    content: $icon--chat

.icon--chat-question
  &:before
    content: $icon--chat-question

.icon--cb-outline-thin
  &:before
    content: $icon--cb-outline-thin

.icon--message
  &:before
    content: $icon--message

.icon--phone
  &:before
    content: $icon--phone

.icon--notification
  &:before
    content: $icon--notification

.icon--check-circle-filled
  &:before
    content: $icon--check-circle-filled

.icon--eye-crossed
  &:before
    content: $icon--eye-crossed

.icon--gavel
  &:before
    content: $icon--gavel

.icon--pencil
  &:before
    content: $icon--pencil

.icon--download
  &:before
    content: $icon--download

.icon--move
  &:before
    content: $icon--move

.icon--check-thin
  &:before
    content: $icon--check-thin

.icon--plus-thin
  &:before
    content: $icon--plus-thin

.icon--calendar
  &:before
    content: $icon--calendar

.icon--info
  &:before
    content: $icon--info

.icon--paste
  &:before
    content: $icon--paste

.icon--circle-exclamation
  &:before
    content: $icon--circle-exclamation

.icon--circle-info
  +flexCenter
  &:before
    content: $icon--circle-exclamation
  transform: rotate(180deg)

.icon--skis
  &:before
    content: $icon--skis

.icon--level
  &:before
    content: $icon--level

.icon--flash
  &:before
    content: $icon--flash

.icon--battery
  &:before
    content: $icon--battery

.icon--magic-outline
  &:before
    content: $icon--magic-outline

.icon--mesure
  &:before
    content: $icon--mesure

.icon--bicycle-frame
  &:before
    content: $icon--bicycle-frame

.icon--bicycle-fork
  &:before
    content: $icon--bicycle-fork

.icon--mountains-outline
  &:before
    content: $icon--mountains-outline

.icon--bicycle-group
  &:before
    content: $icon--bicycle-group

.icon--shield-outline-sharp
  &:before
    content: $icon--shield-outline-sharp

.icon--open-box-outline
  &:before
    content: $icon--open-box-outline

.icon--clock
  &:before
    content: $icon--clock

.icon--store
  &:before
    content: $icon--store
