.customer-advice-message
  cursor: initial
  display: flex
  flex-direction: column
  gap: 14px

  &--submitted
    padding: 0 40px
    text-align: center
    margin-top: 26px
    display: flex
    flex-direction: column
    align-items: center
    gap: 27px
    i
      color: $reinsurance
      font-size: 33px

  &__input-group
    display: flex
    gap: 14px
