@import "../../bulma-custom/utilities/initial-variables"
@import "../../bulma-custom/utilities/mixins"
@import "../../nuuns/functions"
@import "../../nuuns/mixins"

@import "parts/cart-modal-product"
@import "parts/cart-modal-footer"
@import "parts/cart-modal-empty"

.react_cart_modal
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between

  &__content
    flex: 1
    margin: 0 20px
    +tablet
      margin: 0 30px
