// Common properties helpers
%flex-column
  display: flex
  flex-direction: column

// Déclarations de variables qui seront utilisées pour générer les helpers d'espacements
$spaces: ($size-7, $size-6, $size-5, $size-4, $size-3, $size-2, $size-1)
$directions: ('left', 'right', 'top', 'bottom')
$devices: (('tablet', $tablet), ('desktop',$desktop), ('widescreen', $widescreen), ('fullhd',$fullhd))
$axes: (('horizontal',('left','right')), ('vertical',('top','bottom')))

// Génératipon des helpers d'espacements
@each $space in $spaces
  $i: index($spaces, $space)
  // .has-padding-SPACE, .has-margin-SPACE
  .has-padding-#{$i}
    padding: $space !important
  .has-margin-#{$i}
    margin: $space !important

  // .has-margin-SPACE-AXE
  @each $axe, $direction in $axes
    .has-margin-#{$i}-#{$axe}
      margin-#{nth($direction, 1)}: $space !important
      margin-#{nth($direction, 2)}: $space !important

  // .has-padding-SPACE-AXE
  @each $axe, $direction in $axes
    .has-padding-#{$i}-#{$axe}
      padding-#{nth($direction, 1)}: $space !important
      padding-#{nth($direction, 2)}: $space !important

  // .has-padding-DIRECTION-SPACE
  @each $direction in $directions
    .has-padding-#{$direction}-#{$i}
      padding-#{$direction}: $space !important
    .has-margin-#{$direction}-#{$i}
      margin-#{$direction}: $space !important

// Custom helper for setting flex property
@for $intensity from 1 through 10
  .flex-#{$intensity}
    flex: $intensity
    flex-shrink: 1    // ie
    flex-basis: auto  // ie

//
.has-text-nowrap
  white-space: nowrap

// flex Utils
.is-flex-column
  @extend %flex-column

.is-flex-column-strech
  @extend %flex-column
  align-items: stretch

.is-flex-column-arround
  @extend %flex-column
  align-items: stretch

.is-align-items-center
  align-items: center

.is-justify-content-space-between
  justify-content: space-between

.always-flex-row
  display: flex !important
  flex-direction: row !important

.is-22x22
  width: 22px
  height: 22px

.is-20x20
  width: 20px
  height: 20px

// Modal transitions
.modal-transition-in
  transition: visibility 0s, opacity .5s, z-index 0s linear

.modal-transition-out
  transition: visibility 0s, opacity .5s, z-index 1s linear

.is-flex-center
  +flexCenter

// Font Colors
.has-text-red,
.ht-red
  color: $red !important

.has-text-nuuns-grey-dark
  color: #777777

.has-text-reinsurance,
.ht-reinsurance
  color: $reinsurance !important

.has-text-success-darker
  color: #0DA540

.is-grey-350
  color: $grey-350

.is-centered
  margin: auto

.is-hidden-true-tablet
  @media screen and (min-width: 481px) and (max-width: 768px)
    display: none !important

.is-blurred
  filter: blur(4px)

.left
  text-align: left

.right
  text-align: right

.top-right-corner
  position: absolute
  top: 15px
  right: 15px

// Typography
.text--ellipsis
  +ellipsis

// Shapes
.has-shape-1
  background: url('../../images/campsider/shapes/shape-1.svg') no-repeat center

.has-shape-2
  background: url('../../images/campsider/shapes/shape-2.svg') no-repeat center

.has-shape-3
  background: url('../../images/campsider/shapes/shape-3.svg') no-repeat center

.has-shape-4
  background: url('../../images/campsider/shapes/shape-4.svg') no-repeat center

.has-shape-5
  +desktop
    background: url('../../images/campsider/shapes/shape-5.svg') no-repeat right

  +mobile
    background: url('../../images/campsider/shapes/shape-5-mobile.svg') no-repeat right

.section-container
  margin-bottom: toRem(40px)

  &.is-marginless-mobile
    +mobile
      margin-bottom: 0
  +desktop
    margin-bottom: toRem(60px)

.has-text-grey-350
  color: $grey-350 !important

.has-text-grey-300
  color: $grey-300 !important

.has-text-grey-600
  color: $grey-600 !important

.is-disabled
  cursor: not-allowed !important

.stuck-left-border
  border-left: 0 !important
  border-top-left-radius: 0 !important
  border-bottom-left-radius: 0 !important

.stuck-right-border
  border-right: 0 !important
  border-top-right-radius: 0 !important
  border-bottom-right-radius: 0 !important
