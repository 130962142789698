.products-filters
  border-bottom: 4px solid $orange
  border-top: 1px solid $blue-light
  background: $white
  height: 60px
  width: 100%
  align-items: center
  display: flex
  color: $blue-dark

  +touch
    border-bottom: none
    background: $blue-medium
    color: $white

  .apply-button
    margin: 20px auto -55px

    button
      background: no-repeat
      border: none

  .container
    display: flex
    justify-content: space-between
    width: 90%

    +tablet-only
      width: 80%

    ul
      display: flex
      align-items: center
      height: 60px
      justify-content: flex-start
      width: 85%

      +touch
        flex-wrap: wrap
        flex-direction: column

      li

        text-transform: uppercase
        color: $blue-dark
        font-size: 14px
        font-weight: 700
        display: flex
        justify-content: center
        position: relative
        height: 100%
        align-items: center
        width: fit-content
        padding: 0 30px
        min-width: 150px

        .filter-list
          display: none

        &:hover
          .filter-list
            display: flex

    #filters-sort-by
      align-items: center
      text-transform: uppercase
      color: $beige
      font-size: 14px
      font-weight: 700
      width: 15%
      display: flex
      justify-content: center

.filters-form

  +touch
    padding-bottom: 15px

  ul
    +touch
      overflow-y: auto
      padding: 10px 5%
      height: 100%
    li.filter-title
      +touch

        color: $blue-dark
        text-transform: uppercase
        font-size: 14px
        font-weight: 700

  .filter-list
    width: 220px
    height: fit-content
    display: flex
    flex-direction: column
    background: $white
    border-top: 4px solid $blue-dark
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.24)
    top: 57px
    position: absolute
    padding: 10px 0 30px

    +touch
      position: unset
      width: 80%
      align-self: flex-end
      border: none
      box-shadow: none
      top: 0

    .see-more
      font-size: 12px
      justify-self: flex-end
      display: flex
      flex-grow: 1
      text-transform: capitalize
      font-weight: 400
      margin-bottom: 0
      color: $blue
      cursor: pointer
      justify-content: flex-start
      width: 100%
      padding: 10px 0 10px 30px

    ul
      justify-content: center
      width: 100%
      flex-direction: column
      height: fit-content
      padding: 0 10px

      &.active-filters
        border-bottom: 1px solid $grey-light
        height: auto

      &.available-filters-list
        max-height: 250px
        overflow: hidden
        justify-content: flex-start
        height: auto

      &.show-all
        overflow-y: auto

      li
        width: 100%
        justify-content: flex-start
        display: flex
        flex-direction: row
        align-items: baseline
        font-size: 12px
        line-height: 28px
        cursor: pointer
        padding: 0
        position: relative

        input
          +touch
            width: 75%
            height: 28px

        label
          cursor: pointer
          display: flex
          justify-content: flex-start
          width: 100%

          .checkbox-box
            align-self: center

          .label
            font-size: 12px
            justify-self: flex-end
            display: flex
            flex-grow: 1
            text-transform: capitalize
            font-weight: 600
            margin-bottom: 0
            color: $blue-dark

          .quantity
            color: $blue-dark
            font-weight: 300

ul.active-filters-pills
  display: flex
  width: 90%
  justify-content: flex-start
  padding: 20px 0
  flex-wrap: wrap

  li
    margin: 4px 25px 4px 0

    a
      display: flex
      border: 1px solid $orange
      border-radius: 4px
      justify-content: space-between
      align-items: center
      font-size: 14px
      padding: 0 6px
      height: 24px
      color: $blue-dark
      text-transform: capitalize

      span
        margin-right: 15px

.products-filters
  /* MOBILE PART */
  #filter_icon_touch
    display: flex
    align-items: center
    color: $white
    font-size: 14px

    figure
      width: 40px
      margin-right: 10px

  &.is-fixed-top
    #filter_icon_touch
      color: $blue-dark

.products-filters, .active-filters-pills, .modal-filters

  .colors
    flex-wrap: wrap
    margin-left: -10px
    flex-direction: row !important
    justify-content: flex-start !important

    +mobile
      display: flex

    li
      width: 34px !important
      height: 34px !important
      padding: 25px 25px !important
      display: flex !important
      align-items: center !important
      justify-content: center !important
      min-width: unset !important

      .color-pick-wrapper
        width: 34px
        height: 34px
        border-radius: 17px
        display: flex
        align-items: center
        justify-content: center
        border: 1px solid $grey-lighter

      &.selected
        .color-pick-wrapper
          border: 1px solid $orange

      .color-pick
        border: 2px solid $beige
        width: 32px
        height: 32px
        border-radius: 16px
        display: flex

.active-filters-pills

  .colors
    margin-bottom: 0
    margin-left: 5px

    li
      width: 20px !important
      height: 20px !important
      padding: 0 0 0 5px !important
      margin-right: 15px !important

      .color-pick-wrapper
        border: none
        width: 20px !important
        height: 20px !important
        border-radius: 10px

        .color-pick
          width: 20px
          height: 20px
