@import "purchase-protection-card"

.purchase-protection
  width: 100%
  +desktop
    padding: 0 !important
  padding: 0 15px 0 15px !important


  &--compact
    +tablet
      padding: 0 !important
    padding: 0 20px 0 20px !important


