@import "../block/product/banner"

.account-page-layout
  &.has-shape-2b
    &::after
      content: ''
      width: 100%
      height: 100%
      position: absolute
      bottom: toRem(-545px)
      left: 0
      background: url('../../images/campsider/shapes/shape-2.svg') no-repeat center
      background-size: 100%
      z-index: 1
  .return
    display: none

    +touch
      display: flex

  &__content
    display: flex
    //align-items: flex-start
    gap: toRem(16px)
    margin-top: toRem(43px)
    position: relative
    z-index: 2

    +touch
      margin-top: toRem(30px)
      gap: toRem(35px)
      flex-direction: column-reverse
      //padding: 0 toRem(25px)

    //&.is-mobile
    //  +touch
    //    padding-left: 0
    //    padding-right: 0
