@import '../utilities/initial-variables'
@import '../utilities/mixins'
@import '../../nuuns/functions'
@import '../../nuuns/mixins'
@import '../../campsider/icomoon/style'

/**
 * This file overrides the specifics part of the bulma card component.
 *
 * BUT it adds some specific new cards modifier AND new element related to these new modifiers
 *
 * TODO / TO-DISCUSS: To simplify this file, we should :
 * - Remove new component not related to bulma
 * - Create new components for each kind of card (ambassador, category, etc.)
 *   which are just simple box (from bulma) for some of them (eg: brand).
 * These components could be independent of the bulma card to simplify deletion when they become useless
 */
.card
  $this: &

  box-shadow: none
  position: relative
  background-color: $light
  overflow: hidden
  transition: all 0.25s ease

  .card-image
    &--gradient
      &::before
        content: ''
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        z-index: 1
        background: linear-gradient(180deg, rgba(33, 41, 44, 0) 49.72%, rgba(33, 41, 44, 0.8) 100%)

    &__legend
      position: absolute
      bottom: 0
      left: 0
      right: 0
      z-index: 2

      & *
        color: $beige !important

  .card-content
    padding: toRem(12px) toRem(16px)

    +desktop
      padding: toRem(29px) toRem(18px) toRem(20px) toRem(18px)

  .card-footer
    background-color: inherit
    border: none
    justify-content: center
    padding: toRem(16px) toRem(20px) toRem(20px)
    position: absolute
    left: 0
    right: 0
    bottom: 0

  .card-background
    border: 1px solid $beige
    overflow: hidden
    border-radius: toRem(8px) !important
    height: toRem(205px)

    +desktop
      height: toRem(400px)

    & > img
      transition: all 0.25s ease-out
      height: 100%
      width: 100%
      object-fit: cover

  &:hover
    .card-background > img
      transform: scale(1.1)

  &--advert,
  &--catalog-advert
    height: 100%
    padding-top: calc(100% + 124px)

  // Careful : this modifier is designed to work only in the catalogs pages
  &--catalog-advert
    +from(1378px) // Figma template
      --px: 1px
    +between(1216px, 1379px)
      // 940px : the available width at 1379px, 438px the unavailable width at 1379px
      --px: calc((100vw - 438px) / 940)
    +between(1023px, 1216px)
      // 940px : the available width at 1216px, 438px the unavailable width at 1379px.
      // 1.15 is a custom ratio due to the change from 4 cards to 3 cards
      --px: calc(((100vw - 438px) / 780) * 1.15)
    +between(768px, 1023px)
      // 613px : the available width at 1024, 411px the unavailable width at 1024.
      // 1.5 is a custom ratio due to the change from 3 columns to 2 columns
      --px: calc(((100vw - 411px) / 613) * 1.5)
    +until(767px)
      --px: calc(((100vw - 2 * var(--horizontal-min-padding)) / 682) * 1.4)

  // Careful : this modifier is designed to work only in the homepage
  &--advert
    +from(1378px) // Figma template
      --px: 1px
    +between(1216px, 1379px)
      --px: calc(((100vw - 48px) / 1330) * 1)
    +between(1023px, 1216px)
      --px: calc(((100vw - 48px) / 1168) * 0.95)
    +between(768px, 1023px)
      --px: calc(((100vw - 48px) / 976) * 1.1)
    +until(767px)
      --px: calc(((100vw - 48px) / 720) * 1.3)

  // Only uses for advert-card-contentful
  &__background
    +overlayAbsolute
    padding-bottom: 0
    border-radius: 8px
    overflow: hidden
    background-color: var(--background-color)

    &:after
      +overlay
      top: 47.75%
      content: ""
      clip-path: polygon(0 3%, 100%  13%, 100% 100%, 0 100%)
      z-index: 1
      transition: all 0.25s ease-out
      background-image: var(--background-image)
      background-repeat: no-repeat
      background-position: center bottom
      background-size: cover

      &:hover
        &::after
          transform: scale(1.1)

  // Only uses for advert-card-contentful
  &__arrow
    +flexCenter
    background: $white
    position: absolute
    z-index:  2
    left: 50%
    top: 52%
    width: calc(40 * var(--px))
    height: calc(40 * var(--px))
    border-radius: calc(40 * var(--px))
    border: $primary calc(2.5 * var(--px)) solid
    transform: translate3d(-50%, -50%, 0)
    font-size: calc(30 * var(--px))

    &:before
      content: $icon--filled-arrow-right-path2
      font-family: $icomoon-font-family
      color: inherit
      font-size: inherit

    +until(375px)
      top: 75%
      width: calc(80 * var(--px))
      height: calc(80 * var(--px))
      border-radius: calc(80 * var(--px))
      font-size: calc(60 * var(--px))
      border: $primary calc(5 * var(--px)) solid

    &--catalog-advert
      color: var(--arrow-color)
      border-color: var(--arrow-color)

  // Only uses for advert-card-contentful
  &__content
    +flexCenter
    text-align: center
    text-transform: uppercase
    font-weight: $weight-black
    line-height: 1.1
    padding: 10px

    &--catalog-advert
      +overlay
      color: $white
      font-size: calc(var(--font-size) * var(--px))
      bottom: 47.75%

  // This class add global common style to the card and his children (card-image, card-content and card-footer)
  // Uses for advert-card-contentful, advert-card, ambassador-card, how-it-work, concept-card, brand-card, guide-summary, category-card
  //
  // TODO : Inconsistent BEM MODIFIER :  child element / component shouldn't appear in a modifier.
  &--preview
    box-shadow: none
    position: relative
    background-color: $light
    overflow: hidden
    transition: all 0.25s ease

    .card-image
      &--gradient
        &::before
          content: ''
          position: absolute
          top: 0
          bottom: 0
          left: 0
          right: 0
          z-index: 1
          background: linear-gradient(180deg, rgba(33, 41, 44, 0) 49.72%, rgba(33, 41, 44, 0.8) 100%)

      &__legend
        position: absolute
        bottom: 0
        left: 0
        right: 0
        z-index: 2
        color: $beige !important

        & *
          color: $beige !important

    .card-content
      padding: toRem(12px) toRem(16px)

      +desktop
        padding: toRem(29px) toRem(18px) toRem(20px) toRem(18px)

    .card-footer
      background-color: inherit
      border: none
      justify-content: center
      padding: toRem(16px) toRem(20px) toRem(20px)
      position: absolute
      left: 0
      right: 0
      bottom: 0

    &:hover
      .card-background > img
        transform: scale(1.1)

  &-background
    border: 1px solid $beige
    overflow: hidden
    border-radius: toRem(8px) !important
    height: toRem(205px)

    +desktop
      height: toRem(400px)

    & > img
      transition: all 0.25s ease-out
      height: 100%
      width: 100%
      object-fit: cover

  // Specific element for advert_card
  &-advert
    overflow: visible
    &__title
      +title
      //transition: all 0.3s ease-in-out
      font-size: calc(22 * var(--px))

    &__link
      +overlayAbsolute
      transition: all 0.25s ease
      &:hover
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.3))
        box-shadow: inset 0 0 0 1px #F4F2EE

    .card-background
      background: $primary url('../../../images/campsider/icons/commons/white/arrow_right.svg') no-repeat center bottom calc(65 * var(--px))
      background-size: calc(60 * var(--px))
      padding-top: calc(65 * var(--px))
      height: 100%

      &:hover
        border-radius: 8px !important

      & *
        color: $beige !important

  // Only uses on how-it-works page
  &-guide
    background-color: $beige
    height: toRem(464px)

    .card-background
      height: toRem(270px)

  // Only uses on about-us page and brand_card
  &-brand
    display: flex
    justify-content: center
    align-items: center
    border: toRem(1px) solid $beige
    border-radius: toRem(8px)

    img
      -o-object-fit: contain
      object-fit: contain
      filter: grayscale(1)
      transition: filter 0.25s linear

    &:hover
      img
        filter: grayscale(0)

    &--medium
      height: 110px
      img
        width: 50%
        height: 70%
        object-fit: contain

      +tablet
        height: 220px

    &--carousel
      box-sizing: border-box
      width: auto
      height: 92px
      img
        max-width: 92px
        max-height: 62px

  // Only uses in ambassador_card on homepage
  &-ambassador
    height: toRem(305px)
    min-width: toRem(260px)
    border-radius: toRem(50px)

    & + .card-ambassador
      margin-left: toRem(16px)

    .card-background
      height: 100%

    .card-image__legend
      bottom: toRem(16px)
      left: toRem(16px)
      right: toRem(16px)

      span
        display: block

  &-concept
    .card-background
      aspect-ratio: 16/9
      height: auto

    .card-image__legend
      bottom: toRem(16px)
      left: toRem(20px)
      right: toRem(20px)

      +desktop
        bottom: toRem(40px)
        left: toRem(40px)


  // Only uses in category_card on the categories page
  &-category
    border-radius: 0
    width: 100%
    padding-top: 100%
    .card-image
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0

    img
      border-radius: 0 !important

    .card-background
      border-radius: 0 !important

      &--large
        .card-image__legend
          bottom: toRem(48px)

    .card-image__legend
      +title
      font-size: toRem(20px)
      line-height: toRem(24px)
      bottom: toRem(20px)
      left: 0
      right: 0

      &--small
        +mobile
          bottom: toRem(10px)

      +desktop
        font-size: toRem(28px)
        line-height: toRem(35px)
