.guarantee-card-list
  padding-left: 0 !important
  margin: 20px 0 25px 0
  display: flex
  flex-direction: column
  gap: 10px

  &--readonly
    margin: 20px 0 9px 0

  &__item
    display: flex
    align-items: center
    gap: 15px
    font-size: 14px

  &__item-figure
    +flexCenter
    width: 18px
    height: 18px
    border-radius: 50%
    background-color: #DCFCE7
    color: #16A34A
    &--inactive
      background-color: #FCF2F2
      color: #CF4C43
    i
      font-size: 9px


