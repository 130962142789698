@import "~bulma/sass/elements/content.sass"

.content
  ul
    list-style: disc outside
    +ltr-property("margin", 1em, false)

  h1, h2, h3, h4, h5, h6
    +title

  p
    +text
  ul
    margin: auto
    padding: revert

  a
    color: $primary
    &:hover
      text-decoration: underline
