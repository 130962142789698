.react-faq

  &__question
    cursor: pointer
    font-size: 14px
    padding: 10px 0
    display: flex
    justify-content: space-between
    border-bottom: 1px solid $grey-50

  &__icon
    transition: transform .5s ease
    -webkit-transition: .5s ease
    -moz-transition: .5s ease
    -o-transition: .5s ease

    &--open
      transform: rotate(180deg)

  &__answer
    font-size: 13px
    max-height: 0
    overflow: hidden
    transition: .5s ease
    -webkit-transition: .5s ease
    -moz-transition: .5s ease
    -o-transition: .5s ease

    +tablet
      font-size: 14px

    &--open
      padding: 10px 14px 10px 0
      max-height: 400px
      overflow: auto

    ul
      padding: 10px 20px
      list-style-type: disc
    a
      text-decoration: underline
