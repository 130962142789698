@import "../../bulma-custom/utilities/mixins"

.info-block
  padding: 15px 20px
  display: flex
  align-items: center
  gap: 20px
  border-radius: 4px
  background-color: #F4F2EE

  $this: &

  &--bg-light
    background-color: $grey-30

  &--compact
    gap: 10px

  &--bordered
    border: 1px solid $grey-50

  &--without-icon
    #{$this}__icon
      display: none

  &__icon
    font-size: 23px
    color: $primary
    &--small
      font-size: 13px
      +tablet
        font-size: 14px
    &--medium
      font-size: 18px
    &--large
      font-size: 32px

  &__text
    font-size: 14px
    flex: 1
    strong
      font-weight: $weight-medium
    span
      display: none
    +from(438px)
      span
        display: inline
    +tablet
      br
        display: none

    &--unchanged
      font-size: 14px
      a
        text-decoration: underline
      span
        display: inline
      br
        display: block
      ul
        list-style-type: disc
        padding-left: 20px
      ol
        padding-left: 20px

  &--warning
    background-color: #FBEABB
    border: 1px solid #A14019
    #{$this}__text
      color: #A14019
    #{$this}__close
      color: #A14019
      font-size: 14px

  &--error
    background-color: #FFEBEB
    border: 1px solid #DD0000
    a // add more elements if needed
      color: #DD0000
    #{$this}__text
      color: #DD0000
    #{$this}__close
      color: #DD0000
      font-size: 14px

  &--success
    background: #E7FDEE
    border: 1px solid #0DA540
    b // add more elements if needed
      color: #085E25
    #{$this}__text
      color: #085E25
    #{$this}__icon
      color: #0DA540
    #{$this}__close
      color: #085E25
      font-size: 14px

  &--default
    background: $grey-30
    border: 1px solid $grey-50
    #{$this}__icon
      color: $black
    #{$this}__close
      color: $black
      font-size: 14px
