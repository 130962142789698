.brands-list

  ul
    background: $white

    li
      display: flex
      align-items: center
      a
        flex-direction: column
        justify-content: space-evenly
        border: 1px solid $grey-lighter
        display: flex
        align-items: center
        width: 175px
        height: 175px
        border-radius: 4px
        padding: 15px

        img
          height: 110px
          object-fit: contain
          margin-bottom: 15px

        span

          color: $orange
          font-size: 10px
          text-align: center
