.collapse-block
  $this: &

  &--open
    #{$this}__header
      border-radius: 4px 4px 0 0
    #{$this}__header-icon
      transform: rotate(180deg)
    #{$this}__body
      padding: 24px
      max-height: 500svw
      overflow: visible
      border-bottom: 1px solid $grey-50

  &__header
    font-size: 14px
    user-select: none
    cursor: pointer
    padding: 14px 24px
    display: flex
    justify-content: space-between
    align-items: center
    border: 1px solid $grey-50
    border-radius: 4px

  &__header-title
    font-weight: $weight-medium

  &__header-icon
    transition: transform 0.3s ease

  &__body
    padding: 0 24px
    max-height: 0
    overflow: hidden
    border: 1px solid $grey-50
    border-top: none
    border-bottom: none
    border-radius: 0 0 4px 4px
    transition: max-height 0.3s ease, padding-top 0.3s ease, padding-bottom 0.3s ease

    display: flex
    flex-direction: column
    gap: 24px

    +desktop
      gap: 30px


