.react_cart_modal_empty
  padding-top: 36px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center

  &__icon
    font-size: 36px
    +tablet
      font-size: 40px

  &__title
    +text
    font-weight: 500
    font-size: 14px
    line-height: 20px
    margin-top: 17px

  &__text
    font-weight: 400
    font-size: 13px
    line-height: 20px
    margin-top: 14px
    padding: 0 35px
    +tablet
      font-size: 14px
      margin-top: 21px
      padding: 0

  +tablet
    padding-top: 42px
