@import "../../nuuns/functions"
@import "../../nuuns/mixins"
@import "../../bulma-custom/utilities/mixins"
@import "../../bulma-custom/utilities/initial-variables"


.aside-modal-shipping
  flex: 1
  display: flex
  flex-direction: column

  &__intro
    max-width: 340px
    text-align: center
    margin: 27px auto 28px

  &__shipping-mode
    padding: 19px 0 15px
    &:not(:last-child)
      border-bottom: 1px solid $grey-light

