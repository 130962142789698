@import '../../../nuuns/functions'
@import "../../../bulma-custom/utilities/mixins"

.article_card
  $this: &

  &__link
    display: block
    text-decoration: none

    &:hover
      #{$this}__link__figure__image
        transform: scale(1.1)
      #{$this}__link__footer
        &--title
          color: $primary

  &__link__figure
    display: block
    position: relative
    overflow: hidden
    border: 1px solid #E8E8E8

    &--stuck-right
      border-right: none

    &--stuck-left
      border-left: none

  &__link__figure__image
    width: 100%
    height: 100%
    transition: transform 0.25s ease-out
    object-fit: cover

  &__link__footer
    margin: toRem(10px) toRem(5px) 0 toRem(5px)

    &--title
      font-size: 14px
      font-weight: $weight-medium
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
      max-height: 3.6em /* 1.8em per line of text */
      overflow: hidden
      text-overflow: ellipsis
      white-space: normal
      margin-top: toRem(10px)

  +tablet
    &__link__footer
      margin: toRem(10px) 0
      &--title
        font-size: 14px

  +fullhd
    &__link__figure
      width: 231px
      height: 231px
    &__link__figure__image
      width: 231px
      height: 231px
