#try
  height: 625px
  max-width: 410px
  background-size: cover
  background-image: url("../../images/footer/try.png")
  background-repeat: no-repeat
  background-position: right top
  justify-content: flex-end
  display: flex
  flex-direction: column
  align-content: center
  align-items: center
  margin: 20px auto
  text-align: center
  padding: 300px 20px 20px

  +desktop
    min-width: 410px
  +touch
    max-width: 100%

  .text

    font-size: 16px
    text-align: center
    color: $blue-dark !important
    line-height: 30px
    margin-bottom: 20px
