@import "../../bulma-custom/utilities/initial-variables"
@import "../../nuuns/functions"

$gap: 10px
$box-size: 21px

.custom_checkbox
  width: fit-content
  position: relative
  cursor: pointer
  $this: &

  &__content
    display: flex
    align-items: center
    gap: $gap

  &:hover
    #{$this}__box
      &:before
        background-color: $primary

  &__input
    position: absolute
    opacity: 0
    height: 0
    width: 0
    &:checked + #{$this}__box
      &:before
        background-color: $primary

  &__box
    border: 1px solid $grey-50
    border-radius: 2px
    width: 21px
    height: 21px
    min-width: 21px
    min-height: 21px
    display: grid
    place-items: center

    &:before
      content: ""
      background-color: transparent
      width: 13px
      height: 13px
      border-radius: 2px
      transition: background-color .20s ease-in-out
      pointer-events: none

  &__label
    text-align: left
    display: flex
    align-items: flex-end
    gap: 5px
    user-select: none
    cursor: pointer

  &__label-counter
    color: $grey-350

  &__label-icon
    font-size: 17px

  &__description
    padding-left: calc(#{$box-size} + #{$gap})
    font-size: 12px
    color: $grey-350
    text-align: left

