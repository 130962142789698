@import "../../bulma-custom/utilities/initial-variables.sass"
@import "../../bulma-custom/utilities/mixins.sass"
@import "../../nuuns/mixins"
@import "../../nuuns/functions"

.menu-aside
  width: 100%
  display: flex
  flex-direction: column
  //justify-content: center
  gap: toRem(35px)


  &--gapless
    gap: 0

  +desktop
    width: toRem(380px)

  +widescreen
    min-width: toRem(380px)

  &__title
    padding: 19px 0 13px
    text-align: center
    text-transform: uppercase
    background-color: $beige

  &__items
    display: flex
    flex-direction: column
    background-color: $beige

    &:not(.is-mobile)
      +touch
        display: none

  &__item
    &:not(:first-child)
      border-top: 1px solid $grey-100

  &__link
    display: flex
    justify-content: space-between
    align-items: center
    gap: toRem(5px)
    position: relative
    padding: toRem(15px) toRem(25px) toRem(15px) toRem(64px)
    cursor: pointer
    background-color: transparent
    color: $black
    transition: all 0.3s ease-in-out

    &--medium
      font-weight: $weight-medium

    +mobile
      padding: toRem(15px) toRem(25px)

    &:hover, &.active
      background-color: rgba(190, 74, 9, 0.1)
      color: $primary
      font-weight: $weight-medium

      &::before
        background-color: $primary

    &.arrow
      font-weight: 500

    &::before
      content: ''
      width: toRem(2px)
      height: 100%
      position: absolute
      top: 0
      right: 0
      background-color: transparent
      transition: background-color 0.3s ease-in-out

    > div
      display: flex
      align-items: center
      gap: toRem(10px)

    span
      +notification

    i
      transform: rotate(-90deg)
      color: $primary

  &__faq
    text-align: center

    a
      color: $primary
