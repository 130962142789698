@import "../bulma-custom/utilities/initial-variables"
@import "../bulma-custom/utilities/mixins"
@import "../nuuns/functions"

.warning-block
  font-size: toRem(14px)
  padding: toRem(16px) toRem(14px)
  display: flex
  align-items: center
  gap: toRem(18px)
  background-color: #F8F8F8
  border-radius: 4px
  margin-bottom: toRem(30px)
  i
    font-size: toRem(30px)
    color: $primary
  +desktop
    width: fit-content
